import eventManager from './eventManager';

export type Toast = {
  content: string;
  type: 'error' | 'info' | 'success' | 'announcement';
  subcontent?: string;
};

const toast = {
  show: (
    content: string,
    type: 'error' | 'info' | 'success' | 'announcement' = 'info',
    subcontent?: string,
  ): void => {
    eventManager.emit(0, content, type, subcontent);
  },
  clear: (): void => eventManager.emit(1),
};

export default toast;
