import React from 'react';
import styled from 'styled-components';

import { up } from 'styled-breakpoints';

type ResponsiveHandlerProps = {
  desktop?: boolean;
  mobile?: boolean;
  size?: string;
  mobileSize?: string;
  flex?: boolean;
};

const Wrapper = styled.div<ResponsiveHandlerProps>`
  flex: 1 0 ${({ mobileSize, size = 'auto' }) => mobileSize || size};
  ${up('mobile')} {
    flex: ${({ size }) => (size ? `1 0 ${size}` : 'auto')};
  }
  display: ${({ flex, desktop, mobile }): string => {
    const displayType = flex ? 'flex' : 'block';
    if (!desktop && !mobile) {
      return displayType;
    }
    if (desktop) {
      return 'none';
    }
    return displayType;
  }};
  ${up('mobile')} {
    display: ${({ flex, mobile }) => {
      const displayType = flex ? 'flex' : 'block';
      if (mobile) {
        return 'none';
      }
      return displayType;
    }};
  }
`;

const ResponsiveHandler = (Component: any) => {
  const ResponsiveHandlerWrapper = (props: any) => {
    const { flex, desktop, mobile, size, mobileSize } = props;
    return (
      <Wrapper
        desktop={desktop}
        mobile={mobile}
        size={size}
        mobileSize={mobileSize}
        flex={flex}
      >
        <Component {...props} />
      </Wrapper>
    );
  };
  return ResponsiveHandlerWrapper;
};

export default ResponsiveHandler;
