import { useSearchAlertsQuery } from '../generated/graphql';
import utils from '../utils';
import { getAlerts, saveAlerts } from '../utils/localStorage';

const useAlerts = () => {
  const { data: alerts, loading } = useSearchAlertsQuery({
    fetchPolicy: 'no-cache',
  });
  if (!loading) {
    const fetchedAlerts = alerts?.searchAlerts;
    const alertsSeen = getAlerts();
    const unseen = fetchedAlerts?.filter(
      x => !alertsSeen?.find(y => y === x?.id),
    );
    unseen?.forEach(alert => {
      alert && saveAlerts(alert.id);
      alert &&
        utils.toast.show(`${alert.title}`, 'announcement', `${alert.subtitle}`);
    });
  }
};

export default useAlerts;
