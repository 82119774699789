import { CSSProperties } from 'styled-components';
import constants from '../../constants';

export function getAlternativePartNumber(part: any) {
  return part.number !== part.inputNumber ? part.inputNumber : null;
}

export function getInvoiceText(loading: any, error: any, data: any) {
  if (loading) {
    return 'Loading ...';
  }

  if (error) {
    return 'N/A';
  }

  if (data) {
    return 'View Invoice';
  }
}

export function getLinkStyling(
  data: string,
  loading: boolean,
  error: Error,
): CSSProperties {
  const isDisabled = !data || loading || error;

  return {
    cursor: !isDisabled ? 'pointer' : 'inherit',
    pointerEvents: !isDisabled ? 'auto' : 'none',
  };
}

export function getPDFLink(id: string) {
  return `${constants.configs.API_HOST}/pdf/${id}`;
}
