import React from 'react';
import { useInvoiceUrlQuery } from '../../generated/graphql';

interface Props {
  children: any;
  invoiceNumber: string;
}

export function InvoicePDFLink({ children, invoiceNumber }: Props) {
  const { data, loading, error } = useInvoiceUrlQuery({
    skip: !invoiceNumber,
    variables: {
      invoiceNumber: invoiceNumber,
    },
  });

  const url = data?.invoice?.url ? String(data?.invoice?.url) : '';

  const strippedUrl = url
    .substring(url.lastIndexOf('/') + 1)
    .replace('.pdf', '');

  return children({ data: strippedUrl, error, loading });
}
