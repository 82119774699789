import { useState, useEffect } from 'react';

/**
 * Hook that debounces the update of a value
 */

export default function useDebounce(value: any, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      if (debouncedValue === value) {
        return undefined;
      }
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function
      return () => {
        clearTimeout(handler);
      };
    },
    // Only trigger effect if value changes
    [value, delay, debouncedValue],
  );

  return debouncedValue;
}
