import React, { FunctionComponent } from 'react';

interface SpacerProps {
  size?: string;
  vertical?: boolean;
}

const Spacer: FunctionComponent<SpacerProps> = ({
  size = '0.7rem',
  vertical = false,
}) => (
  <div
    style={{
      width: vertical ? 'auto' : size,
      height: vertical ? size : 'auto',
    }}
  />
);

export default Spacer;
