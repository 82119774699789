import { useState, useEffect, useCallback } from 'react';

function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const getSizeMemo = useCallback(getSize, [getSize]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return () => {};
    }

    function handleResize() {
      setWindowSize(getSizeMemo());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize, getSizeMemo, isClient]);

  return windowSize;
}

export default useWindowSize;
