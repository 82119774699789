import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import get from 'lodash/get';
import React, { FunctionComponent, useState, useEffect } from 'react';

import ApolloClient from 'apollo-client';
import { withApollo } from '@apollo/react-hoc';

import { ReactComponent as ChevronIcon } from '../assets/images/chevron.svg';

import Title from '../components/Title';
import ActionBar from '../components/ActionBar';
import withLayout from '../components/withLayout';
import TabsContainer from '../containers/TabsContainer';
import NewOrdersForm from '../components/Orders/NewOrders';
import NewOrderModal from '../components/Modals/NewOrder';
import DeliveryOption from '../components/DeliveryOption';
import DeleteCartModal from '../components/Modals/DeleteCart';
import FullscreenSpinner from '../components/FullscreenSpinner';
import OrdersSection from '../containers/Sections/OrdersSection';
import SearchSection from '../containers/Sections/SearchSection';
import OrderSuccessModal from '../components/Modals/OrderSuccess';
import OrdersSectionMobile from '../containers/Sections/OrdersSectionMobile';
import InventoryStatus, {
  InventoryStatusMobile,
} from '../components/InventoryStatus';

import {
  SearchPartsDocument,
  useSearchSavedOrdersQuery,
  useDeleteSavedOrderMutation,
  ISavedOrder,
} from '../generated/graphql';

import useActionBar from '../hooks/useActionBar';
import useWindowSize from '../hooks/useWindowSize';
import useNewOrderForm from '../hooks/useNewOrderForm';

import utils from '../utils';
import theme from '../constants/theme';
import formatters from '../utils/formatters';
import useEventListener from '../hooks/useEventListener';
import { isReadyToSubmitOrderChecker } from '../utils/isReadyToSubmitOrderChecker';
import moment from 'moment';
import { mergeParts } from '../utils/mergeParts';

const { toast } = utils;

const Column = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DesktopOnly = styled.div`
  display: none;
  ${up('mobile')} {
    width: 50%;
    display: block;
  }
`;

const MobileOnly = styled.div`
  display: block;
  ${up('mobile')} {
    display: none;
  }
`;

const FavoritePartsButton = styled.div<MobileFavoritesSlider>`
  cursor: pointer;
  background-color: ${({ theme: { colors } }) => colors.white};
  top: ${({ isOpen }) => (isOpen ? '51px' : 'auto')};
  position: absolute;
  bottom: ${({ isOpen, isSecondPanel }) => {
    if (isOpen) {
      return 'auto';
    }
    return isSecondPanel ? '65px' : '0';
  }};
  left: 0;
  right: 0;
  z-index: 11;
  padding: 0 1.5rem;
  min-height: 5rem;
  transition: all 0.2s;
  border-top: 0.1rem solid ${({ theme: { colors } }) => colors.grayLight};
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  ${up('mobile')} {
    display: none;
  }
`;
const FavoritePartsTitle = styled(Title)`
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-style: normal;
  font-weight: normal;
  color: ${({ theme: { colors } }) => colors.black};
  margin-left: 1.6rem;
`;
const ExpiredOrderText = styled(Title)`
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-style: normal;
  font-weight: normal;
  color: ${({ theme: { colors } }) => colors.black};
  margin-left: 1.6rem;
`;

const SavedCartsContainer = styled.main`
  display: flex;
  height: 100%;
`;
type MobileFavoritesSlider = {
  isOpen?: boolean;
  isSecondPanel?: boolean;
  isHidden?: boolean;
};
const MobileFavoritesSlider = styled.div<MobileFavoritesSlider>`
  width: 100%;
  z-index: 10;
  flex: 1;
  background-color: ${({ theme: { colors } }) => colors.white};
  transition: all 0.2s;
  top: ${({ isOpen }) => (isOpen ? '60px' : '100%')};
  height: ${({ isOpen }) => (isOpen ? '100%' : 0)};
  overflow: hidden;
  position: absolute;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  ${up('mobile')} {
    display: none;
  }
`;
type ChevronWDirectionProps = {
  showItems: boolean;
};
const ChevronWDirection = styled(({ showItems, ...rest }) => (
  <ChevronIcon {...rest} />
))<ChevronWDirectionProps>`
  transform: ${({ showItems }) => (showItems ? 'rotate(0)' : 'rotate(180deg)')};
  margin-left: 0.8rem;
`;

interface ApolloClientProps {
  client: ApolloClient<any>;
}

const customerDetailsFields = [
  'orderType',
  'shippingMethod',
  'freightCode',
  'customer',
  'address',
  'poNumber',
  'state',
  'zip',
  'date',
];

function turnOffLoad(setLoading: (value: boolean) => void) {
  setTimeout(() => setLoading(false), 250);
}

function hasSavedOrderExpired(order?: ISavedOrder | null) {
  const expiredDate = moment().subtract(30, 'days');

  return moment(order?.savedAt || '', 'x').isBefore(expiredDate);
}

const NewOrders: FunctionComponent<ApolloClientProps> = ({ client }) => {
  const [hasExpired, setHasExpired] = useState<boolean>(false);
  const [toggleFav, setToggleFav] = useState<boolean>(false);
  const [isSearchingPart, setIsSearchingPart] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const [orderToDelete, setOrderToDelete] = useState<number | null>(null);
  const [savedOrderLoading, setLoading] = useState(true);

  const [deleteSavedOrder] = useDeleteSavedOrderMutation({
    variables: { orderId: '' },
    refetchQueries: ['searchSavedOrders'],
  });

  const {
    loading,
    data = { searchSavedOrders: [] },
    refetch: refetchSavedOrders,
  } = useSearchSavedOrdersQuery({
    fetchPolicy: 'network-only',
  });

  useEventListener(
    'AddedToOrder',
    (_: any) => {
      if (toggleFav) {
        setToggleFav(!toggleFav);
      }
    },
    toggleFav,
  );

  const { width } = useWindowSize();
  const mobileBreakpoint = Number(theme.breakpoints.mobile.replace('px', ''));
  const [formik, current, send, orderNumber] = useNewOrderForm(null, {
    showCover: false,
    savedOrder: selected,
  });

  useEffect(() => {
    send('START'); // From 'inactive' to 'customerDetails'
    if (width && width >= mobileBreakpoint) {
      send('CONTINUE'); // From 'customerDetails' to 'editingAll'
    }
  }, []);

  useEffect(() => {
    if (selected && data && (data.searchSavedOrders || []).length) {
      setHasExpired(false);
      setLoading(true);

      const order = (data.searchSavedOrders || []).find(
        (ord: any) => ord.id === selected,
      );

      if (order) {
        order.parts = mergeParts(order?.parts);
      }

      const updateFormData = (detailedParts: any[] = []) => {
        const shippingMethodRaw = order?.shippingMethodRaw
          ? JSON.parse(order?.shippingMethodRaw)
          : null;

        setTimeout(() => {
          formik.setValues({
            ...order,
            date: formatters.date(new Date(), '/'),
            shippingMethodRaw,
            parts: (order?.parts || []).map((part: any) => {
              const { quantity } = part;

              return {
                ...detailedParts.find((pt: any) => {
                  return pt.number === part.number;
                }),
                qty: quantity,
              };
            }),
          });
        });
      };

      const isOrderExpired = hasSavedOrderExpired(order);

      if (isOrderExpired) {
        setHasExpired(true);
        updateFormData([]);
        turnOffLoad(setLoading);
        refetchSavedOrders();
        utils.toast.show('Saved Order has expired.', 'error');
        return;
      }

      if (order) {
        const partNo = (order.parts || []).map((part: any) => part.number);
        setLoading(true);
        if (partNo?.length > 0) {
          client
            .query({
              fetchPolicy: 'network-only',
              query: SearchPartsDocument,
              variables: {
                exact: true,
                parts: partNo,
              },
            })
            .then((res: any) => {
              updateFormData(res?.data?.searchParts);
              turnOffLoad(setLoading);
            })
            .catch(() => turnOffLoad(setLoading));
        } else {
          updateFormData([]);
          turnOffLoad(setLoading);
        }
      } else {
        updateFormData([]);
        turnOffLoad(setLoading);

        if (selected) {
          setSelected(undefined);
        }
      }
    }
    // eslint-disable-next-line
  }, [selected, data]);

  const orders =
    data.searchSavedOrders?.filter(order => {
      return !hasSavedOrderExpired(order);
    }) || [];

  useEffect(() => {
    if (!selected) {
      if (orders.length && orders[0] && orders[0].id) {
        if (width && width >= mobileBreakpoint) {
          setSelected(orders[0].id);
        }
      }
    } else if (!orders.length && selected) {
      setSelected(undefined);
    }
  }, [data, selected, orders, width, mobileBreakpoint]);

  const loadingText = (() => {
    let text = '';
    if (current.matches({ inventory: 'loading' })) {
      text = 'Checking Status on Inventory';
    } else {
      text = 'Processing your Order';
    }
    return text;
  })();

  const displayNewOrderModal = (mobile?: boolean) => {
    if (width) {
      if (mobile) {
        return width < mobileBreakpoint && current.matches('shipping');
      }
      return width >= mobileBreakpoint && current.matches('shipping');
    }
    return false;
  };

  const displayInventoryStatusModal = (mobile?: boolean) => {
    if (width) {
      if (mobile) {
        return (
          width < mobileBreakpoint && current.matches({ inventory: 'stock' })
        );
      }
      return (
        width >= mobileBreakpoint && current.matches({ inventory: 'stock' })
      );
    }
    return false;
  };

  const isReadyToSubmit = () => {
    return isReadyToSubmitOrderChecker({
      current,
      customerDetailsFields,
      formik,
      mobileBreakpoint,
      send,
      width,
    });
  };

  const EditingActionBar = useActionBar(
    <ActionBar
      secondaryAction={{
        label: 'Save Cart',
        onClick: () => send('SAVE'),
      }}
      primaryAction={{
        onClick: () =>
          (current.matches('customerDetails') || isReadyToSubmit()) &&
          send('CONTINUE'),
        label:
          current.value === 'customerDetails' &&
          width &&
          width < mobileBreakpoint
            ? 'Add Parts'
            : 'Continue',
      }}
    />,
    current.value === 'customerDetails' ||
      current.value === 'editingAll' ||
      current.value === 'stockHasChanged',
  );

  const stockItems = get(current, 'event.parts', []);

  const stateValue: any = current?.value;
  const isLoading =
    stateValue?.inventory === 'loading' ||
    stateValue === 'saving' ||
    stateValue === 'submitting';

  const loadingData = savedOrderLoading || loading;

  return (
    <>
      <FullscreenSpinner
        description={loadingText}
        isOpen={
          isLoading ||
          current.matches([{ inventory: 'loading' }, 'saving', 'submitting'])
        }
      />
      <InventoryStatus
        items={stockItems}
        onContinue={() => send('CONTINUE')}
        isOpen={displayInventoryStatusModal()}
        onEditParts={() => send('EDIT_PARTS', { parts: stockItems })}
      />
      <InventoryStatusMobile
        items={stockItems}
        onContinue={() => send('CONTINUE')}
        isOpen={displayInventoryStatusModal(true)}
        onEditParts={() => send('EDIT_PARTS', { parts: stockItems })}
      />
      <NewOrderModal
        form={formik}
        onDoneforNow={() => send('SAVE')}
        onSubmit={() => send('CONTINUE')}
        onAddMoreParts={() => send('ADD_MORE_PARTS')}
        isOpen={displayNewOrderModal()}
      />
      <OrderSuccessModal
        orderNumber={orderNumber}
        isOpen={current.matches('finish')}
        onContinue={() => {
          setSelected(undefined);
          send('CONTINUE');
          send('START');
        }}
      />
      <DeliveryOption
        form={formik}
        onDoneforNow={() => send('SAVE')}
        onSubmit={() => send('CONTINUE')}
        onAddMoreParts={() => send('ADD_MORE_PARTS')}
        isOpen={displayNewOrderModal(true)}
      />
      <DeleteCartModal
        isOpen={!!orderToDelete}
        onCancel={() => setOrderToDelete(null)}
        onConfirm={() => {
          deleteSavedOrder({
            awaitRefetchQueries: true,
            variables: { orderId: `${orderToDelete}` },
          })
            .then(() => {
              toast.show('Deleted the Saved Cart', 'success');

              if (String(orderToDelete) === selected) {
                setSelected(undefined);
              }
            })
            .catch(() => toast.show('Failed to delete the Saved Cart', 'error'))
            .finally(() => setOrderToDelete(null));
        }}
      />
      <SavedCartsContainer>
        <TabsContainer
          loading={loading}
          isSubpage={!!selected}
          shouldShowCover={orders.length === 0}
          onClickBack={() => setSelected(undefined)}
          title={selected ? 'Saved Carts' : undefined}
          redirectTo="/new-order"
        >
          {hasExpired && (
            <ExpiredOrderText label="This order has expired and will be deleted soon." />
          )}
          {!hasExpired &&
            (selected ? (
              <Column>
                <NewOrdersForm
                  key={selected}
                  loading={loadingData}
                  isSavedOrders
                  state={current}
                  formik={formik}
                  savedOrder={selected}
                  reviewOrder={displayNewOrderModal(true)}
                  setAddedParts={() => send('CONTINUE')}
                  onOpenSearch={() => setIsSearchingPart(true)}
                  onCloseSearch={() => setIsSearchingPart(false)}
                  isCustomerDataCollapsed={current.value !== 'customerDetails'}
                  setCustomerDataCollapsed={() => send('BACK')}
                />
                {!isSearchingPart && !loadingData && EditingActionBar}
              </Column>
            ) : (
              <MobileOnly>
                <OrdersSectionMobile
                  isSavedOrders
                  loading={loading}
                  selected={selected}
                  setSelected={nr => setSelected(nr)}
                  orders={orders.map((order: any) => ({
                    ...order,
                    number: order.id,
                    submittedAt: order.savedAt,
                  }))}
                />
              </MobileOnly>
            ))}
        </TabsContainer>
        <FavoritePartsButton
          tabIndex={0}
          isSecondPanel
          isOpen={toggleFav}
          onClick={() => setToggleFav(!toggleFav)}
          isHidden={
            isSearchingPart ||
            current.value === 'inactive' ||
            current.value === 'customerDetails' ||
            current.value === 'shipping' ||
            current.value === 'saving' ||
            current.value === 'submitting' ||
            current.value === 'finish'
          }
        >
          <FavoritePartsTitle label="Favorite Parts" />
          <ChevronWDirection showItems={toggleFav} />
        </FavoritePartsButton>
        <MobileFavoritesSlider
          isOpen={toggleFav}
          isHidden={!current.matches(['editingAll'])}
        >
          <SearchSection />
        </MobileFavoritesSlider>
        <DesktopOnly>
          <OrdersSection
            isSavedOrders
            loading={loading}
            selected={selected}
            label="Saved Carts"
            placeholder="Search Saved carts by PO number"
            setSelected={(nr: string | undefined) => setSelected(nr)}
            orders={orders.map((order: any) => ({
              ...order,
              number: order.id,
            }))}
            actions={[
              {
                label: 'Delete Order',
                method: (orderID: number) => setOrderToDelete(orderID),
              },
            ]}
          />
        </DesktopOnly>
      </SavedCartsContainer>
    </>
  );
};

export default withApollo(withLayout(NewOrders));
