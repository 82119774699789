const matchhGraphqlString = new RegExp('GraphQL error:');

/**
 * Parses out the prefix that graphql
 * adds to the Error message
 * ex: GraphQL error: this is a error
 * @param {string} message
 * @returns error message without the graphql prefix
 */
function stripGraphQL(message: string) {
  return message.replace(matchhGraphqlString, '');
}

/**
 * Parses the error message to a more
 * human readable format
 * @param {string} message error message string
 * @param {string} [defaultMessage='Unexpected Error']
 * @returns {string} parsed, trimmed and formatted error message
 */
export function parseErrorMessage(
  message: string,
  defaultMessage = 'Unexpected Error',
): string {
  if (typeof message !== 'string') {
    return defaultMessage;
  }

  return stripGraphQL(message).trim() || defaultMessage;
}
