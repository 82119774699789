import { useEffect } from 'react';

/**
 * Hook that handles clicks outside of the passed refs or its children
 */

export default function useClickOutside(
  refs: Array<any>,
  isDisabled = false,
  callback: any,
) {
  /**
   * Handle click on outside of element
   */
  function handleClickOutside(event: { target: any }) {
    if (isDisabled) {
      return;
    }
    let clickedOutside = true;

    const inspectElement = (elem: any): boolean | null => {
      if (elem && elem.current && elem.current.contains(event.target)) {
        clickedOutside = false;
        return false;
      }

      if (
        elem &&
        elem.current &&
        elem.current.children &&
        elem.current.children.length
      ) {
        for (let j = 0; j < elem.current.children.length; j += 1) {
          inspectElement(elem.current.children.item(j));
        }
      } else {
        return true;
      }
      return null;
    };

    refs.forEach(ref => {
      inspectElement(ref);
    });
    if (clickedOutside && callback) {
      callback();
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}
