import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Tabs from '../components/Tabs';
import Spinner from '../components/Spinner';
import { PrimaryButton } from '../components/styled/buttons';

import theme from '../constants/theme';

const StartOrderButton = styled(PrimaryButton)`
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  padding-right: 2.2rem;
  padding-left: 2.2rem;
  background-color: ${({ theme: { colors } }) => colors.white};
  color: ${({ theme: { colors } }) => colors.blueDark};

  font-size: 1rem;
  line-height: 1.1rem;
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const TabsSectionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${up('mobile')} {
    width: 50%;
  }
`;

const BackgroundContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;
  background-color: ${({ theme: { colors } }) => colors.grayLight};
  ${up('mobile')} {
    padding: 1.2rem;
  }
`;

const OrdersContainerStyle = styled.div`
  height: 100%;
  padding: 1rem 0rem;
  box-sizing: border-box;
  background-color: ${({ theme: { colors } }) => colors.white};
  ${up('mobile')} {
    padding: 1.2rem;
  }
`;

const StartOrderCover = styled.div`
  background-color: rgba(43, 85, 124, 0.4);
  mix-blend-mode: normal;
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  z-index: 15; //Larger than anything it covers.
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface TabsContainerProps {
  loading?: boolean;
  isSubpage?: boolean;
  redirectTo?: string;
  title?: string | null;
  shouldShowCover?: boolean;
  onClickBack?: () => void;
  onStartNewOrder?: () => void;
}

const TabsContainer: React.FC<TabsContainerProps> = ({
  loading,
  children,
  redirectTo,
  title = null,
  isSubpage = false,
  shouldShowCover = true,
  onClickBack = () => {},
  onStartNewOrder = () => {},
}) => {
  const history = useHistory();

  const [showCover, setCover] = useState(shouldShowCover);
  useEffect(() => {
    setCover(shouldShowCover);
  }, [shouldShowCover]);

  const handleStartNewOrder = () => {
    if (redirectTo) {
      history.push(redirectTo, { showCover: false });
    } else {
      onStartNewOrder();
      setCover(false);
    }
  };

  const renderButtonOrSpinner = () => (
    <StartOrderCover>
      {loading ? (
        <Spinner
          size="medium"
          style={{ zIndex: 20 }}
          color={theme.colors.blueKobelco}
        />
      ) : (
        <StartOrderButton type="button" onClick={handleStartNewOrder}>
          Start a New Order
        </StartOrderButton>
      )}
    </StartOrderCover>
  );

  return (
    <TabsSectionContainer>
      <Tabs isSubpage={isSubpage} onClickBack={onClickBack} title={title} />
      <BackgroundContainer>
        {showCover && renderButtonOrSpinner()}
        <OrdersContainerStyle>{children}</OrdersContainerStyle>
      </BackgroundContainer>
    </TabsSectionContainer>
  );
};

export default TabsContainer;
