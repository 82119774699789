export const SOTypeLabelMapper = {
  ZAB1: 'UNIT DOWN',
  ZAB2: 'EMERGENCY',
  ZAB3: 'STOCK < $500',
  ZAB4: 'STOCK ≥ $500',
  ZABB: 'NEW NAME (ZABB)',
};

type SOTypeLabelKeys = keyof typeof SOTypeLabelMapper;

export function humanizeSOType(SOType: string) {
  if (SOType === 'ZAB3' || SOType === 'ZAB4') {
    return 'STOCK';
  }
  return SOTypeLabelMapper[SOType as SOTypeLabelKeys];
}
