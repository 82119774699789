import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import React, { ReactNode } from 'react';

const FixedWrapper = styled.div<{ mobileOnly?: boolean }>`
  z-index: 10;
  display: block;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  ${up('mobile')} {
    left: auto;
    bottom: auto;
    right: auto;
    position: inherit;
    display: ${({ mobileOnly }) => (mobileOnly ? 'none' : 'block')};
  }
`;

const useActionBar = (
  component: ReactNode,
  render?: boolean,
  mobileOnly?: boolean,
) =>
  render ? (
    <FixedWrapper className="action-bar-wrapper" mobileOnly={mobileOnly}>
      {component}
    </FixedWrapper>
  ) : null;

export default useActionBar;
