import React, { FunctionComponent } from 'react';
import { up } from 'styled-breakpoints';
import get from 'lodash/get';
import styled from 'styled-components';
import SearchPart from '../../components/Search';
import Title from '../../components/Title';
import FavoritePart from '../../components/PartItem/FavoritePart';
import ResponsiveHandler from '../../components/ResponsiveHandler';
import { useFavoritePartsQuery } from '../../generated/graphql';
import Loader from '../../components/LoaderContainer';
import useWindowSize from '../../hooks/useWindowSize';
import constants, { dealerTypes } from '../../constants';
import useUser from '../../hooks/useUser';

const SearchSectionContainer = styled.div`
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: absolute;
  background-color: ${({ theme: { colors } }) => colors.blueUltraLight};
  margin-top: 4rem;
  ${up('mobile')} {
    flex-direction: row;
    position: static;
    background-color: transparent;
    margin-top: 0;
  }
`;

const PartsContainer = ResponsiveHandler(styled.div`
  padding: 0.7rem 2rem;
`);

const CategoriesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 0.1rem solid ${({ theme: { colors } }) => colors.grayLight};
  border-bottom: 0.1rem solid ${({ theme: { colors } }) => colors.grayLight};
  padding: 0.8rem 0.8rem 1.6rem 0.8rem;
  ${up('mobile')} {
    padding: 0;
  }
`;

const CategoryTitle = styled.div<{ flex: number }>`
  padding: 0;
  font-size: 0.8rem;
  line-height: normal;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  flex: ${({ flex }) => flex};
  color: ${({ theme: { colors } }) => colors.black};
  font-family: ${({ theme: { typography } }) => typography.museo};
  &:last-child {
    text-align: right;
  }
  ${up('mobile')} {
    padding: 0.8rem 0.9rem;
    &:last-child {
      text-align: ${undefined};
    }
  }
`;

const EmptyMessage = styled.div`
  text-align: center;
  padding: 3rem 1rem;
  width: calc(100% - 2rem);
  color: ${({ theme: { colors } }) => colors.blueKobelco};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const TableBody = styled.div`
  position: relative;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  &::before {
    content: '';
    border-radius: 5px;
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0px;
    left: calc(50% - 50px);
    width: 100px;
    background-color: ${({ theme: { colors } }) => colors.blueUltraLight};
    opacity: 0.9;
  }
`;

const SearchSection: FunctionComponent = () => {
  const { data: favorites, loading } = useFavoritePartsQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const user = useUser();

  const parts = get(favorites, 'favoriteParts.results', []);

  const { width } = useWindowSize();

  const mobileBreakpoint = Number(
    constants.theme.breakpoints.mobile.replace('px', ''),
  );
  const renderTableHead = () => (
    <CategoriesContainer>
      {width && width >= mobileBreakpoint ? (
        <>
          <CategoryTitle flex={2}>part number</CategoryTitle>
          <CategoryTitle flex={2}>part description</CategoryTitle>
          {user?.dealerType === dealerTypes.excavator ? (
            <>
              <CategoryTitle flex={0.45}>kty</CategoryTitle>
              <CategoryTitle flex={0.45}>jpn</CategoryTitle>
            </>
          ) : (
            <>
              <CategoryTitle flex={0.3}>kty</CategoryTitle>
              <CategoryTitle flex={0.3}>jpn</CategoryTitle>
              <CategoryTitle flex={0.3}>sg</CategoryTitle>
            </>
          )}
          {user?.dealerType === dealerTypes.excavator ? (
            <>
              <CategoryTitle flex={0.8}>list price</CategoryTitle>
              <CategoryTitle flex={0.8}>D-NET price</CategoryTitle>
            </>
          ) : (
            <CategoryTitle flex={1.6}>list price</CategoryTitle>
          )}
          <CategoryTitle flex={0.3} />
          <CategoryTitle flex={1.2} />
        </>
      ) : (
        <>
          <CategoryTitle flex={2}>part number</CategoryTitle>
          {user?.dealerType === dealerTypes.excavator ? (
            <>
              <CategoryTitle flex={0.5}>kty</CategoryTitle>
              <CategoryTitle flex={0.5}>jpn</CategoryTitle>
            </>
          ) : (
            <>
              <CategoryTitle flex={0.33}>kty</CategoryTitle>
              <CategoryTitle flex={0.33}>jpn</CategoryTitle>
              <CategoryTitle flex={0.33}>sg</CategoryTitle>
            </>
          )}
          {user?.dealerType === dealerTypes.excavator ? (
            <>
              <CategoryTitle flex={1}>list price</CategoryTitle>
              <CategoryTitle flex={1}>D-NET price</CategoryTitle>
            </>
          ) : (
            <CategoryTitle flex={2}>list price</CategoryTitle>
          )}
        </>
      )}
    </CategoriesContainer>
  );

  return (
    <SearchSectionContainer>
      <PartsContainer desktop>
        <Title label="Part Search" />
        <SearchPart
          viewOnly
          parts={[]}
          defaultSearch=""
          onOpen={() => {}}
          onClose={() => {}}
          addToCart={() => {}}
          clearDefaultSearch={() => {}}
          placeholder="Enter part number to see availability & pricing"
        />
        <Title label="Favorites" />
      </PartsContainer>
      {renderTableHead()}
      <TableBody>
        {parts.map((part: any) => (
          <FavoritePart
            part={part}
            price={part.price}
            dealerPrice={part.dealerPrice}
            sg={part.ATPstockCR}
            jpn={part.ATPstockSH}
            kty={part.ATPstockSC}
            partNum={part.number}
            partDesc={part.description}
            alternateFor={part.alternateFor}
            key={`favorite-${part.number}`}
            isMobile={Boolean(width && width < mobileBreakpoint)}
          />
        ))}
        {loading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : null}
        {!parts.length ? (
          <EmptyMessage>
            You can favorite parts for quick access by clicking the star next to
            a part number and they will appear here.
          </EmptyMessage>
        ) : null}
      </TableBody>
    </SearchSectionContainer>
  );
};

export default SearchSection;
