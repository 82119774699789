import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import Fuse from 'fuse.js';
import FlipMove from 'react-flip-move';
import utils from '../../utils';
import Title from '../../components/Title';
import ResponsiveHandler from '../../components/ResponsiveHandler';
import Loader from '../../components/LoaderContainer';
import OrderItem from '../../components/Orders/OrderItem';
import SearchInputComponent from '../../components/Inputs/Search';
import Pagination from '../../components/Pagination/index';
import SimplerDropDown from '../../components/Inputs/SimplerDropDown';
import { numDaysOptions } from '../../constants';

const SectionContainer = styled.div`
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.blueUltraLight};
  margin-bottom: 2rem;
  ${up('mobile')} {
    margin-bottom: 0;
    background-color: transparent;
    flex-direction: row;
  }
`;

const OrderContainer = ResponsiveHandler(styled.div`
  padding: 0.7rem 1rem;
`);

const TableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 0.1rem solid ${({ theme: { colors } }) => colors.grayLight};
  border-bottom: 0.1rem solid ${({ theme: { colors } }) => colors.grayLight};
  padding: 0 1rem;
`;

const TableTitle = ResponsiveHandler(styled.div`
  font-size: 0.8rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  line-height: 0.7rem;
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.black};
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`);

const EmptyMessage = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.grayADA};
  font-family: ${({ theme: { typography } }) => typography.museo};
  padding: 3rem 1rem;
`;
const DropDownDaysRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 20rem;
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const Strong = styled.span`
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-weight: 600;
`;
interface Props {
  label?: string;
  placeholder?: string;
  selected?: string;
  displayInvoice?: boolean;
  setSelected: (number?: string) => void;
  setDays?: (number: number) => void;
  days?: number;
  orders: Array<unknown>;
  loading: boolean;
  isSavedOrders?: boolean;
  actions?: Array<{
    label: string;
    method: any;
  }>;
}

const PAGE_SIZE = 10;

const SearchSection: FunctionComponent<Props> = ({
  label,
  orders,
  loading,
  selected,
  placeholder,
  setSelected,
  setDays,
  days,
  actions = undefined,
  isSavedOrders = false,
  displayInvoice = true,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState<number>(1);
  const searchOptions = {
    shouldSort: true,
    minMatchCharLength: 2,
    keys: [
      {
        name: 'number',
        weight: 0.7,
      },
      { name: 'poNumber', weight: 0.3 },
    ],
  };

  const fuse = new Fuse(orders, searchOptions);
  const filteredOrders =
    searchTerm.length <= 2 ? orders : fuse.search(searchTerm);

  const totalPages = useMemo(() => {
    return Math.ceil(orders.length / PAGE_SIZE);
  }, [orders.length]);
  const processedOrders = useMemo(() => {
    const start = PAGE_SIZE * (page - 1);
    return filteredOrders.slice(start, start + PAGE_SIZE);
  }, [filteredOrders, page]);

  const pageUpdateHandler = useCallback((newIem: { selected: number }) => {
    setPage(newIem.selected + 1);
  }, []);
  return (
    <SectionContainer>
      <OrderContainer desktop>
        <Title label={label || 'Open Orders'} />
        <ActionRow>
          <SearchInputComponent
            value={searchTerm}
            onChange={t => setSearchTerm(t)}
            placeholder={
              placeholder ||
              'Search Open Orders by Sales Order or Invoice number'
            }
          />
          {days ? (
            <DropDownDaysRow>
              <Strong>{filteredOrders.length} orders</Strong>&nbsp; in
              the&nbsp;&nbsp;
              <SimplerDropDown
                onChange={option => {
                  setSelected(undefined);
                  setDays?.(parseInt(option.value));
                }}
                options={numDaysOptions}
                value={`${days}`}
              />
            </DropDownDaysRow>
          ) : null}
        </ActionRow>
      </OrderContainer>
      <TableContainer>
        {!isSavedOrders ? (
          <TableTitle size="24%" mobileSize="50%">
            Sales Order No.
          </TableTitle>
        ) : null}
        <TableTitle desktop size={isSavedOrders ? '32%' : '20%'}>
          PO NO.
        </TableTitle>
        <TableTitle size="15%">
          {isSavedOrders ? 'Saved Date' : 'Submitted'}
        </TableTitle>
        {isSavedOrders && <TableTitle size="12.5%">Saved Total</TableTitle>}
        {!isSavedOrders ? (
          <TableTitle size="12.5%">
            {displayInvoice ? 'Invoice Total' : 'Status'}
          </TableTitle>
        ) : null}
        <TableTitle desktop size="28%" />
      </TableContainer>
      <FlipMove>
        {processedOrders.map((order: any) => (
          <div key={`order-${order.number}`}>
            <OrderItem
              displayInvoice={displayInvoice}
              selected={selected === order.number}
              onView={() => {
                setSelected(order.number);
                if (typeof window !== 'undefined') {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }
              }}
              order={{
                number: order.number,
                status: order.status,
                poNumber: order.poNumber,
                submittedAt: utils.format.date(
                  order.submittedAt || order.savedAt,
                ),
                total: order?.invoiceTotal || order?.total,
              }}
              isSavedOrders={isSavedOrders}
              actions={actions}
            />
          </div>
        ))}
      </FlipMove>
      {!loading && processedOrders?.length > 0 ? (
        <ActionRow>
          <Pagination
            page={page}
            pageCount={totalPages}
            onPageChange={pageUpdateHandler}
          />
        </ActionRow>
      ) : null}
      {loading ? <Loader /> : null}
      {!loading && !processedOrders.length ? (
        <EmptyMessage>Orders will appear here.</EmptyMessage>
      ) : null}
    </SectionContainer>
  );
};

export default SearchSection;
