import { Machine } from 'xstate';

const NewOrderFSM = Machine({
  id: 'new_order',
  initial: 'inactive',
  states: {
    inactive: {
      on: {
        START: { target: 'customerDetails' },
      },
    },
    customerDetails: {
      on: {
        SAVE: { target: 'saving' },
        CONTINUE: { target: 'editingAll' },
      },
    },
    editingAll: {
      on: {
        SAVE: { target: 'saving' },
        CONTINUE: { target: 'inventory' },
        BACK: { target: 'customerDetails' },
      },
    },
    stockHasChanged: {
      on: {
        SAVE: { target: 'saving' },
        CONTINUE: { target: 'inventory' },
      },
    },
    inventory: {
      initial: 'loading',
      states: {
        loading: {
          on: {
            STOCK: { target: 'stock' },
          },
        },
        stock: {},
      },
      on: {
        CONTINUE: { target: 'shipping' },
        EDIT_PARTS: { target: 'stockHasChanged' },
      },
    },
    shipping: {
      on: {
        SAVE: { target: 'saving' },
        CONTINUE: { target: 'submitting' },
        ADD_MORE_PARTS: { target: 'editingAll' },
      },
    },
    saving: {
      entry: ['save_order'],
      on: {
        FAILED: { target: 'editingAll' },
        COMPLETED: { target: 'inactive' },
      },
    },
    submitting: {
      entry: ['submit_order'],
      on: {
        FAILED: { target: 'editingAll' },
        COMPLETED: { target: 'finish' },
      },
    },
    finish: {
      on: {
        CONTINUE: { target: 'inactive' },
      },
    },
  },
});

export default NewOrderFSM;
