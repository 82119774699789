import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import get from 'lodash/get';
import React, { FunctionComponent, useState, useEffect } from 'react';

import withLayout from '../components/withLayout';
import OpenOrderForm from '../containers/OpenOrderForm';
import TabsContainer from '../containers/TabsContainer';
import OrdersSection from '../containers/Sections/OrdersSection';
import OrdersSectionMobile from '../containers/Sections/OrdersSectionMobile';

import { useSearchOrdersQuery, IOrderStatus } from '../generated/graphql';

import useWindowSize from '../hooks/useWindowSize';

import theme from '../constants/theme';
import useIsMobile from '../hooks/useIsMobile';

const NewOrdersContainer = styled.main`
  display: flex;
  height: 100%;
`;

const DesktopOnly = styled.div`
  display: none;
  ${up('mobile')} {
    width: 50%;
    display: block;
  }
`;

const MobileOnly = styled.div`
  display: block;
  ${up('mobile')} {
    display: none;
  }
`;

const NewOrders: FunctionComponent = () => {
  const { width } = useWindowSize();
  const isMobile = useIsMobile();
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const [days, setDays] = useState<number>(7);

  const { data, loading } = useSearchOrdersQuery({
    fetchPolicy: 'network-only',
    variables: {
      status: IOrderStatus.Open,
      days,
    },
  });

  const allOrders = get(data, 'searchOrders', []) || [];

  useEffect(() => {
    if (!selected) {
      if (allOrders.length && allOrders[0] && allOrders[0].number) {
        const mobileBreakpoint = Number(
          theme.breakpoints.mobile.replace('px', ''),
        );
        if (width && width >= mobileBreakpoint) {
          setSelected(allOrders[0].number);
        }
      }
    }
  }, [data, selected, width, allOrders]);

  const selectedOrder = selected
    ? allOrders.find(order => order && order.number === selected)
    : null;

  return (
    <NewOrdersContainer>
      <TabsContainer
        loading={loading}
        isSubpage={!!selected}
        redirectTo="/new-order"
        onClickBack={() => setSelected(undefined)}
        shouldShowCover={allOrders.length === 0 && !isMobile}
        title={selected ? 'Open Orders' : undefined}
      >
        {selected ? (
          <OpenOrderForm key={selected} order={selectedOrder} />
        ) : (
          <MobileOnly>
            <OrdersSectionMobile
              orders={allOrders}
              loading={loading}
              selected={selected}
              setSelected={nr => setSelected(nr)}
              days={days}
              setDays={setDays}
            />
          </MobileOnly>
        )}
      </TabsContainer>
      <DesktopOnly>
        <OrdersSection
          displayInvoice={false}
          orders={allOrders}
          loading={loading}
          selected={selected}
          days={days}
          setDays={setDays}
          setSelected={nr => setSelected(nr)}
        />
      </DesktopOnly>
    </NewOrdersContainer>
  );
};

export default withLayout(NewOrders);
