import React, { useState, FunctionComponent, useEffect } from 'react';
import Modal from 'react-modal';
import theme from '../../constants/theme';
import ToRGB from '../../utils/color';

Modal.setAppElement('#root');

const { r, g, b } = ToRGB(theme.colors.blueKobelco);
const { r: rd, g: gd, b: bd } = ToRGB(theme.colors.blueDark);

const customStyles = {
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    minWidth: '42%',
    minHeight: '49%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: '70vh',
    border: 'none',
    borderRadius: 0,
    boxShadow: `0px 4px 24px rgba(${rd}, ${gd}, ${bd}, 0.5)`,
  },
  overlay: {
    backgroundColor: `rgba(${r}, ${g}, ${b}, 0.8)`,
    zIndex: 1000,
  },
};

interface ModalProps {
  isOpen?: boolean;
  afterOpenModal?: () => void;
  onClose?: () => void;
  title?: string;
}

const InventoryStatusModal: FunctionComponent<ModalProps> = ({
  isOpen = false,
  afterOpenModal = (): void => {},
  onClose = (): void => {},
  title = 'Example Modal',
  children,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(isOpen);
  useEffect(() => {
    setModalIsOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel={title}
    >
      {children}
    </Modal>
  );
};

export default InventoryStatusModal;
