import styled from 'styled-components';

import React, { FunctionComponent } from 'react';

import ModalLayout from './Layout';
import CustomerDetails from '../CustomerDetails';
import NewOrderHeader from '../Orders/NewOrderHeader';

import formatter from '../../utils/formatters';

import { ReactComponent as chevron } from '../../assets/images/chevron.svg';
import { dealerTypes } from '../../constants';
import useUser from '../../hooks/useUser';

const ModalBody = styled.div`
  padding: 1.5rem;
`;

const ModalDivider = styled.div<{ big?: boolean; bottom?: number }>`
  width: 100%;
  height: ${({ big }) => (big ? '0.5rem' : '0.1rem')};
  background-color: ${({ theme }) => theme.colors.grayLight};
  margin-bottom: ${({ bottom }) => (bottom ? `${bottom}rem` : 0)};
`;

const TablesContainer = styled.div`
  overflow: auto;
  max-height: 320px;
`;

const ModalTable = styled.table`
  width: 100%;
  margin-bottom: 4rem;
  border-collapse: collapse;
  :last-child {
    margin-bottom: 0;
  }
`;

const TableRow = styled.tr`
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.grayLight};
`;

const TableTitle = styled.th<{ align: 'left' | 'right'; size: 'lg' | 'sm' }>`
  font-size: 1rem;
  font-weight: normal;
  padding-bottom: 0.8rem;
  text-align: ${({ align }) => align};
  color: ${({ theme }) => theme.colors.blueNeutral};
  font-family: ${({ theme }) => theme.typography.museo};
  width: ${({ size }) => (size === 'lg' ? '45%' : 'calc(55% / 3)')};
`;

const TableContent = styled.th<{ align: 'left' | 'right' }>`
  font-size: 1rem;
  font-weight: normal;
  padding-top: 1rem;
  padding-bottom: 0.8rem;
  text-align: ${({ align }) => align};
  color: ${({ theme }) => theme.colors.grayDark};
  font-family: ${({ theme }) => theme.typography.museo};
`;

const ModalOptionsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ModalOptionsSeparator = styled.div`
  width: 0.1rem;
  height: 2.4rem;
  background-color: ${({ theme }) => theme.colors.grayLight};
`;

const LeftChevron = styled(chevron)`
  transform: rotate(90deg);
  color: ${({ theme }) => theme.colors.blueNeutral};
`;

const ModalOption = styled.a`
  font-size: 1rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blueKobelco};
  font-family: ${({ theme }) => theme.typography.museo};
`;

const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SubmitButton = styled.button`
  margin: 0;
  border: none;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 1rem 2.4rem;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grayDark : theme.colors.white};
  font-family: ${({ theme }) => theme.typography.museo};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.whiteSmoke : theme.colors.blueKobelco};
`;

const NotesTitle = styled.p`
  text-align: center;
  margin: 0 0 0.8rem 0;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.typography.museo};
`;

const NotesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.colors.grayLight};
  padding: 1rem;
  max-width: 75rem;
`;

const NotesTextArea = styled.textarea`
  width: 100%;
  border: none;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.typography.museo};
  font-size: 1rem;
`;

interface NewOrderModalProps {
  form: any;
  title?: string;
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  onDoneforNow?: () => void;
  onAddMoreParts?: () => void;
}

const NewOrderModal: FunctionComponent<NewOrderModalProps> = ({
  form,
  isOpen,
  onClose,
  onSubmit = () => {},
  onDoneforNow = () => {},
  onAddMoreParts = () => {},
}) => {
  const formik = form;
  const user = useUser();

  const renderBackOrderParts = () => {
    if (
      formik.values.parts.filter((part: any) => part.ATPstockSC < part.qty)
        .length !== 0
    ) {
      return (
        <ModalTable>
          <thead>
            <tr>
              <TableTitle size="lg" align="left">
                Back Order Parts
              </TableTitle>
              <TableTitle size="sm" align="right">
                Unit
              </TableTitle>
              <TableTitle size="sm" align="right">
                QTY
              </TableTitle>
              <TableTitle size="sm" align="right">
                Total
              </TableTitle>
            </tr>
          </thead>
          <tbody>
            {formik.values.parts.map((part: any) => {
              const stock = part.ATPstockSC;
              const toBackOrder = stock < part.qty ? part.qty - stock : 0;

              const price =
                user?.dealerType === dealerTypes.excavator
                  ? part.dealerPrice || part.price
                  : part.price;
              if (toBackOrder !== 0) {
                return (
                  <TableRow key={`part-${part.number}-${part.inputNumber}`}>
                    <TableContent align="left">{part.number}</TableContent>
                    <TableContent align="right">
                      {formatter.currency(price)}
                    </TableContent>
                    <TableContent align="right">{toBackOrder}</TableContent>
                    <TableContent align="right">
                      {formatter.currency(price * toBackOrder)}
                    </TableContent>
                  </TableRow>
                );
              }

              return null;
            })}
          </tbody>
        </ModalTable>
      );
    }

    return null;
  };

  const renderInStockParts = () => (
    <ModalTable>
      <thead>
        <tr>
          <TableTitle size="lg" align="left">
            In Stock Parts
          </TableTitle>
          <TableTitle size="sm" align="right">
            Unit
          </TableTitle>
          <TableTitle size="sm" align="right">
            QTY
          </TableTitle>
          <TableTitle size="sm" align="right">
            Total
          </TableTitle>
        </tr>
      </thead>
      <tbody>
        {formik.values.parts.map((part: any) => {
          const stock = part.ATPstockSC;
          const toOrder = stock < part.qty ? stock : part.qty;

          const price =
            user?.dealerType === dealerTypes.excavator
              ? part.dealerPrice || part.price
              : part.price;

          if (stock !== 0) {
            return (
              <TableRow key={`part-stock-${part.number}-${part.inputNumber}`}>
                <TableContent align="left">{part.number}</TableContent>
                <TableContent align="right">
                  {formatter.currency(price)}
                </TableContent>
                <TableContent align="right">{toOrder}</TableContent>
                <TableContent align="right">
                  {formatter.currency(price * toOrder)}
                </TableContent>
              </TableRow>
            );
          }

          return null;
        })}
      </tbody>
    </ModalTable>
  );

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} spaceBetween>
      <ModalBody>
        <NewOrderHeader order={formik.values} parts={formik.values.parts} />
        <CustomerDetails disabled formController={formik} />
        <ModalDivider bottom={2.4} />
        <TablesContainer>
          {renderInStockParts()}
          {renderBackOrderParts()}
        </TablesContainer>
      </ModalBody>
      <div>
        <ModalDivider big />
        <ModalBody>
          {user?.dealerType !== dealerTypes.excavator && (
            <>
              <NotesTitle>Add a Note:</NotesTitle>
              <NotesContainer>
                <NotesTextArea
                  value={formik.values.notes}
                  onChange={e =>
                    formik.setValues({
                      ...formik.values,
                      notes: e.target.value,
                    })
                  }
                />
              </NotesContainer>
            </>
          )}
          <ModalDivider bottom={0.8} />
          <SubmitRow>
            <ModalOptionsRow>
              <ModalOption
                href="#"
                style={{ marginRight: '1.6rem' }}
                onClick={e => {
                  e.preventDefault();
                  onAddMoreParts();
                }}
              >
                <LeftChevron /> Edit Order
              </ModalOption>
              <ModalOptionsSeparator />
              <ModalOption
                href="#"
                style={{ marginLeft: '1.6rem' }}
                onClick={e => {
                  e.preventDefault();
                  onDoneforNow();
                }}
              >
                I&apos;m done for now
              </ModalOption>
            </ModalOptionsRow>
            <SubmitButton
              type="button"
              onClick={e => {
                e.preventDefault();
                onSubmit();
              }}
            >
              Submit Order
            </SubmitButton>
          </SubmitRow>
        </ModalBody>
      </div>
    </ModalLayout>
  );
};

export default NewOrderModal;
