import styled from 'styled-components';

const Line = styled.div`
  width: 100%;
  height: 0.1rem;
  margin: 0.7rem 0;
  background-color: ${({ theme: { colors } }) => colors.grayLight};
`;

export default Line;
