import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlatButtonColor } from '../styled/buttons';
import ResponsiveHandler from '../ResponsiveHandler';
import theme from '../../constants/theme';

const Container = ResponsiveHandler(styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`);

const Placeholder = styled.div`
  color: ${({ theme: { colors } }) => colors.grayADA};
  font-size: 1rem;
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-style: normal;
  font-weight: normal;
  margin-bottom: 1.6rem;
`;

interface IncompleteOrderProps {
  onClick: () => void;
}

const IncompleteOrderPlaceholder: FunctionComponent<IncompleteOrderProps> = ({
  onClick,
}) => (
  <Container desktop>
    <Placeholder>
      Fill out the order details above to start adding parts to your cart
    </Placeholder>
    <FlatButtonColor
      type="button"
      disabled={true}
      onClick={onClick}
      color={theme.colors.grayADA}
      borderColor={theme.colors.blueLight}
      hoverColor={theme.colors.blueKobelco}
    >
      + Add Items
    </FlatButtonColor>
  </Container>
);

export default IncompleteOrderPlaceholder;
