import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import useUser from '../../hooks/useUser';
import { dealerTypes } from '../../constants';
import formatters from '../../utils/formatters';
import { getOrderTotal } from '../../utils/getOrderTotal';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.span`
  font-size: 1rem;
  line-height: normal;
  color: ${({ theme }) => theme.colors.blueNeutral};
  font-family: ${({ theme }) => theme.typography.museo};
`;

const Value = styled.span`
  font-size: 1.8rem;
  line-height: normal;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.typography.museo};
`;

interface NewOrderHeaderProps {
  order?: any;
  parts?: Array<any>;
}

const NewOrderHeader: FunctionComponent<NewOrderHeaderProps> = ({
  parts = [],
  order,
}) => {
  const user = useUser();
  const isExcavatorUser = user?.dealerType === dealerTypes.excavator;

  return (
    <Container>
      <Row style={{ marginBottom: '0.2rem' }}>
        <Label>Sales Order Number</Label>
        <Label>Total</Label>
      </Row>
      <Row>
        <Value>{order ? order.number : '--'}</Value>
        <Value>
          {formatters.currency(getOrderTotal(parts, isExcavatorUser))}
        </Value>
      </Row>
    </Container>
  );
};

export default NewOrderHeader;
