/* eslint-disable @typescript-eslint/indent */
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import ReactTooltip from 'react-tooltip';
import React, { FunctionComponent, useState } from 'react';

import StarSwitch from '../StarSwitch';
import StepCounter from '../StepCounter';

import { ReactComponent as chevron } from '../../../assets/images/chevron.svg';

import {
  useFavoritePartMutation,
  useUnfavoritePartMutation,
} from '../../../generated/graphql';

import formatters from '../../../utils/formatters';
import { dealerTypes } from '../../../constants';
import useUser from '../../../hooks/useUser';

const Container = styled.div<{
  column?: boolean;
  isAlternate?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  border-bottom: 1px solid
    ${({ theme, isAlternate }) =>
      isAlternate ? theme.colors.grayLight : 'transparent'};
  background-color: ${({ theme, isAlternate }) =>
    isAlternate ? theme.colors.blueUltraLight : theme.colors.white};

  div,
  span {
    opacity: ${({ disabled = false }) => (disabled ? 0.5 : 1)};
  }
`;

interface ContentProps {
  flex: number;
  isBlue?: boolean;
  tooltip?: boolean;
  flexEnd?: boolean;
  isAlternate?: boolean;
}

const Content = styled.div<ContentProps>`
  font-size: 1rem;
  padding: 0 0.8rem;
  line-height: normal;
  flex: ${({ flex }) => flex};
  font-family: ${({ theme }) => theme.typography.museo};
  overflow: ${({ tooltip }) => (tooltip ? 'hidden' : undefined)};
  white-space: ${({ tooltip }) => (tooltip ? 'nowrap' : undefined)};
  text-overflow: ${({ tooltip }) => (tooltip ? 'ellipsis' : undefined)};
  display: ${({ flexEnd }) => (flexEnd ? 'flex' : undefined)};
  justify-content: ${({ flexEnd }) => (flexEnd ? 'flex-end' : undefined)};
  color: ${({ theme, isBlue }) =>
    isBlue ? theme.colors.blueDark : theme.colors.grayDark};
  ${up('mobile')} {
    padding: 0.8rem 0.8rem;
  }
`;

const ExtraContent = styled.span<{ flex: number; tooltip?: boolean }>`
  font-size: 0.8rem;
  padding: 0 0.8rem;
  line-height: normal;
  flex: ${({ flex }) => flex};
  color: ${({ theme }) => theme.colors.grayDark};
  font-family: ${({ theme }) => theme.typography.museo};
  overflow: ${({ tooltip }) => (tooltip ? 'hidden' : undefined)};
  white-space: ${({ tooltip }) => (tooltip ? 'nowrap' : undefined)};
  text-overflow: ${({ tooltip }) => (tooltip ? 'ellipsis' : undefined)};
`;

const ExtraContentTitle = styled.span<{ flex: number }>`
  font-size: 0.8rem;
  padding: 0 0.8rem;
  line-height: normal;
  text-transform: uppercase;
  flex: ${({ flex }) => flex};
  color: ${({ theme }) => theme.colors.grayADA};
  font-family: ${({ theme }) => theme.typography.museo};
`;

const MobileAlternatePartLabel = styled.span`
  font-weight: 300;
  font-size: 0.8rem;
  font-style: italic;
  font-family: ${({ theme }) => theme.typography.museo};
`;

const ContentRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MoreInfoAction = styled.div`
  font-size: 1rem;
  line-height: normal;
  color: ${({ theme }) => theme.colors.grayDark};
  font-family: ${({ theme }) => theme.typography.museo};
`;

const ChevronIcon = styled(chevron)<{ isUp?: boolean }>`
  transition: transform 0.12s ease-in-out;
  color: ${({ theme }) => theme.colors.grayDark};
  transform: ${({ isUp }) => (isUp ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const CustomReactTooltip = styled(ReactTooltip)`
  font-size: 1.14rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  opacity: 1 !important;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  font-family: ${({ theme }) => theme.typography.museo};
  color: ${({ theme }) => theme.colors.black} !important;
  background-color: ${({ theme }) => theme.colors.white} !important;
  &::after,
  &::before {
    display: none;
  }
`;

interface SearchResultsDropDownPartProps {
  alternate?: boolean;
  alternateFor?: string;
  dealerPrice?: number;
  description: string;
  dimensions?: string;
  dimensionUnit?: string;
  disabled?: boolean;
  height?: string;
  isFavorite?: boolean;
  isMobile?: boolean;
  jpn: number;
  kty: number;
  length?: string;
  number: string;
  price: number;
  qty: number;
  sg: number;
  viewOnly?: boolean;
  weight: number;
  weightUnit?: string;
  width?: string;
  setQty?: (qty: number) => void;
}

const SearchResultsDropDownPart: FunctionComponent<SearchResultsDropDownPartProps> = ({
  alternateFor,
  dealerPrice,
  description,
  dimensions,
  dimensionUnit,
  disabled = false,
  height,
  isFavorite = false,
  isMobile,
  jpn,
  kty,
  length,
  number,
  price,
  qty,
  setQty = _num => {},
  sg,
  viewOnly = false,
  weight,
  weightUnit,
  width,
}) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  const [favoriteMutation] = useFavoritePartMutation({
    variables: { partNumber: number },
    optimisticResponse: {
      __typename: 'Mutation',
      favoritePart: {
        __typename: 'Part',
        number,
      },
    },
    refetchQueries: ['currentUser', 'favoriteParts'],
  });
  const [unfavoriteMutation] = useUnfavoritePartMutation({
    variables: { partNumber: number },
    refetchQueries: ['currentUser', 'favoriteParts'],
  });

  const mutation = isFavorite ? unfavoriteMutation : favoriteMutation;

  const formattedPrice = formatters.currency(price);
  const formattedDealerPrice = formatters.currency(dealerPrice || 0);
  const numberTooltip = `Number: ${number}${
    alternateFor ? ` (New Part Number for: ${alternateFor})` : ''
  }`;

  const user = useUser();

  if (isMobile) {
    return (
      <Container
        disabled={disabled}
        column
        isAlternate={!!alternateFor}
        style={{
          paddingTop: '0.6rem',
          paddingBottom: '0.4rem',
          marginBottom: '0.4rem',
        }}
      >
        <ContentRow style={{ marginBottom: '0.8rem' }}>
          <Content isBlue flex={1.6}>
            {!!alternateFor && (
              <>
                <MobileAlternatePartLabel>
                  New Part Number
                </MobileAlternatePartLabel>
                <br />
              </>
            )}
            {number}
          </Content>
          {user?.dealerType === dealerTypes.excavator ? (
            <>
              <Content flex={0.9}>{kty}</Content>
              <Content flex={0.9}>{jpn}</Content>
            </>
          ) : (
            <>
              <Content flex={0.6}>{kty}</Content>
              <Content flex={0.6}>{jpn}</Content>
              <Content flex={0.6}>{sg}</Content>
            </>
          )}
          <Content flex={1.6} style={{ textAlign: 'right' }}>
            {formattedPrice}
            {dealerPrice && <br />}
            {dealerPrice && formattedDealerPrice}
          </Content>
        </ContentRow>
        <ContentRow style={{ alignItems: 'center' }}>
          <Content flex={1}>
            <MoreInfoAction onClick={() => setExpanded(!isExpanded)}>
              More Info <ChevronIcon isUp={isExpanded} />
            </MoreInfoAction>
          </Content>
          <Content flex={1} flexEnd={!viewOnly}>
            {!viewOnly ? (
              <StepCounter num={qty} setNum={setQty} disabled={disabled} />
            ) : (
              <StarSwitch
                isFavorite={isFavorite}
                disabled={disabled}
                onClick={() => mutation()}
              />
            )}
          </Content>
        </ContentRow>
        {isExpanded && (
          <div
            className={`more-info-${number}`}
            style={{ width: '100%', marginBottom: '1rem' }}
          >
            <ContentRow>
              <ExtraContentTitle flex={1}>Description</ExtraContentTitle>
              <ExtraContentTitle flex={1} style={{ textAlign: 'center' }}>
                Dimensions
              </ExtraContentTitle>
              <ExtraContentTitle flex={1} style={{ textAlign: 'right' }}>
                Weight
              </ExtraContentTitle>
            </ContentRow>
            <ContentRow style={{ marginTop: '0.4rem' }}>
              <ExtraContent flex={1} tooltip>
                {description}
              </ExtraContent>
              <ExtraContent flex={1} tooltip style={{ textAlign: 'center' }}>
                {length} x {width} x {height} (
                {String(dimensionUnit).toLowerCase()})
              </ExtraContent>
              <ExtraContent flex={1} tooltip style={{ textAlign: 'right' }}>
                {weight} ({String(weightUnit).toLowerCase()})
              </ExtraContent>
            </ContentRow>
          </div>
        )}
      </Container>
    );
  }

  return (
    <Container isAlternate={!!alternateFor} disabled={disabled}>
      <Content
        isBlue
        tooltip
        data-tip
        flex={2}
        isAlternate={!!alternateFor}
        data-for={`${number}-number`}
      >
        <span style={{ paddingLeft: alternateFor ? '0.8rem' : undefined }}>
          {!!alternateFor && (
            <>
              <span style={{ fontStyle: 'italic' }}>New Part Number</span>
              <br />
            </>
          )}
        </span>
        <span style={{ paddingLeft: alternateFor ? '0.8rem' : undefined }}>
          {number}
        </span>
      </Content>

      <CustomReactTooltip multiline effect="solid" id={`${number}-number`}>
        {numberTooltip}
      </CustomReactTooltip>
      <Content tooltip data-tip flex={2} data-for={`${number}-description`}>
        {description}
      </Content>
      <CustomReactTooltip multiline effect="solid" id={`${number}-description`}>
        {description}
      </CustomReactTooltip>

      <Content tooltip data-tip flex={1.4} data-for={`${number}-dimensions`}>
        {length} x {width} x {height} ({String(dimensionUnit).toLowerCase()})
      </Content>
      <CustomReactTooltip multiline effect="solid" id={`${number}-dimensions`}>
        {length} x {width} x {height} ({String(dimensionUnit).toLowerCase()})
      </CustomReactTooltip>

      <Content flex={1}>
        {weight} ({String(weightUnit).toLowerCase()})
      </Content>
      {user?.dealerType === dealerTypes.excavator ? (
        <>
          <Content flex={0.3}>{kty}</Content>
          <Content flex={0.3}>{jpn}</Content>
        </>
      ) : (
        <>
          <Content flex={0.2}>{kty}</Content>
          <Content flex={0.2}>{jpn}</Content>
          <Content flex={0.2}>{sg}</Content>
        </>
      )}

      <Content flex={1}>{formattedPrice}</Content>
      {user?.dealerType === dealerTypes.excavator && (
        <Content flex={1}>{formattedDealerPrice}</Content>
      )}

      <Content flex={1}>
        {!viewOnly ? (
          <StepCounter num={qty} setNum={setQty} disabled={disabled} />
        ) : (
          <StarSwitch
            disabled={disabled}
            isFavorite={isFavorite}
            onClick={() => mutation()}
          />
        )}
      </Content>
    </Container>
  );
};

export default SearchResultsDropDownPart;
