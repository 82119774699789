import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import './index.css';
import ToRGB from '../../utils/color';

const Spin = styled.div`
  border-radius: 100%;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader 1.1s infinite linear;
  animation: loader 1.1s infinite linear;
`;

interface SpinnerProps {
  style?: any;
  color?: string;
  size?: 'small' | 'medium' | 'large';
}
const Spinner: FunctionComponent<SpinnerProps> = ({
  size = 'small',
  color = 'white',
  style,
}) => {
  let width;
  let fontSize;
  let borderSize;
  switch (size) {
    case 'small':
    default:
      width = '1.2rem';
      fontSize = '0.1rem';
      borderSize = '0.2rem';
      break;
    case 'medium':
      width = '3rem';
      fontSize = '0.3rem';
      borderSize = '10px';
      break;
    case 'large':
      width = '5rem';
      fontSize = '0.5rem';
      borderSize = '1.1rem';
      break;
  }

  const { r, g, b } = ToRGB(color);

  return (
    <Spin
      className="loader"
      style={{
        width,
        fontSize,
        height: width,
        borderTop: `${borderSize} solid rgba(${r}, ${g}, ${b}, 0.2)`,
        borderRight: `${borderSize} solid rgba(${r}, ${g}, ${b}, 0.2)`,
        borderBottom: `${borderSize} solid rgba(${r}, ${g}, ${b}, 0.2)`,
        borderLeft: `${borderSize} solid ${color}`,
        ...style,
      }}
    />
  );
};

export default Spinner;

Spinner.defaultProps = {
  size: 'small',
};
