import theme from './theme';

export interface Option {
  value: string;
  label: string;
}

export interface CompoundOption {
  value: Array<string>;
  label: string;
}

const {
  REACT_APP_GRAPHQL_URL,
  REACT_APP_RESET_URL,
  REACT_APP_ROLLBAR_TOKEN,
  REACT_APP_ENVIRONMENT,
} = process.env;

const configs = {
  ENVIRONMENT: REACT_APP_ENVIRONMENT || 'development',
  API_HOST: REACT_APP_GRAPHQL_URL
    ? String(REACT_APP_GRAPHQL_URL).replace('/graphql', '')
    : 'http://localhost:8080',
  GRAPHQL_URL: REACT_APP_GRAPHQL_URL || 'http://localhost:8080/graphql',
  PASSWORD_RESET:
    REACT_APP_RESET_URL ||
    'https://www.kobelcoportal.com/idm/reset/PwResetRequest.do',
  ROLLBAR_TOKEN: REACT_APP_ROLLBAR_TOKEN || '53a26a61679345feaa8bf2689d6c59e7',
};

const deliveryOptions: Option[] = [
  { value: 'saturday_delivery', label: 'Saturday Delivery' },
  { value: 'first_overnight', label: 'First Overnight' },
  { value: 'priority_overnight', label: 'Priority Overnight' },
  { value: 'fedex_express_saver', label: 'Fedex Express Saver' },
];

export const USStateOptions: Option[] = [
  { value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MP', label: 'Northern Mariana Isl' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MT', label: 'Montana' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VT', label: 'Vermont' },
  { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' },
];

export const CAStateOptions: Option[] = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NF', label: 'Newfoundland' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'NT', label: 'Northwest Territory' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'YT', label: 'Yukon' },
  { value: 'ZZ', label: 'Outside province/Can' },
];

export type TStateOptions = {
  CA: Option[];
  US: Option[];
};

export const stateOptions: TStateOptions = {
  CA: CAStateOptions,
  US: USStateOptions,
};

export enum dealerTypes {
  crane = 'CRANE',
  excavator = 'EXCAVATOR',
}
export default { theme, configs, deliveryOptions };

export const numDaysOptions = [
  { label: 'Last 7 days', value: '7' },
  { label: 'Last 15 days', value: '15' },
  { label: 'Last 30 days', value: '30' },
  { label: 'Last 90 days', value: '90' },
  { label: 'Last 120 days', value: '120' },
  { label: 'Last 180 days', value: '180' },
];
