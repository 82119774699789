import React, { FunctionComponent } from 'react';

import {
  ActionBarRow,
  ActionBarLine,
  PrimaryAction,
  SecondaryAction,
  ActionBarWrapper,
} from './styles';

interface Action {
  label: string;
  disabled?: boolean;
  onClick: () => void;
}

interface ActionBarProps {
  primaryAction: Action;
  secondaryAction?: Action;
}

const ActionBar: FunctionComponent<ActionBarProps> = ({
  primaryAction,
  secondaryAction,
}) => (
  <ActionBarWrapper>
    <ActionBarLine />
    <ActionBarRow hasBothActions={!!secondaryAction}>
      {secondaryAction && (
        <SecondaryAction
          onClick={secondaryAction.onClick}
          disabled={secondaryAction.disabled}
        >
          {secondaryAction.label}
        </SecondaryAction>
      )}
      <PrimaryAction
        onClick={primaryAction.onClick}
        disabled={primaryAction.disabled}
      >
        {primaryAction.label}
      </PrimaryAction>
    </ActionBarRow>
  </ActionBarWrapper>
);

export default ActionBar;
