import Modal from 'react-modal';
import styled from 'styled-components';
import React, { FunctionComponent, useState, useEffect } from 'react';

import ToRGB from '../../utils/color';
import theme from '../../constants/theme';

Modal.setAppElement('#root');

const { r, g, b } = ToRGB(theme.colors.blueKobelco);
const { r: rd, g: gd, b: bd } = ToRGB(theme.colors.blueDark);

const customStyles: Modal.Styles = {
  content: {
    top: undefined,
    left: undefined,
    right: undefined,
    bottom: undefined,
    border: 'none',
    borderRadius: 0,
    margin: '1rem',
    width: '100%',
    maxWidth: '520px',
    padding: '2.8rem 4rem',
    boxSizing: 'border-box',
    backgroundColor: theme.colors.white,
    boxShadow: `0px 4px 24px rgba(${rd}, ${gd}, ${bd}, 0.5)`,
  },
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `rgba(${r}, ${g}, ${b}, 0.8)`,
    zIndex: 11,
  },
};

const ModalTitle = styled.h1`
  font-size: 1.7rem;
  text-align: center;
  font-weight: normal;
  margin: 0 0 0.8rem 0;
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const ModalSubtitle = styled.h2`
  margin: 0;
  font-weight: 300;
  font-size: 1.2rem;
  text-align: center;
  font-weight: normal;
  color: ${({ theme: { colors } }) => colors.grayDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1.4rem;
`;

const ActionButton = styled.button<{ cancel?: boolean }>`
  padding: 1rem;
  outline: none;
  cursor: pointer;
  min-width: 130px;
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors }, cancel }) =>
    cancel ? colors.white : colors.blueNeutral};
  background-color: ${({ theme: { colors }, cancel }) =>
    cancel ? colors.blueKobelco : colors.white};
  border: 0.1rem solid
    ${({ theme: { colors }, cancel }) =>
      cancel ? colors.blueKobelco : colors.blueNeutral};
`;

interface DeleteCartModalProps {
  isOpen?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const DeleteCartModal: FunctionComponent<DeleteCartModalProps> = ({
  isOpen = false,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(isOpen);
  useEffect(() => {
    setModalIsOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={onCancel}>
      <ModalTitle>Are you sure?</ModalTitle>
      <ModalSubtitle>Are you sure you want to delete this cart?</ModalSubtitle>
      <ActionsRow>
        <ActionButton
          type="button"
          onClick={onConfirm}
          style={{ marginRight: '1.9rem' }}
        >
          Yes
        </ActionButton>
        <ActionButton cancel type="button" onClick={onCancel}>
          Cancel
        </ActionButton>
      </ActionsRow>
    </Modal>
  );
};

export default DeleteCartModal;
