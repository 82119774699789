import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import get from 'lodash/get';
import React, { useState, FunctionComponent, useEffect } from 'react';

import ActionBar from '../../ActionBar';
import Loader from '../../LoaderContainer';
import SearchResultsPart from './SearchResultsPart';
import { RegularButton } from '../../styled/buttons';

import useActionBar from '../../../hooks/useActionBar';
import useWindowSize from '../../../hooks/useWindowSize';
import { useCurrentUserQuery } from '../../../generated/graphql';

import constants, { dealerTypes } from '../../../constants';
import { isPartOrderable } from '../../../utils/isPartOrderable';
import useUser from '../../../hooks/useUser';

const SearchResultsContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.whiteSmoke};
  ${down('mobile')} {
    margin-left: -2rem;
    margin-right: -2rem;
    width: calc(100% + 4rem);
  }
`;
const HeaderContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0;
`;

interface HeaderItemProps {
  flex?: number;
  align?: 'left' | 'center' | 'right';
}

const HeaderItem = styled.span<HeaderItemProps>`
  font-size: 0.8rem;
  padding: 0 0.8rem;
  line-height: normal;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  flex: ${({ flex }) => flex};
  text-align: ${({ align }) => align};
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.black} !important;
  ${up('mobile')} {
    font-size: 0.7rem;
  }
`;

const AddToCartContainer = styled.div`
  margin-top: 1rem;
  background-color: ${({ theme: { colors } }) => colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0.4rem;
  padding: 0.7rem 1.8rem;
`;

const EmptyContainer = styled(AddToCartContainer)`
  background-color: ${({ theme: { colors } }) => colors.whiteSmoke};
  margin-top: 0;
`;

const AddToCart = styled(RegularButton)`
  width: 9rem;
  height: 3rem;
  &:disabled {
    background: ${({ theme: { colors } }) => colors.grayLight};
    color: ${({ theme: { colors } }) => colors.grayDark};
  }
  display: none;
  ${up('mobile')} {
    display: block;
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 3rem 0;
  background-color: ${({ theme: { colors } }) => colors.white};
  & > span {
    font-family: ${({ theme: { typography } }) => typography.museo};
    color: ${({ theme: { colors } }) => colors.gray};
    font-size: 1.3rem;
  }
`;

interface SearchDropDownProps {
  items: any[];
  isOpen?: boolean;
  loading?: boolean;
  selectedItems: any[];
  addToCart?: (items: any[]) => void;
  viewOnly?: boolean;
}

const getActionStatus = (
  initialParts: Array<any>,
  selectedParts: Array<any>,
): string => {
  const partsRemoved = [];
  const partsAdded = [];

  initialParts.forEach((part: any) => {
    const selected = selectedParts.find(
      (selPart: any) =>
        selPart.number === part.number &&
        selPart.inputNumber === part.inputNumber,
    );
    if (!selected || selected.qty < part.qty) {
      partsRemoved.push({
        ...part,
        qty: part.qty - selected && selected.qty ? selected.qty : 0,
      });
    }
  });

  selectedParts.forEach((part: any) => {
    const initial = initialParts.find(
      (inPart: any) =>
        inPart.number === part.number &&
        inPart.inputNumber === part.inputNumber,
    );
    if (!initial || part.qty > initial.qty) {
      partsAdded.push({
        ...part,
        qty: part.qty - initial && initial.qty ? initial.qty : 0,
      });
    }
  });

  if (!initialParts.length && partsAdded.length) {
    return 'add';
  }
  if (!partsRemoved.length && !partsAdded.length) {
    return 'none';
  }
  return 'update';
};

const SearchResultsDropDown: FunctionComponent<SearchDropDownProps> = ({
  items,
  isOpen,
  loading = false,
  viewOnly = false,
  selectedItems = [],
  addToCart = _items => {},
}) => {
  const [initialParts, setInitialParts] = useState<any[]>(selectedItems);
  const [selectedParts, setSelectedParts] = useState<any[]>(selectedItems);

  const user = useUser();

  useEffect(() => {
    setInitialParts(selectedItems);
    setSelectedParts(selectedItems);
  }, [selectedItems]);

  const allParts = [
    ...items.map(item => ({
      ...item,
      ...(selectedParts.find(
        part =>
          part.number === item.number && item.inputNumber === part.inputNumber,
      )
        ? selectedParts.find(
            part =>
              part.number === item.number &&
              item.inputNumber === part.inputNumber,
          )
        : {}),
    })),
  ];

  const { data: currentUser } = useCurrentUserQuery();
  const favorites = get(currentUser, 'currentUser.favorites', []);
  const formStatus = getActionStatus(initialParts, selectedParts);

  const isPartFavorite = (item: any) =>
    favorites.findIndex(
      (part: any) =>
        part.number === item.number && item.inputNumber === part.inputNumber,
    ) > -1;

  const setQtyForPart = (qty: number, item: any) => {
    const index = selectedParts.findIndex(
      part =>
        part.number === item.number && item.inputNumber === part.inputNumber,
    );

    if (qty > 0) {
      if (index > -1) {
        setSelectedParts([
          ...selectedParts.slice(0, index),
          { ...item, qty },
          ...selectedParts.slice(index + 1),
        ]);
      } else {
        setSelectedParts([...selectedParts, { ...item, qty }]);
      }
    } else if (index > -1) {
      setSelectedParts([
        ...selectedParts.slice(0, index),
        ...selectedParts.slice(index + 1),
      ]);
    }
  };

  const { width } = useWindowSize();
  const mobileBreakpoint = Number(
    constants.theme.breakpoints.mobile.replace('px', ''),
  );

  const renderPart = (item: any, index: any) => (
    <SearchResultsPart
      alternateFor={
        item.number !== item.inputNumber ? item.inputNumber : undefined
      }
      disabled={!isPartOrderable(item, user?.dealerType || dealerTypes.crane)}
      description={item.description}
      dimensions={item.dimensions}
      isFavorite={isPartFavorite(item)}
      isMobile={Boolean(width && width < mobileBreakpoint)}
      jpn={item.ATPstockSH}
      key={`part-${item.number}-${item.inputNumber}-${index}`}
      kty={item.ATPstockSC}
      number={item.number}
      price={item.price}
      dealerPrice={item.dealerPrice}
      qty={item.qty}
      setQty={qty => setQtyForPart(qty, item)}
      sg={item.ATPstockCR}
      viewOnly={viewOnly}
      length={item.length}
      width={item.width}
      height={item.height}
      weightUnit={item.weightUnit}
      dimensionUnit={item.dimensionUnit}
      weight={item.weight || item.grossWeight || 0}
    />
  );

  const Empty = (
    <EmptyState>
      <span>Search for a part and your results will appear here</span>
    </EmptyState>
  );

  const renderHeaderContainer = () => (
    <HeaderContainer>
      {width && width >= mobileBreakpoint ? (
        <>
          <HeaderItem flex={2}>Part Number</HeaderItem>
          <HeaderItem flex={2}>Part Description</HeaderItem>
          <HeaderItem flex={1.4}>Dimensions</HeaderItem>
          <HeaderItem flex={1}>Weight</HeaderItem>
          {user?.dealerType === dealerTypes.excavator ? (
            <>
              <HeaderItem flex={0.3}>kty</HeaderItem>
              <HeaderItem flex={0.3}>jpn</HeaderItem>
            </>
          ) : (
            <>
              <HeaderItem flex={0.2}>kty</HeaderItem>
              <HeaderItem flex={0.2}>jpn</HeaderItem>
              <HeaderItem flex={0.2}>sg</HeaderItem>
            </>
          )}
          <HeaderItem flex={1}>List Price</HeaderItem>
          {user?.dealerType === dealerTypes.excavator && (
            <HeaderItem flex={1}>D-NET Price</HeaderItem>
          )}
          <HeaderItem flex={1} align={!viewOnly ? 'center' : undefined}>
            {viewOnly ? 'Favorite' : 'QTY'}
          </HeaderItem>
        </>
      ) : (
        <>
          <HeaderItem flex={1.6}>Part Number</HeaderItem>
          {user?.dealerType === dealerTypes.excavator ? (
            <>
              <HeaderItem flex={0.9}>kty</HeaderItem>
              <HeaderItem flex={0.9}>jpn</HeaderItem>
            </>
          ) : (
            <>
              <HeaderItem flex={0.6}>kty</HeaderItem>
              <HeaderItem flex={0.6}>jpn</HeaderItem>
              <HeaderItem flex={0.6}>sg</HeaderItem>
            </>
          )}
          <HeaderItem flex={1.6} align="right">
            List Price /
            <br />
            Dealer Net
          </HeaderItem>
        </>
      )}
    </HeaderContainer>
  );

  const AddToCartActionBar = useActionBar(
    <ActionBar
      primaryAction={{
        label:
          (formStatus === 'add' && 'Add to Cart') ||
          (formStatus === 'update' && 'Update Cart') ||
          'Add to Cart',
        disabled: formStatus === 'none',
        onClick: () => addToCart(selectedParts),
      }}
    />,
    isOpen && !viewOnly,
    true,
  );

  return (
    <SearchResultsContainer>
      {renderHeaderContainer()}
      {allParts && allParts.length
        ? allParts.map(renderPart)
        : !loading && Empty}
      {loading ? <Loader /> : null}
      {!viewOnly ? (
        <AddToCartContainer>
          <AddToCart
            type="button"
            disabled={formStatus === 'none'}
            onClick={() => addToCart(selectedParts)}
          >
            {(formStatus === 'add' && 'Add to Cart') ||
              (formStatus === 'update' && 'Update Cart') ||
              'Add to Cart'}
          </AddToCart>
        </AddToCartContainer>
      ) : (
        <EmptyContainer />
      )}
      {AddToCartActionBar}
    </SearchResultsContainer>
  );
};

export default SearchResultsDropDown;
