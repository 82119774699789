import Modal from 'react-modal';
import styled from 'styled-components';
import React, { FunctionComponent, useState, useEffect } from 'react';

import ToRGB from '../../utils/color';
import theme from '../../constants/theme';

Modal.setAppElement('#root');

const { r, g, b } = ToRGB(theme.colors.blueKobelco);
const { r: rd, g: gd, b: bd } = ToRGB(theme.colors.blueDark);

const customStyles: Modal.Styles = {
  content: {
    top: undefined,
    left: undefined,
    right: undefined,
    bottom: undefined,
    margin: '16px',
    width: '100%',
    border: 'none',
    borderRadius: 0,
    textAlign: 'center',
    boxSizing: 'border-box',
    maxWidth: 'calc(423px + 7.2rem)',
    padding: '4rem 3.6rem 3.2rem 3.6rem',
    boxShadow: `0px 4px 24px rgba(${rd}, ${gd}, ${bd}, 0.5)`,
  },
  overlay: {
    zIndex: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `rgba(${r}, ${g}, ${b}, 0.8)`,
  },
};

const ModalTitle = styled.h1`
  font-size: 1.7rem;
  font-weight: normal;
  margin: 0 0 0.8rem 0;
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const ModalSubtitle = styled.h2`
  margin: 0;
  font-size: 1.2rem;
  font-weight: normal;
  color: ${({ theme: { colors } }) => colors.grayDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const OrderLabel = styled.div`
  width: 100%;
  padding: 1.6rem;
  max-width: 331px;
  margin: 2rem auto 2.4rem auto;
  background-color: ${({ theme: { colors } }) => colors.grayLight};
`;

const OrderLabelTitle = styled.h3`
  font-weight: 300;
  font-size: 1.4rem;
  margin: 0 0 0.4rem 0;
  color: ${({ theme: { colors } }) => colors.grayDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const OrderLabelNumber = styled.h4`
  margin: 0;
  font-weight: 600;
  font-size: 1.8rem;
  color: ${({ theme: { colors } }) => colors.black};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const ContinueButton = styled.button`
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 1.2rem 3.8rem;
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { typography } }) => typography.museo};
  background-color: ${({ theme: { colors } }) => colors.blueKobelco};
`;

interface OrderSuccessModalProps {
  isOpen?: boolean;
  orderNumber: string;
  onClose?: () => void;
  onContinue?: () => void;
  afterOpenModal?: () => void;
}

const OrderSuccessModal: FunctionComponent<OrderSuccessModalProps> = ({
  orderNumber,
  isOpen = false,
  onClose = () => {},
  onContinue = () => {},
  afterOpenModal = () => {},
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(isOpen);
  useEffect(() => {
    setModalIsOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal
      contentLabel="Success"
      isOpen={modalIsOpen}
      style={customStyles}
      onRequestClose={onClose}
      onAfterOpen={afterOpenModal}
    >
      <ModalTitle>Your order has been submitted successfully</ModalTitle>
      <ModalSubtitle>
        We will send you an invoice once your order has been shipped
      </ModalSubtitle>
      <OrderLabel>
        <OrderLabelTitle>Your Sales Order number is</OrderLabelTitle>
        <OrderLabelNumber>{orderNumber}</OrderLabelNumber>
      </OrderLabel>
      <ContinueButton type="button" onClick={onContinue}>
        Continue
      </ContinueButton>
    </Modal>
  );
};

export default OrderSuccessModal;
