import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useFormik, FormikValues, FormikConfig } from 'formik';

import Line from '../components/Line';
import InvoiceList from '../components/InvoiceList';
import UninvoicedList from '../components/UninvoicedList';
import CustomerDetails from '../components/CustomerDetails';
import NewOrderHeader from '../components/Orders/NewOrderHeader';
import noop from '../utils/noop';
import { useOrderDetailsQuery } from '../generated/graphql';
import Spinner from '../components/Spinner';
import theme from '../constants/theme';

interface Props {
  order: any | null;
}

const Container = styled.div`
  margin-top: 1rem;
  background-color: ${({ theme: { colors } }) => colors.white};
  padding: 2rem;
  ${up('mobile')} {
    margin-top: 0;
    background-color: transparent;
    padding: 0;
  }
`;

const LoaderContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
`;

function useOrderDetailsToInvoicesTransformer(parts: any[] = []) {
  return useMemo(() => {
    // Group Invoices
    // and combine into invoice
    // with parts array
    const mappedInvoices = parts.reduce((accumulator: any, invoice: any) => {
      const { invoiceNumber } = invoice;

      if (!invoice.invoiceNumber) {
        return accumulator;
      }

      if (!accumulator[invoiceNumber]) {
        accumulator[invoiceNumber] = {
          ...invoice,
          // component expects parts list
          // each item is an invoice
          // and a part. They are grouped
          // by invoice number
          parts: [invoice],
          // component expects total for total invoice price
          total: invoice.invoiceAmount,
        };
      } else {
        accumulator[invoiceNumber].parts.push(invoice);
        accumulator[invoiceNumber].total += invoice.invoiceAmount;
      }

      return accumulator;
    }, {});

    return Object.values(mappedInvoices);
  }, [parts]);
}

const OpenOrderForm: FunctionComponent<Props> = ({ order }) => {
  const { loading, data } = useOrderDetailsQuery({
    variables: { orderNumber: order?.number },
    fetchPolicy: 'network-only',
    skip: order?.number === undefined,
  });

  const formikDefault: FormikConfig<FormikValues> = {
    initialValues: {
      ...order,
      ...(data?.orderDetails || {}),
    },
    onSubmit: noop,
  };

  const formik = useFormik(formikDefault);

  useEffect(() => {
    if (data?.orderDetails) {
      formik.setValues({
        ...formik.values,
        ...data.orderDetails,
        city: data.orderDetails?.consigneeCity,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const invoicedParts = useOrderDetailsToInvoicesTransformer(
    formik.values?.parts || [],
  );

  const uninvoicedParts = useMemo(() => {
    return (formik.values?.parts || []).filter(
      (item: any) => !item.invoiceNumber,
    );
  }, [formik.values]);

  if (!order) {
    return null;
  }

  return formik.values && !loading ? (
    <Container>
      <NewOrderHeader order={formik.values} parts={formik.values.parts} />
      <CustomerDetails formController={formik} disabled />
      <Line />
      {invoicedParts.length !== 0 && <InvoiceList order={invoicedParts} />}
      <UninvoicedList items={uninvoicedParts} />
    </Container>
  ) : (
    <LoaderContainer>
      <Spinner
        size="medium"
        style={{ zIndex: 20 }}
        color={theme.colors.blueKobelco}
      />
    </LoaderContainer>
  );
};

export default OpenOrderForm;
