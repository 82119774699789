import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

import './index.css';
import { ReactComponent as chevron } from '../../assets/images/chevron-outline.svg';

const StyledChevron = styled(chevron)<{ disabled?: boolean }>`
  stroke: ${({ theme: { colors }, disabled }) =>
    disabled ? colors.gray : colors.blueDark} !important;
  width: 15px;
  height: 15px;
`;

const ChevronLeft = styled(StyledChevron)`
  transform: rotate(90deg);
`;

const ChevronRight = styled(StyledChevron)`
  transform: rotate(-90deg);
`;

interface Props {
  page: number;
  pageCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
}

const Pagination: React.FC<Props> = ({ page, pageCount, onPageChange }) => {
  return (
    <ReactPaginate
      activeClassName="react-pagination__active react-pagination__item"
      breakClassName="react-pagination__item"
      containerClassName="react-pagination__pagination"
      disabledClassName="react-pagination__disabled-page"
      nextClassName="react-pagination__item react-pagination__next"
      pageClassName="react-pagination__item react-pagination__pagination-page"
      previousClassName="react-pagination__item react-pagination__previous"
      previousLabel={<ChevronLeft disabled={page === 1} />}
      nextLabel={<ChevronRight disabled={page === pageCount} />}
      breakLabel="..."
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={onPageChange}
    />
  );
};

export default Pagination;
