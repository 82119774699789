import { useEffect } from 'react';
import get from 'lodash/get';
import useWindowSize from './useWindowSize';
import theme from '../constants/theme';

function useIsMobile() {
  let isMobile = true;
  const size = useWindowSize();
  const mobileBreakpoint = parseInt(
    get(theme, 'breakpoints.mobile', '').replace('px', ''),
    10,
  );
  isMobile = Boolean(
    !!size && size && size.width && size.width <= mobileBreakpoint,
  );
  useEffect(() => {
    isMobile = Boolean(
      !!size && size && size.width && size.width <= mobileBreakpoint,
    );
  }, [size]);
  return isMobile;
}

export default useIsMobile;
