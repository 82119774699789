/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/destructuring-assignment */
import React, { FunctionComponent, CSSProperties } from 'react';
import Select, { components, ValueType, ActionMeta } from 'react-select';

import ToRGB from '../../utils/color';
import constants, { Option } from '../../constants';

const { theme } = constants;
const { r, g, b } = ToRGB(theme.colors.blueDark);
const boxShadow = `0px 4px 8px rgba(${r}, ${g}, ${b}, 0.15), 0px 0px 2px rgba(${r}, ${g}, ${b}, 0.04)`;

const { ValueContainer } = components;
const CustomValueContainer: FunctionComponent<any> = ({
  children,
  ...props
}) => (
  <ValueContainer {...props}>
    {React.Children.map(children, child => (child ? child : null))}
  </ValueContainer>
);

interface SimplerDropDownProps {
  id?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  noOptionsMessage?: string;
  onChange?: (option: Option) => void;
  options: Option[];
  required?: boolean;
  value?: string;
}

const SimplerDropDown: FunctionComponent<SimplerDropDownProps> = ({
  id,
  disabled,
  errorMessage,
  noOptionsMessage,
  onChange,
  options,
  required,
  value,
}) => {
  const inputStyle = (base: CSSProperties, state: any) => ({
    ...base,
    fontSize: '1rem',
    color: theme.colors.blueDark,
    fontFamily: theme.typography.museo,
    minWidth: '6.5rem',
    padding: 0,
  });

  const menuStyle = (base: CSSProperties, state: any) => ({
    ...base,
    boxShadow,
    margin: 0,
    padding: 0,
    zIndex: 10,
    borderRadius: 0,
  });

  const menuListStyle = (base: CSSProperties, state: any) => ({
    ...base,
    padding: 0,
  });

  const { r: rk, g: gk, b: bk } = ToRGB(theme.colors.blueKobelco);
  const optionBackgroundColor = `rgba(${rk}, ${gk}, ${bk}, 0.1)`;
  const optionStyle = (base: CSSProperties, state: any) => ({
    ...base,
    fontSize: '0.8rem',
    padding: '0.8rem 0.5rem',
    color: theme.colors.blueDark,
    fontFamily: theme.typography.museo,
    '&:first-of-type': { paddingTop: '1rem' },
    '&:last-child': { paddingBottom: '1rem' },
    '&:hover': { backgroundColor: optionBackgroundColor },
    '&:active': { backgroundColor: optionBackgroundColor },
    backgroundColor: 'transparent',
  });

  const controlStyle = (base: CSSProperties, state: any) => {
    const backgroundColor = 'transparent';

    return {
      ...base,
      borderRadius: 3,
      minHeight: 0,
      boxShadow: state.selectProps.menuIsOpen ? boxShadow : undefined,
      border: `1px solid ${theme.colors.gray}`,
      borderBottom: state.selectProps.menuIsOpen
        ? `0.1rem solid ${theme.colors.blueLight}`
        : undefined,
      backgroundColor: backgroundColor,
      '&:hover': {
        borderColor:
          required && !!errorMessage
            ? `2px solid ${theme.colors.red}`
            : theme.colors.blueLight,
      },
    };
  };

  const dropdownIndicatorStyle = (base: CSSProperties, state: any) => {
    if (disabled) {
      return { display: 'none' };
    }

    return {
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: '3px',
      paddingRight: '3px',
      transition: 'transform 0.12s ease-in-out',
      '&:hover': { color: theme.colors.blueDark },
      transform: state.selectProps.menuIsOpen
        ? 'rotate(180deg)'
        : 'rotate(0deg)',
      color: state.selectProps.menuIsOpen
        ? theme.colors.blueDark
        : theme.colors.grayADA,
    };
  };

  const valueContainerStyle = (base: CSSProperties, state: any) => ({
    ...base,
    paddingLeft: '3px',
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  });

  const noOptionsMessageStyle = (base: CSSProperties) => ({
    ...base,
    fontFamily: theme.typography.museo,
  });

  const handleSelectOption = (
    option: ValueType<Option>,
    actionMeta: ActionMeta,
  ) => {
    if (option && onChange) {
      const { value: newValue, label: newLabel }: any = option;
      onChange({ value: newValue, label: newLabel });
    }
  };

  return (
    <Select
      id={id}
      noOptionsMessage={() => noOptionsMessage || 'No Options'}
      options={options}
      isDisabled={disabled}
      onChange={handleSelectOption}
      value={options.find(option => option.value === value) || null}
      components={{
        IndicatorSeparator: null,
        ValueContainer: CustomValueContainer,
      }}
      styles={{
        control: controlStyle,
        dropdownIndicator: dropdownIndicatorStyle,
        input: inputStyle,
        menu: menuStyle,
        menuList: menuListStyle,
        option: optionStyle,
        singleValue: inputStyle,
        valueContainer: valueContainerStyle,
        noOptionsMessage: noOptionsMessageStyle,
      }}
    />
  );
};

export default SimplerDropDown;
