import styled from 'styled-components';
import React, { FunctionComponent, useState } from 'react';

import PartRow, { Row } from '../PartRow';

import { ReactComponent as Chevron } from '../../assets/images/chevron.svg';

import constants from '../../constants';
import useWindowSize from '../../hooks/useWindowSize';
import { PartsContainer, PartColumnTitle } from '../InvoiceItem/styled';

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.6rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.grayLight};
`;

const UninvoicedTitle = styled.span`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.typography.museo};
`;

const UninvoicedAction = styled.span`
  cursor: pointer;
  font-size: 1.3rem;
  user-select: none;
  color: ${({ theme }) => theme.colors.blueDark};
  font-family: ${({ theme }) => theme.typography.museo};
`;

const ChevronIcon = styled(({ up, ...rest }) => <Chevron {...rest} />)<{
  up?: boolean;
}>`
  margin-left: 0.8rem;
  transition: transform 0.12s ease-in-out;
  color: ${({ theme }) => theme.colors.blueDark};
  transform: ${({ up }) => (up ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

interface UninvoicedListProps {
  items: any[];
}

const UninvoicedList: FunctionComponent<UninvoicedListProps> = ({ items }) => {
  const [isHidden, setHidden] = useState<boolean>(false);

  const { width } = useWindowSize();
  const mobileBreakpoint = Number(
    constants.theme.breakpoints.mobile.replace('px', ''),
  );

  const renderPartsRows = () =>
    items.map((part: any) => (
      <PartRow
        key={`uninvoiced-${part.number}`}
        alternateFor={part.alternateFor}
        number={part.number}
        part={part}
        quantity={part.quantity}
        unitCost={part.price}
      />
    ));

  return (
    <div className="uninvoiced-items">
      <TitleRow>
        <UninvoicedTitle>Uninvoiced Items</UninvoicedTitle>
        <UninvoicedAction onClick={() => setHidden(!isHidden)}>
          {isHidden ? 'Show All' : 'Hide All'} <ChevronIcon up={!isHidden} />
        </UninvoicedAction>
      </TitleRow>
      {!isHidden && (
        <PartsContainer>
          {width && width >= mobileBreakpoint && (
            <Row verPadding={0}>
              <PartColumnTitle flex={1.5}>Part No.</PartColumnTitle>
              <PartColumnTitle flex={0.875}>Unit Cost</PartColumnTitle>
              <PartColumnTitle flex={0.875}>QTY</PartColumnTitle>
              <PartColumnTitle flex={0.875}>Total</PartColumnTitle>
              <PartColumnTitle center flex={0.875}>
                Favorite
              </PartColumnTitle>
            </Row>
          )}
          {renderPartsRows()}
        </PartsContainer>
      )}
    </div>
  );
};

export default UninvoicedList;
