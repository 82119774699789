/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import styled from 'styled-components';
import React, { useState, FunctionComponent } from 'react';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';

import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { ReactComponent as ChevronIcon } from '../../assets/images/chevron.svg';

import { TextButton } from '../styled/buttons';

const activeClassName = 'active';

const TabLinks = styled(NavLink)`
  font-size: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 1.8rem;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &.${activeClassName} {
    color: ${({ theme: { colors } }) => colors.blueDark};
    background-color: ${({ theme: { colors } }) => colors.grayLight};
    svg line {
      stroke: ${({ theme: { colors } }) => colors.blueDark};
    }
  }
`;

const PlusSignTabLink = styled(TabLinks)`
  width: 5rem;
  height: 4.2rem;
`;

const DropDownTabLinks = styled(TabLinks)`
  cursor: pointer;
  user-select: none;
  color: ${({ theme: { colors } }) => colors.blueDark};
  background-color: ${({ theme: { colors } }) => colors.white};
  text-align: center;
  padding-bottom: 2rem;
  :first-child {
    padding-top: 2rem;
  }
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
  &.${activeClassName} {
    background-color: ${({ theme: { colors } }) => colors.white};
  }
`;
const TabLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface ChevronWDirectionProps {
  showItems: boolean;
}

const ChevronWDirection = styled(({ showItems, ...rest }) => (
  <ChevronIcon {...rest} />
))<ChevronWDirectionProps>`
  margin-left: 0.8rem;
  transition: transform 0.12s ease-in-out;
  transform: ${({ showItems }) => (showItems ? 'rotate(180deg)' : 'rotate(0)')};
`;

const ChevronLeft = styled(ChevronIcon)`
  transform: rotate(90deg);
  margin-left: 0.8rem;
  margin-right: 0.5rem;
`;

const PlusIconStyled = styled(PlusIcon)`
  width: 1.2rem;
  height: 1.2rem;
  &.${activeClassName} {
    color: ${({ theme: { colors } }) => colors.blueDark};
    background-color: ${({ theme: { colors } }) => colors.grayLight};
    svg line {
      stroke: ${({ theme: { colors } }) => colors.blueDark};
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 4.2rem;
`;

const CurrentTabContainer = styled.div`
  flex: 1;
  cursor: pointer;
  user-select: none;
  font-size: 1.3rem;
  font-family: ${({ theme: { typography } }) => typography.museo};
  line-height: 1.8rem;
  color: ${({ theme: { colors } }) => colors.blueDark};
  background-color: ${({ theme: { colors } }) => colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.span`
  color: ${({ theme: { colors } }) => colors.blueDark};
`;

interface DropdownMenuProps {
  showItems?: boolean;
}

const MenuContainer = styled.div<DropdownMenuProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 17;
  transition: all 0.2s;
  top: 4.2rem;
  left: 0;
  right: 0;
  width: 100%;
  display: ${({ showItems }) => (!showItems ? 'none' : undefined)};
  background-color: ${({ theme: { colors } }) => colors.white};
  border-top: ${({ theme: { colors } }) => `0.1rem solid ${colors.grayLight}`};
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
`;

interface DropdownPros {
  items: any;
  currentPath: any;
}

const Dropdown: FunctionComponent<DropdownPros> = ({
  items = [],
  currentPath,
}) => {
  const [showItems, setShowItems] = useState(false);
  const [_selectedItem, setSelectedItem] = useState(items[0]); // eslint-disable-line @typescript-eslint/no-unused-vars

  const selectItem = (item: any) => {
    setSelectedItem(item);
    setShowItems(false);
  };

  const currentItem = items.find((item: any) => item.to === currentPath);
  const availableItems = items.filter((item: any) => item.to !== currentPath);

  return (
    <TabLinksContainer id="asdfdsa">
      <CurrentTabContainer onClick={() => setShowItems(!showItems)}>
        {currentItem.value}
        <ChevronWDirection showItems={showItems} />
      </CurrentTabContainer>
      <MenuContainer showItems={showItems}>
        {availableItems.map((item: any) => (
          <DropDownTabLinks
            to={item.to}
            key={item.to}
            onClick={() => selectItem(item)}
          >
            {item.value}
          </DropDownTabLinks>
        ))}
      </MenuContainer>
    </TabLinksContainer>
  );
};

interface ContainerProps {
  isSubpage?: boolean;
}

const TabsMenuMobileContainer = styled.div<ContainerProps>`
  display: flex;
  justify-content: ${({ isSubpage }) =>
    isSubpage ? 'flex-start' : 'space-between'};
  position: relative;
  align-items: center;
  min-height: 4.2rem;
  font-size: 1.3rem;
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.blueDark};
`;

interface TabsMenuMobileProps extends RouteComponentProps {
  onClickBack?: any;
  isSubpage?: boolean;
  title?: string | null;
}

const TabsMenuMobile: FunctionComponent<TabsMenuMobileProps> = ({
  title = null,
  isSubpage = false,
  location: { pathname },
  onClickBack = () => {},
}) => (
  <TabsMenuMobileContainer isSubpage={isSubpage}>
    {!isSubpage ? (
      <>
        <PlusSignTabLink to="/new-order">
          <PlusIconStyled />
        </PlusSignTabLink>
        <Dropdown
          currentPath={pathname}
          items={[
            { to: '/new-order', value: 'New Order' },
            { to: '/saved-carts', value: 'Saved Carts' },
            { to: '/open-orders', value: 'Open Orders' },
            { to: '/all-orders', value: 'All Orders' },
          ]}
        />
        <IconContainer />
      </>
    ) : (
      <TextButton onClick={onClickBack}>
        <ChevronLeft />
        <Title>{title}</Title>
      </TextButton>
    )}
  </TabsMenuMobileContainer>
);

export default withRouter(TabsMenuMobile);
