export function mergeParts<T>(parts?: T[] | null) {
  if (!parts) {
    return [];
  }

  const partsToSave = new Map<string, any>();

  (parts as any[]).forEach((part: any) => {
    if (partsToSave.has(part?.number || '')) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const prevPart = partsToSave.get(part.number!)!;

      partsToSave.set(part.number, {
        ...prevPart,
        quantity: (prevPart.quantity || 0) + (part.qty || part.quantity || 0),
      });

      return;
    }

    partsToSave.set(part?.number, {
      number: part.number,
      inputNumber: part.inputNumber,
      quantity: part.qty || part.quantity || 0,
    });
  });

  const mergedPartsArray: Array<T> = [];

  partsToSave.forEach(part => mergedPartsArray.push(part));

  return mergedPartsArray;
}
