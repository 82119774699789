const dealerWhitelistStates: {
  [key: string]: { stock: string[]; emergency?: string[] };
} = {
  G2668A: { stock: ['AK'] },
  G3273A: { stock: ['OH', 'WV', 'PA'] },
  G3274A: { stock: ['OH', 'WV', 'PA'] },
  G3275A: { stock: ['OH', 'WV', 'PA'] },
  G3276A: { stock: ['OH', 'WV', 'PA'] },
  G3277A: { stock: ['OH', 'WV', 'PA'] },
  G2676A: { stock: ['TX'] },
  G2670A: { stock: ['TX'] },
  G2672A: { stock: ['TX'] },
  G2669A: { stock: ['TX'] },
  G2673A: { stock: ['TX'] },
  G2671A: { stock: ['TX'] },
  G2677A: { stock: ['NM'] },
  G2678A: { stock: ['TX'] },
  G2688A: { stock: ['TX'] },
  G2695A: { stock: ['GA', 'AL', 'FL'] },
  G3285A: { stock: ['GA', 'AL', 'FL'] },
  G3286A: { stock: ['GA', 'AL', 'FL'] },
  G3287A: { stock: ['GA', 'AL', 'FL'] },
  G3288A: { stock: ['GA', 'AL', 'FL'] },
  G3289A: { stock: ['GA', 'AL', 'FL'] },
  G3290A: { stock: ['GA', 'AL', 'FL'] },
  G2696A: { stock: ['PA'] },
  G3326A: { stock: ['NE'] },
  G2699A: { stock: ['NV'] },
  G2704A: { stock: ['MI'] },
  G3293A: { stock: ['TX', 'WA', 'CA'] },
  G3416A: { stock: ['TX', 'WA', 'CA', 'CO'], emergency: ['CO', 'UT'] },
  G3294A: { stock: ['TX', 'WA', 'CA', 'CO'], emergency: ['CO', 'UT'] },
  G2689A: { stock: ['TX', 'WA', 'CA', 'CO'], emergency: ['CO', 'UT'] },
  G2706A: { stock: ['TX'] },
  G2709A: { stock: ['SC', 'NC'] },
  G2713A: { stock: ['TX'] },
  G3295A: { stock: ['OH'] },
  G3399A: { stock: ['NE', 'VA'] },
  G2719A: { stock: ['NE', 'VA'] },
  G3400A: { stock: ['NE', 'VA'] },
  G2721A: { stock: ['NE', 'VA'] },
  G2720A: { stock: ['NE', 'VA'] },
  G2737A: { stock: ['TX'] },
  G2738A: { stock: ['WA'] },
  G2745A: { stock: ['MD'] },
  G2747A: { stock: ['TX'] },
  G3297A: { stock: ['FL'] },
  G2750A: { stock: ['FL'] },
  G2752A: { stock: ['CA'] },
  G2754A: { stock: ['SP'] },
  G2759A: { stock: ['TX'] },
  G2766A: { stock: ['IA', 'WI', 'ND', 'MN', 'IL'] },
  G3305A: { stock: ['IA', 'WI', 'ND', 'MN', 'IL'] },
  G3306A: { stock: ['LA', 'AR', 'MS'] },
  G3307A: { stock: ['LA', 'AR', 'MS'] },
  G3308A: { stock: ['LA', 'AR', 'MS'] },
  G3309A: { stock: ['LA', 'AR', 'MS'] },
  G3310A: { stock: ['LA', 'AR', 'MS'] },
  G3311A: { stock: ['LA', 'AR', 'MS'] },
  G3312A: { stock: ['LA', 'AR', 'MS'] },
  G3313A: { stock: ['LA', 'AR', 'MS'] },
  G3314A: { stock: ['LA', 'AR', 'MS'] },
  G3315A: { stock: ['LA', 'AR', 'MS'] },
  G3316A: { stock: ['LA', 'AR', 'MS'] },
  G2768A: { stock: ['LA', 'AR', 'MS'] },
  G2770A: { stock: ['FL'] },
  G3317A: { stock: ['FL'] },
  G3318A: { stock: ['FL'] },
  G3319A: { stock: ['FL'] },
  G3320A: { stock: ['FL'] },
  G2771A: { stock: ['FL'] },
  G2772A: { stock: ['AZ'] },
  G2776A: { stock: ['KY'] },
  G2777A: { stock: ['AK'] },
  G2701A: { stock: ['NY', 'NJ'] },
  G3321A: { stock: ['NY', 'NJ'] },
  G2782A: { stock: ['GA'] },
  G2783A: { stock: ['FL'] },
  G2789A: { stock: ['CO'] },
  G2788A: { stock: ['CO'] },
  G3322A: { stock: ['CO'] },
  G3323A: { stock: ['CO'] },
  G2792A: { stock: ['MD'] },
  G2791A: { stock: ['AK'] },
  G2793A: { stock: ['IN'] },
  G2797A: { stock: ['TX'] },
  G4963A: { stock: ['FL'] },
  G4649A: { stock: ['TN'] },
  G4529A: { stock: ['OK', 'TX'] },
  G4954A: { stock: ['OK', 'TX'] },
  G2697A: { stock: ['ON', 'QC', 'AB', 'SK'] },
  G3291A: { stock: ['ON', 'QC', 'AB', 'SK'] },
  G3292A: { stock: ['ON', 'QC', 'AB', 'SK'] },
  G3303A: { stock: ['ON', 'QC', 'AB', 'SK'] },
  G3304A: { stock: ['ON', 'QC', 'AB', 'SK'] },
  G2761A: { stock: ['ON', 'QC', 'AB', 'SK'] },
};

export default dealerWhitelistStates;
