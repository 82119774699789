/* eslint-disable @typescript-eslint/indent */
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import intersectionBy from 'lodash/intersectionBy';
import { up } from 'styled-breakpoints';
import PartItem from '../PartItem';
import Spacer from '../Spacer';
import SearchPart from '../Search';
import Collapsible from '../Collapsible';
import PartsHeader from '../PartsHeader';
import NewOrderHeader from './NewOrderHeader';
import IncompleteOrderPlaceholder from './IncompleteOrderPlaceholder';
import { useCurrentUserQuery } from '../../generated/graphql';
import useEventListener from '../../hooks/useEventListener';
import PartEvent from '../../utils/partEvent';
import CustomerDetails from '../CustomerDetails';
import theme from '../../constants/theme';
import formatter from '../../utils/formatters';
import useWindowSize from '../../hooks/useWindowSize';
import Spinner from '../Spinner';
import useUser from '../../hooks/useUser';
import { dealerTypes } from '../../constants';

const Line = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1.6rem;
  margin-bottom: 0.7rem;
  background-color: ${({ theme: { colors } }) => colors.grayLight};
`;

const Column = styled.div`
  height: 100%;
  padding: 2rem;
  ${up('mobile')} {
    padding: 0;
  }
`;

const PartsTableTitle = styled.span`
  font-size: 1rem;
  line-height: normal;
  margin-bottom: 1.2rem;
  color: ${({ theme: { colors } }) => colors.blueNeutral};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const PartsTableTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  background-color: ${({ theme: { colors } }) => colors.whiteSmoke};
`;

const PartsTableColumnTitle = styled.span<{
  flex: number;
  align: 'left' | 'right';
}>`
  font-size: 0.8rem;
  line-height: normal;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  flex: ${({ flex }) => flex};
  text-align: ${({ align }) => align};
  color: ${({ theme: { colors } }) => colors.grayADA};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const PartsTableContentRowWrapper = styled.div`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-bottom: 0.2rem solid
    ${({ theme: { colors } }) => colors.blueUltraLight};
`;

const PartsTableContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.2rem;
`;

const PartsTableColumnContent = styled.span<{
  flex: number;
  gray?: boolean;
  align: 'left' | 'right';
}>`
  font-size: 1rem;
  line-height: normal;
  flex: ${({ flex }) => flex};
  text-align: ${({ align }) => align};
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors }, gray }) =>
    gray ? colors.grayADA : colors.black};
`;

const LoaderContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
`;

interface NewOrderFormProps {
  formik: any;
  isCustomerDataCollapsed: boolean;
  isSavedOrders?: boolean;
  onCloseSearch?: () => void;
  onOpenSearch?: () => void;
  reviewOrder?: boolean;
  savedOrder?: string | null;
  send?: (a: any) => any;
  setAddedParts: (val: boolean) => void;
  setCustomerDataCollapsed: () => void;
  state: any;
  stockItems?: any[];
  loading?: boolean;
}

const NewOrders: FunctionComponent<NewOrderFormProps> = ({
  state,
  formik,
  setAddedParts,
  stockItems = [],
  savedOrder = null,
  reviewOrder = false,
  isSavedOrders = false,
  isCustomerDataCollapsed,
  setCustomerDataCollapsed,
  onOpenSearch = () => undefined,
  onCloseSearch = () => undefined,
  send = a => a,
  loading = false,
}) => {
  const { data: currentUser } = useCurrentUserQuery();
  const favorites = get(currentUser, 'currentUser.favorites', []);
  const user = useUser();

  const [defaultSearch, setDefaultSearch] = useState('');
  const [editingParts, setEditingParts] = useState<Array<any>>([]);

  useEventListener(
    'AddedToOrder',
    (event: PartEvent) => {
      const partIndex = formik.values.parts.findIndex(
        (part: { number: any; inputNumber: any }) =>
          part.number === event.part.number &&
          part.inputNumber === event.part.inputNumber,
      );
      formik.setValues({
        ...formik.values,
        parts:
          partIndex > -1
            ? [
                ...formik.values.parts.slice(0, partIndex),
                {
                  ...formik.values.parts[partIndex],
                  qty: formik.values.parts[partIndex].qty + 1,
                },
                ...formik.values.parts.slice(partIndex + 1),
              ]
            : [...formik.values.parts, { ...event.part, qty: 1 }],
      });
      if (state.matches('inactive')) {
        send('START');
        send('CONTINUE');
      } else if (state.matches('customerDetails')) {
        send('CONTINUE');
      }
    },
    formik,
  );

  const handleAddToCart = (parts: Array<any>) => {
    const repeatedPartNumbers = intersectionBy(
      [...formik.values.parts, ...parts],
      'number',
    ).map(part => part.number);

    const formerParts = formik.values.parts.filter(
      (part: any) => !repeatedPartNumbers.includes(part.number),
    );

    setEditingParts([]);

    formik.setValues({
      ...formik.values,
      parts: [...formerParts, ...parts].filter(part => part.qty > 0),
    });
  };

  const { width } = useWindowSize();
  const mobileBreakpoint = Number(theme.breakpoints.mobile.replace('px', ''));

  const renderParts =
    state.value === 'editingAll' ||
    state.value === 'stockHasChanged' ||
    state.value === 'shipping' ||
    state.value === 'saving' ||
    state.value === 'submitting' ||
    state.matches({ inventory: 'loading' }) ||
    state.matches({ inventory: 'stock' });

  const renderPartsHeader = () =>
    formik.values.parts.length &&
    width &&
    width >= mobileBreakpoint &&
    renderParts ? (
      <PartsHeader />
    ) : null;

  const renderInStockParts = () => (
    <div>
      <PartsTableTitle>In Stock Parts</PartsTableTitle>
      <PartsTableTitleRow>
        <PartsTableColumnTitle flex={1.5} align="left">
          Part No
        </PartsTableColumnTitle>
        <PartsTableColumnTitle flex={0.75} align="right">
          QTY
        </PartsTableColumnTitle>
        <PartsTableColumnTitle flex={0.75} align="right">
          Total
        </PartsTableColumnTitle>
      </PartsTableTitleRow>
      {formik.values.parts.map((part: any) => {
        const stock = part.ATPstockSC;
        const toOrder = stock < part.qty ? stock : part.qty;

        const price =
          user?.dealerType === dealerTypes.excavator
            ? part.dealerPrice || part.price
            : part.price;

        if (stock !== 0) {
          return (
            <PartsTableContentRowWrapper key={part.number}>
              <PartsTableContentRow>
                <PartsTableColumnContent flex={1.5} align="left">
                  {part.number}
                </PartsTableColumnContent>
                <PartsTableColumnContent flex={0.75} align="right">
                  {toOrder}
                </PartsTableColumnContent>
                <PartsTableColumnContent flex={0.75} align="right">
                  {formatter.currency(price * toOrder)}
                </PartsTableColumnContent>
              </PartsTableContentRow>
              <PartsTableContentRow>
                <PartsTableColumnContent gray flex={1} align="left">
                  {formatter.currency(price)}/unit
                </PartsTableColumnContent>
              </PartsTableContentRow>
            </PartsTableContentRowWrapper>
          );
        }
      })}
    </div>
  );

  const renderBackOrderParts = () => {
    if (
      formik.values.parts.filter((part: any) => part.ATPstockSC < part.qty)
        .length !== 0
    ) {
      return (
        <div style={{ marginTop: '3.2rem' }}>
          <PartsTableTitle>Back Order Parts</PartsTableTitle>
          <PartsTableTitleRow>
            <PartsTableColumnTitle flex={1.5} align="left">
              Part No
            </PartsTableColumnTitle>
            <PartsTableColumnTitle flex={0.75} align="right">
              QTY
            </PartsTableColumnTitle>
            <PartsTableColumnTitle flex={0.75} align="right">
              Total
            </PartsTableColumnTitle>
          </PartsTableTitleRow>
          {formik.values.parts.map((part: any) => {
            const stock = part.ATPstockSC;
            const toBackOrder = stock < part.qty ? part.qty - stock : 0;

            const price =
              user?.dealerType === dealerTypes.excavator
                ? part.dealerPrice || part.price
                : part.price;
            return (
              toBackOrder !== 0 && (
                <PartsTableContentRowWrapper>
                  <PartsTableContentRow>
                    <PartsTableColumnContent flex={1.5} align="left">
                      {part.number}
                    </PartsTableColumnContent>
                    <PartsTableColumnContent flex={0.75} align="right">
                      {toBackOrder}
                    </PartsTableColumnContent>
                    <PartsTableColumnContent flex={0.75} align="right">
                      {formatter.currency(price * toBackOrder)}
                    </PartsTableColumnContent>
                  </PartsTableContentRow>
                  <PartsTableContentRow>
                    <PartsTableColumnContent gray flex={1} align="left">
                      {formatter.currency(price)}/unit
                    </PartsTableColumnContent>
                  </PartsTableContentRow>
                </PartsTableContentRowWrapper>
              )
            );
          })}
        </div>
      );
    }

    return null;
  };

  if (isSavedOrders && !savedOrder) {
    return null;
  }

  if (loading) {
    return (
      <Column>
        <LoaderContainer>
          <Spinner
            size="medium"
            style={{ zIndex: 20 }}
            color={theme.colors.blueKobelco}
          />
        </LoaderContainer>
      </Column>
    );
  }

  return (
    <Column>
      <NewOrderHeader parts={formik.values.parts} order={null} />
      <Collapsible
        title="Customer Details"
        collapsed={isCustomerDataCollapsed}
        setCollapsed={setCustomerDataCollapsed}
      >
        <CustomerDetails formController={formik} />
      </Collapsible>
      <Line />
      {renderPartsHeader()}
      {reviewOrder && (
        <>
          {renderInStockParts()}
          {renderBackOrderParts()}
        </>
      )}
      {!reviewOrder &&
        renderParts &&
        formik.values.parts.map((part: any) =>
          editingParts.findIndex(
            partNr => partNr === part.number + part.inputNumber,
          ) > -1 ? (
            <SearchPart
              onOpen={onOpenSearch}
              addToCart={handleAddToCart}
              parts={formik.values.parts}
              editingParts={editingParts}
              key={`search-${part.number}-${part.inputNumber}`}
              defaultSearch={defaultSearch}
              clearDefaultSearch={() => setDefaultSearch('')}
              onClose={() => {
                setEditingParts([]);
                onCloseSearch();
              }}
            />
          ) : (
            <PartItem
              part={part}
              qty={part.qty}
              unitCost={part.price}
              partNumber={part.number}
              key={`search-${part.number}-${part.inputNumber}`}
              alternateFor={
                part.number !== part.inputNumber ? part.inputNumber : undefined
              }
              stockHasChanged={
                state === 'stockHasChanged' &&
                stockItems.find(
                  item =>
                    item.number === part.number &&
                    item.inputNumber === part.inputNumber,
                )
              }
              onEdit={() => {
                setEditingParts([
                  ...editingParts,
                  part.number + part.inputNumber,
                ]);
                setDefaultSearch(part.inputNumber || part.number);
              }}
              isFavorite={
                favorites.findIndex(
                  (item: any) =>
                    item.number === part.number &&
                    item.inputNumber === part.inputNumber,
                ) >= 0
              }
            />
          ),
        )}
      <Spacer vertical />
      {!reviewOrder && renderParts ? (
        Array(Math.max(3 - formik.values.parts.length, 1))
          .fill(1)
          .map((_, i) => i + 1)
          .map((_, _i) => (
            <SearchPart
              key={`search-${_}`}
              onOpen={onOpenSearch}
              onClose={onCloseSearch}
              addToCart={handleAddToCart}
              parts={formik.values.parts}
              hasError={!!formik.errors.parts}
            />
          ))
      ) : (
        <IncompleteOrderPlaceholder onClick={() => setAddedParts(true)} />
      )}
    </Column>
  );
};
export default NewOrders;
