import moment from 'moment';
import { getErrorMessage } from './errorMessages';

const numFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default {
  error: (error: Error | string) => getErrorMessage(error),
  currency: (value: number): string => numFormatter.format(value),
  date: (date: any, separator = '-'): string => {
    // eslint-disable-next-line no-useless-escape
    const isEpoch =
      (typeof date === 'number' || typeof date === 'string') &&
      !`${date}`.match(/[-|/]/);

    let momentDate;

    if (isEpoch && date.length !== 8) {
      if (date.length === 13) {
        momentDate = moment.utc(Number(date));
      } else {
        momentDate = moment.unix(Number(date)).utc();
      }
    } else if (typeof date === 'string' && date?.match('-')?.length) {
      momentDate = moment(date, 'MM-DD-YYYY');
    } else if (typeof date === 'string') {
      momentDate = moment.utc(date, 'YYYYMMDD');
    } else {
      momentDate = moment(date);
    }

    return momentDate.format(`MM${separator}DD${separator}YYYY`);
  },
};
