import Rollbar from 'rollbar';
import constants from '../constants';

const rollbarConfig = {
  accessToken: constants.configs.ROLLBAR_TOKEN,
  environment: constants.configs.ENVIRONMENT,
  enabled: constants.configs.ENVIRONMENT !== 'development', //disable for development
};

const rollbar = new Rollbar(rollbarConfig);

export default rollbar;
