import '@webcomponents/webcomponentsjs';
import 'core-js/es/array';
import 'core-js/es/map';
import 'core-js/es/set';
import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill';

// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';

import 'intl';
import 'normalize.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
