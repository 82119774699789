import React, { FunctionComponent, useState } from 'react';

import PartRow, { Row } from '../PartRow';
import InvoiceModal from '../Modals/Invoice';

import formatters from '../../utils/formatters';
import useWindowSize from '../../hooks/useWindowSize';

import constants from '../../constants';
import { InvoicePDFLink } from '../InvoicePDFLink/InvoicePDFLink';
import {
  getAlternativePartNumber,
  getPDFLink,
  getLinkStyling,
  getInvoiceText,
} from './helpers';
import {
  PartsContainer,
  PartColumnTitle,
  ColumnContent,
  MobileContentColumn,
  MobileContentRow,
  MobileColumnTitle,
  OpenIcon,
  MobileColumnContent,
  MobileAction,
  ChevronIcon,
} from './styled';
import { getTrackingCompanyUrl } from '../../utils/trackingUrls';

interface InvoiceItemProps {
  order: any;
  invoice: any;
  invoiceParts: any[];
}

const InvoiceItem: FunctionComponent<InvoiceItemProps> = ({
  order,
  invoice,
  invoiceParts,
}) => {
  const { width } = useWindowSize();
  const mobileBreakpoint = Number(
    constants.theme.breakpoints.mobile.replace('px', ''),
  );

  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);

  const renderInvoiceParts = () => (
    <PartsContainer>
      {width && width >= mobileBreakpoint && (
        <Row verPadding={0}>
          <PartColumnTitle flex={1.5}>Part No.</PartColumnTitle>
          <PartColumnTitle flex={0.875}>Unit Cost</PartColumnTitle>
          <PartColumnTitle flex={0.875}>QTY</PartColumnTitle>
          <PartColumnTitle flex={0.875}>Total</PartColumnTitle>
          <PartColumnTitle center flex={0.875}>
            Favorite
          </PartColumnTitle>
        </Row>
      )}
      {invoiceParts.map((part: any) => (
        <PartRow
          key={part.number}
          alternateFor={getAlternativePartNumber(part)}
          number={part.number}
          part={part}
          quantity={part.quantity}
          totalCost={part.invoiceAmount}
          unitCost={part.price}
        />
      ))}
    </PartsContainer>
  );

  const renderInvoiceTableHead = () => (
    <Row hasBorder verPadding={0.8}>
      {width && width >= mobileBreakpoint ? (
        <>
          <ColumnContent flex={1}>{invoice.invoiceNumber}</ColumnContent>
          <ColumnContent
            as={'a'}
            isBlue
            flex={1}
            alignCenter
            rel="noreferrer"
            target="_blank"
            href={getTrackingCompanyUrl(invoice.trackingNumber).url}
            style={{
              cursor: invoice.trackingNumber ? 'pointer' : 'inherit',
              pointerEvents: invoice.trackingNumber ? 'auto' : 'none',
            }}
          >
            {invoice.trackingNumber || 'N/A'}
            {invoice.trackingNumber && <OpenIcon />}
          </ColumnContent>
          <ColumnContent flex={1}>
            {formatters.date(invoice.issuedAt)}
          </ColumnContent>
          <ColumnContent flex={1}>
            {formatters.currency(invoice.total)}
          </ColumnContent>
          <InvoicePDFLink invoiceNumber={invoice.invoiceNumber}>
            {({ data, loading, error }: any) => {
              return (
                <ColumnContent
                  as={'a'}
                  flex={1}
                  isBlue
                  rel="noreferrer"
                  target="_blank"
                  href={getPDFLink(data)}
                  style={getLinkStyling(data, loading, error)}
                >
                  {getInvoiceText(loading, error, data)}
                  {data && <OpenIcon />}
                </ColumnContent>
              );
            }}
          </InvoicePDFLink>
          <ColumnContent
            isBlue
            isAction
            flex={1}
            alignCenter
            onClick={() => setExpanded(!isExpanded)}
          >
            {isExpanded ? 'Collapse' : 'Expand'} <ChevronIcon up={isExpanded} />
          </ColumnContent>
        </>
      ) : (
        <MobileContentColumn>
          <MobileContentRow>
            <MobileColumnTitle flex={1} align="left">
              Invoice No.
            </MobileColumnTitle>
            <MobileColumnTitle flex={1} align="center">
              Invoice Total
            </MobileColumnTitle>
            <MobileColumnTitle flex={1} align="right">
              Date Issued
            </MobileColumnTitle>
          </MobileContentRow>
          <MobileContentRow>
            <MobileColumnContent flex={1} align="left">
              {invoice.number}
            </MobileColumnContent>
            <MobileColumnContent flex={1} align="center">
              {formatters.currency(invoice.total)}
            </MobileColumnContent>
            <MobileColumnContent flex={1} align="right">
              {formatters.date(invoice.issuedAt)}
            </MobileColumnContent>
          </MobileContentRow>
          <MobileContentRow style={{ marginTop: '1.2rem' }}>
            <MobileColumnTitle flex={1} align="left">
              Tracking No.
            </MobileColumnTitle>
          </MobileContentRow>
          <MobileContentRow>
            <MobileColumnContent flex={1} isBlue align="left">
              {invoice.trackingNumber || 'N/A'}
            </MobileColumnContent>
            <InvoicePDFLink invoiceNumber={invoice.invoiceNumber}>
              {({ data, loading, error }: any) => {
                return (
                  <MobileColumnContent
                    as={'a'}
                    isBlue
                    flex={1}
                    align="right"
                    rel="noreferrer"
                    target="_blank"
                    href={getPDFLink(data)}
                    style={getLinkStyling(data, loading, error)}
                  >
                    {getInvoiceText(loading, error, data)}
                    {data && <OpenIcon />}
                  </MobileColumnContent>
                );
              }}
            </InvoicePDFLink>
          </MobileContentRow>
          <MobileContentRow style={{ marginTop: '1rem' }}>
            <MobileAction onClick={() => setExpanded(!isExpanded)}>
              {isExpanded ? 'Collapse' : 'Expand'}
              <ChevronIcon up={isExpanded} />
            </MobileAction>
          </MobileContentRow>
        </MobileContentColumn>
      )}
    </Row>
  );

  return (
    <div className="invoice">
      <InvoiceModal
        order={order}
        invoice={invoice}
        isOpen={isDetailsModalOpen}
        onClose={() => setDetailsModalOpen(false)}
      />
      {renderInvoiceTableHead()}
      {isExpanded && renderInvoiceParts()}
    </div>
  );
};

export default InvoiceItem;
