import React from 'react';
import styled from 'styled-components';

import Navbar from './Navbar';
import AlertContainer from './AlertContainer';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const AppAreaContainer = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  background: ${({ theme: { colors } }) => colors.background};
`;

const withLayout = <P extends object>(Component: React.ComponentType<P>) => (
  props: any,
) => (
  <AppContainer>
    <Navbar />
    <AppAreaContainer>
      <AlertContainer />
      <Component {...props} />
    </AppAreaContainer>
  </AppContainer>
);

export default withLayout;
