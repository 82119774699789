import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import Fuse from 'fuse.js';
import FlipMove from 'react-flip-move';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import Loader from '../../components/LoaderContainer';
import OrderItemMobile from '../../components/Orders/OrderItemMobile';

import { ReactComponent as search } from '../../assets/images/search.svg';

import formatters from '../../utils/formatters';
import Pagination from '../../components/Pagination';
import { numDaysOptions } from '../../constants';
import SimplerDropDown from '../../components/Inputs/SimplerDropDown';

const SectionContainer = styled.div`
  flex: 1;
  position: static;
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.blueUltraLight};
  margin-bottom: 2rem;
  ${up('mobile')} {
    flex-direction: row;
    background-color: transparent;
    margin-bottom: 0;
  }
`;

const EmptyMessage = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.grayADA};
  font-family: ${({ theme: { typography } }) => typography.museo};
  padding: 3rem 1rem;
`;

const SearchIcon = styled(search)`
  left: 2rem;
  top: 1.35rem;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  rect,
  circle {
    stroke: ${({ theme: { colors } }) => colors.blueNeutral};
  }
`;

const SearchInputWrapper = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-size: 1.33rem;
  box-sizing: border-box;
  width: calc(100% - 2.5rem);
  padding: 1.25rem 1.25rem 1.25rem 2.6rem;
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
  &::placeholder {
    color: ${({ theme: { colors } }) => colors.blueNeutral};
  }
`;

const Strong = styled.span`
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-weight: 600;
`;

const DropDownDaysRow = styled.div`
  display: flex;
  background-color: ${({ theme: { colors } }) => colors.white};
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 2rem;
  padding: 0.5rem 0;
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

interface Props {
  selected?: string;
  setSelected: (number?: string) => void;
  orders: Array<unknown>;
  loading: boolean;
  isSavedOrders?: boolean;
  setDays?: (number: number) => void;
  days?: number;
}

const PAGE_SIZE = 10;

const OrdersSectionMobile: FunctionComponent<Props> = ({
  selected,
  setSelected,
  orders,
  loading,
  isSavedOrders,
  setDays,
  days,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const searchOptions = {
    shouldSort: true,
    minMatchCharLength: 2,
    keys: [
      {
        name: 'number',
        weight: 0.7,
      },
      { name: 'poNumber', weight: 0.3 },
    ],
  };

  const fuse = new Fuse(orders, searchOptions);
  const filteredOrders =
    searchTerm.length <= 2 ? orders : fuse.search(searchTerm);

  const totalPages = useMemo(() => {
    return Math.ceil(orders.length / PAGE_SIZE);
  }, [orders.length]);
  const processedOrders = useMemo(() => {
    const start = PAGE_SIZE * (page - 1);
    return filteredOrders.slice(start, start + PAGE_SIZE);
  }, [filteredOrders, page]);

  const pageUpdateHandler = useCallback((newIem: { selected: number }) => {
    setPage(newIem.selected + 1);
  }, []);
  return (
    <SectionContainer>
      {days ? (
        <DropDownDaysRow>
          <Strong>{filteredOrders.length} orders</Strong>&nbsp; in
          the&nbsp;&nbsp;
          <SimplerDropDown
            onChange={option => {
              setSelected(undefined);
              setDays?.(parseInt(option.value));
            }}
            options={numDaysOptions}
            value={`${days}`}
          />
        </DropDownDaysRow>
      ) : null}
      <SearchInputWrapper>
        <SearchIcon />
        <SearchInput
          value={searchTerm}
          placeholder="Search for an order"
          onChange={e => setSearchTerm(e.target.value)}
        />
      </SearchInputWrapper>
      <FlipMove>
        {filteredOrders.map((order: any) => (
          <div
            key={`order-${order.number}`}
            style={{ backgroundColor: 'white' }}
          >
            <OrderItemMobile
              isSavedOrders={isSavedOrders}
              selected={selected === order.number}
              onView={() => setSelected(order.number)}
              order={{
                id: order.id,
                number: order.number,
                poNumber: order.poNumber,
                submittedAt: formatters.date(order.submittedAt),
                total: order?.invoiceTotal || order?.total,
              }}
            />
          </div>
        ))}
      </FlipMove>
      {!loading && processedOrders?.length > 0 ? (
        <ActionRow>
          <Pagination
            page={page}
            pageCount={totalPages}
            onPageChange={pageUpdateHandler}
          />
        </ActionRow>
      ) : null}
      {loading ? <Loader /> : null}
      {!loading && !filteredOrders.length ? (
        <EmptyMessage>No orders in the last {days} days.</EmptyMessage>
      ) : null}
    </SectionContainer>
  );
};

export default OrdersSectionMobile;
