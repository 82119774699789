import omit from 'lodash/omit';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { State, AnyEventObject } from 'xstate';

import { useFormik, FormikConfig, FormikValues } from 'formik';

import utils from '../utils';
import client from '../utils/apolloClient';
import NewOrderFSM from '../utils/NewOrderProvider';

import {
  SearchPartsDocument,
  useSaveOrderMutation,
  useUpdateSavedOrderMutation,
  useSubmitOrderMutation,
} from '../generated/graphql';
import { parseErrorMessage } from '../utils/parseErrorMessage';
import { getOrderTotal } from '../utils/getOrderTotal';
import { mergeParts } from '../utils/mergeParts';
import useUser from './useUser';
import { dealerTypes } from '../constants';

const defaultInitialValues = {
  notes: '',
  address: '',
  city: '',
  customer: '',
  date: '',
  freightCode: '',
  orderType: null,
  parts: [],
  poNumber: '',
  shippingMethod: null,
  shippingMethodRaw: null,
  state: null,
  zip: '',
  country: '',
};

const formikDefault: FormikConfig<FormikValues> = {
  onSubmit: () => {
    // @TODO: As it stands this does nothing. Is it necessary?
  },
  initialValues: defaultInitialValues,
  validateOnChange: false,
  // validationSchema: OrderValidationSchema,
};

/**
 * Hook that handles the logic for a new order form
 */

type HookReturn = [
  FormikValues,
  State<any, AnyEventObject, any, any>,
  (event: any, payload?: any) => any,
  string,
];

interface InitialConfigs {
  showCover?: boolean;
  savedOrder?: string;
}

export default function useNewOrderForm(
  initialValues: any,
  initialConfigs: InitialConfigs = {},
): HookReturn {
  const history = useHistory();
  const [orderNumber, setOrderNumber] = useState<string>('');
  const user = useUser();
  const isExcavatorUser = user?.dealerType === dealerTypes.excavator;

  const formik: FormikValues = useFormik({
    ...formikDefault,
    initialValues: initialValues || defaultInitialValues,
  });

  // Checking if address was updated when filling out the forms
  const wasAddressUpdated =
    `${formik.values.shippingMethodRaw?.ConsigneeName1}${formik.values.shippingMethodRaw?.ConsigneeName2}` !==
      formik.values.customer ||
    formik.values.shippingMethodRaw?.ConsigneeStreet !==
      formik.values.address ||
    formik.values.shippingMethodRaw?.ConsigneeCity !== formik.values.city ||
    formik.values.shippingMethodRaw?.ConsigneeState !== formik.values.state ||
    formik.values.shippingMethodRaw?.ConsigneePostalCode !==
      formik.values.zip ||
    formik.values.shippingMethodRaw?.ConsigneeCountry !== formik.values.country;

  const orderInput = {
    ...formik.values,
    ShipToChangeFlag: wasAddressUpdated,
    shippingMethodRaw: formik?.values?.shippingMethodRaw
      ? JSON.stringify(formik.values.shippingMethodRaw)
      : null,
    parts: mergeParts(formik.values.parts),
  };

  const [saveOrderMutation] = useSaveOrderMutation({
    variables: {
      orderInput: orderInput,
      total: getOrderTotal(formik.values.parts, isExcavatorUser),
    },
    refetchQueries: ['searchSavedOrders'],
  });

  const [updateSavedOrderMutation] = useUpdateSavedOrderMutation({
    variables: {
      orderId: initialConfigs.savedOrder || '-1',
      orderInput: omit(orderInput, ['id', 'savedAt', '__typename', 'total']),
      total: getOrderTotal(formik.values.parts, isExcavatorUser),
    },
    refetchQueries: ['searchSavedOrders'],
  });

  let shippingMethod;

  try {
    shippingMethod = JSON.parse(orderInput?.shippingMethodRaw || {});
  } catch (_e) {
    // gently ignore parsing error
  }

  const [submitOrderMutation] = useSubmitOrderMutation({
    variables: {
      input: {
        savedCartId: orderInput.id,
        ...omit(orderInput, [
          'id',
          'total',
          'savedAt',
          '__typename',
          'shippingMethodRaw',
        ]),
        consigneeCode: shippingMethod?.ConsigneeCode,
      },
    },
    refetchQueries: ['searchOrders', 'searchSavedOrders'],
  });

  const machine = NewOrderFSM;
  if (!initialConfigs.showCover) {
    machine.initial = 'started';
  }

  const [current, send] = useMachine(machine);

  useEffect(() => {
    current.actions.forEach((action: any) => {
      if (action.type === 'save_order') {
        const callback = () => {
          send('COMPLETED');
          formik.resetForm();
          if (initialConfigs.savedOrder) {
            history.push('new-order');
          }
          utils.toast.show('Order Saved Successfully', 'success');
        };

        if (initialConfigs.savedOrder) {
          updateSavedOrderMutation()
            .then(callback)
            .catch(() => {
              send('FAILED');
              utils.toast.show('Failed to Update Order', 'error');
            });
        } else {
          saveOrderMutation()
            .then(callback)
            .catch(() => {
              send('FAILED');
              utils.toast.show('Failed to Save Order', 'error');
            });
        }
      } else if (action.type === 'submit_order') {
        const callback = (res: any) => {
          send('COMPLETED');
          formik.resetForm(defaultInitialValues);
          setOrderNumber(res.data.submitOrder.number);
        };

        submitOrderMutation()
          .then(callback)
          .catch((submitError: Error) => {
            send('FAILED');
            const submitErrorMessage = parseErrorMessage(
              submitError.message,
              'Failed to Submit Order',
            );
            utils.toast.show(submitErrorMessage, 'error');
          });
      }
    });

    if (current.matches({ inventory: 'loading' })) {
      client
        .query({
          query: SearchPartsDocument,
          variables: {
            parts: formik.values.parts.map((part: any) => part.number),
            exact: true,
          },
          fetchPolicy: 'network-only',
        })
        .then(res => {
          const stockIssueParts: Array<any> = [];

          formik.values.parts.forEach((part: any) => {
            const serverPart = res.data.searchParts.find(
              (prt: any) => prt.number === part.number,
            );

            const stock = serverPart.ATPstockSC;

            if (part.qty > stock) {
              stockIssueParts.push({
                ...part,
                stock,
              });
            }
          });
          if (stockIssueParts.length > 0) {
            send('STOCK', { parts: stockIssueParts });
          } else {
            send('CONTINUE');
          }
        });
    }
    // eslint-disable-next-line
  }, [current]);

  return [formik, current, send, orderNumber];
}
