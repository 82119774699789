/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/destructuring-assignment */
import React, { FunctionComponent, CSSProperties } from 'react';
import Select, { components, ValueType, ActionMeta } from 'react-select';

import ToRGB from '../../utils/color';
import constants, { Option } from '../../constants';

const { theme } = constants;
const { r, g, b } = ToRGB(theme.colors.blueDark);
const boxShadow = `0px 4px 8px rgba(${r}, ${g}, ${b}, 0.15), 0px 0px 2px rgba(${r}, ${g}, ${b}, 0.04)`;

const { ValueContainer, Placeholder } = components;
const CustomValueContainer: FunctionComponent<any> = ({
  children,
  ...props
}) => (
  <ValueContainer {...props}>
    <Placeholder {...props} isFocused={props.isFocused}>
      {props.selectProps.placeholder}
    </Placeholder>
    {React.Children.map(children, child =>
      child && child.type !== Placeholder ? child : null,
    )}
  </ValueContainer>
);

interface DropDownProps {
  id?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  noOptionsMessage?: string;
  onChange?: (option: Option) => void;
  options: Option[];
  required?: boolean;
  value?: string;
}

const DropDown: FunctionComponent<DropDownProps> = ({
  id,
  disabled,
  errorMessage,
  label,
  noOptionsMessage,
  onChange,
  options = [],
  required,
  value,
}) => {
  const inputStyle = (base: CSSProperties, state: any) => ({
    ...base,
    fontSize: '1rem',
    color: theme.colors.black,
    fontFamily: theme.typography.museo,
  });

  const placeholderStyle = (base: CSSProperties, state: any) => {
    const hasValueStyle: CSSProperties = state.hasValue
      ? {
          top: '-0.6rem',
          fontSize: '0.6rem',
          position: 'absolute',
          color: theme.colors.blueDark,
        }
      : {
          top: 'auto',
          fontSize: '1rem',
          position: 'inherit',
          color: theme.colors.grayDark,
        };

    return {
      ...base,
      ...hasValueStyle,
      transform: 'translateY(0)',
      fontFamily: theme.typography.museo,
      transition: 'top 0.12s ease-in-out, font-size 0.12s ease-in-out',
    };
  };

  const menuStyle = (base: CSSProperties, state: any) => ({
    ...base,
    boxShadow,
    margin: 0,
    padding: 0,
    zIndex: 10,
    borderRadius: 0,
  });

  const menuListStyle = (base: CSSProperties, state: any) => ({
    ...base,
    padding: 0,
  });

  const { r: rk, g: gk, b: bk } = ToRGB(theme.colors.blueKobelco);
  const optionBackgroundColor = `rgba(${rk}, ${gk}, ${bk}, 0.1)`;
  const optionStyle = (base: CSSProperties, state: any) => ({
    ...base,
    fontSize: '0.8rem',
    padding: '0.8rem 1rem',
    color: theme.colors.blueDark,
    fontFamily: theme.typography.museo,
    '&:first-of-type': { paddingTop: '1rem' },
    '&:last-child': { paddingBottom: '1rem' },
    '&:hover': { backgroundColor: optionBackgroundColor },
    '&:active': { backgroundColor: optionBackgroundColor },
    backgroundColor: state.isSelected ? optionBackgroundColor : 'transparent',
  });

  const controlStyle = (base: CSSProperties, state: any) => {
    const backgroundColor = state.hasValue
      ? theme.colors.grayLight
      : theme.colors.blueUltraLight;

    return {
      ...base,
      borderRadius: 0,
      height: '3.8rem',
      boxShadow: state.selectProps.menuIsOpen ? boxShadow : undefined,
      border:
        required && !!errorMessage ? `2px solid ${theme.colors.red}` : 'none',
      borderBottom: state.selectProps.menuIsOpen
        ? `0.1rem solid ${theme.colors.blueLight}`
        : undefined,
      backgroundColor: state.selectProps.menuIsOpen
        ? theme.colors.white
        : backgroundColor,
      '&:hover': {
        borderColor:
          required && !!errorMessage
            ? `2px solid ${theme.colors.red}`
            : theme.colors.blueLight,
      },
    };
  };

  const dropdownIndicatorStyle = (base: CSSProperties, state: any) => {
    if (disabled) {
      return { display: 'none' };
    }

    return {
      ...base,
      transition: 'transform 0.12s ease-in-out',
      '&:hover': { color: theme.colors.blueKobelco },
      transform: state.selectProps.menuIsOpen
        ? 'rotate(180deg)'
        : 'rotate(0deg)',
      color: state.selectProps.menuIsOpen
        ? theme.colors.blueKobelco
        : theme.colors.grayADA,
    };
  };

  const valueContainerStyle = (base: CSSProperties, state: any) => ({
    ...base,
    paddingRight: 0,
  });

  const noOptionsMessageStyle = (base: CSSProperties) => ({
    ...base,
    fontFamily: theme.typography.museo,
  });

  const handleSelectOption = (
    option: ValueType<Option>,
    actionMeta: ActionMeta,
  ) => {
    if (option && onChange) {
      const { value: newValue, label: newLabel }: any = option;
      onChange({ value: newValue, label: newLabel });
    }
  };

  return (
    <Select
      id={id}
      noOptionsMessage={() => noOptionsMessage || 'No Options'}
      options={options}
      placeholder={label}
      isDisabled={disabled}
      onChange={handleSelectOption}
      value={
        options
          .filter(Boolean)
          .filter(({ value }) => !!value)
          .find(option => option.value === value) || null
      }
      components={{
        IndicatorSeparator: null,
        ValueContainer: CustomValueContainer,
      }}
      styles={{
        control: controlStyle,
        dropdownIndicator: dropdownIndicatorStyle,
        input: inputStyle,
        menu: menuStyle,
        menuList: menuListStyle,
        option: optionStyle,
        placeholder: placeholderStyle,
        singleValue: inputStyle,
        valueContainer: valueContainerStyle,
        noOptionsMessage: noOptionsMessageStyle,
      }}
    />
  );
};

export default DropDown;
