import React from 'react';
import styled from 'styled-components';

import { ReactComponent as open } from '../../assets/images/open.svg';
import { ReactComponent as Chevron } from '../../assets/images/chevron.svg';

interface MobileColumnTitleProps {
  flex: number;
  align: 'left' | 'right' | 'center';
}

interface MobileColumnContentProps {
  flex: number;
  isBlue?: boolean;
  isAction?: boolean;
  align: 'left' | 'right' | 'center';
}

interface ColumnContentProps {
  flex: number;
  isBlue?: boolean;
  isAction?: boolean;
  alignCenter?: boolean;
}

export const ColumnTitle = styled.span<{ flex: number }>`
  font-size: 1rem;
  line-height: normal;
  flex: ${({ flex }) => flex};
  color: ${({ theme }) => theme.colors.blueNeutral};
  font-family: ${({ theme }) => theme.typography.museo};
`;

export const ChevronIcon = styled(({ up, ...rest }) => <Chevron {...rest} />)<{
  up?: boolean;
}>`
  margin-left: 0.6rem;
  transition: transform 0.12s ease-in-out;
  color: ${({ theme }) => theme.colors.blueDark};
  transform: ${({ up }) => (up ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const OpenIcon = styled(open)`
  margin-left: 0.6rem;
  color: ${({ theme }) => theme.colors.blueDark};
`;

export const ColumnContent = styled.span<ColumnContentProps>`
  font-size: 1rem;
  line-height: normal;
  flex: ${({ flex }) => flex};
  font-family: ${({ theme }) => theme.typography.museo};
  cursor: ${({ isAction }) => (isAction ? 'pointer' : undefined)};
  user-select: ${({ isAction }) => (isAction ? 'none' : undefined)};
  display: ${({ alignCenter }) => (alignCenter ? 'flex' : undefined)};
  flex-direction: ${({ alignCenter }) => (alignCenter ? 'row' : undefined)};
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : undefined)};
  color: ${({ theme, isBlue }) =>
    isBlue ? theme.colors.blueDark : theme.colors.black};
`;

export const PartsContainer = styled.div`
  padding: 0.8rem 0;
  border-bottom: ${({ theme }) => `0.1rem solid ${theme.colors.gray}`};
`;

export const PartColumnTitle = styled.span<{ flex: number; center?: boolean }>`
  font-size: 0.8rem;
  line-height: normal;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  flex: ${({ flex }) => flex};
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.typography.museo};
  text-align: ${({ center }) => (center ? 'center' : undefined)};
`;

export const MobileContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MobileContentColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MobileColumnTitle = styled.span<MobileColumnTitleProps>`
  font-size: 0.8rem;
  line-height: normal;
  flex: ${({ flex }) => flex};
  text-align: ${({ align }) => align};
  color: ${({ theme }) => theme.colors.blueNeutral};
  font-family: ${({ theme }) => theme.typography.museo};
`;

export const MobileColumnContent = styled.span<MobileColumnContentProps>`
  line-height: normal;
  flex: ${({ flex }) => flex};
  text-align: ${({ align }) => align};
  font-family: ${({ theme }) => theme.typography.museo};
  font-size: ${({ isAction }) => (isAction ? '1.2rem' : '1rem')};
  cursor: ${({ isAction }) => (isAction ? 'pointer' : undefined)};
  user-select: ${({ isAction }) => (isAction ? 'none' : undefined)};
  color: ${({ theme, isBlue }) =>
    isBlue ? theme.colors.blueDark : theme.colors.grayADA};
`;

export const MobileAction = styled.span`
  font-size: 1.2rem;
  line-height: normal;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.typography.museo};
`;
