// eslint-disable
// prettier-ignore-start
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;


/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
};

export type IAlert = {
   __typename?: 'Alert',
  id: Scalars['String'],
  title: Scalars['String'],
  subtitle: Scalars['String'],
};

export enum ICacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type ICurrentUser = {
   __typename?: 'CurrentUser',
  favorites?: Maybe<Array<Maybe<IPart>>>,
  user: IUser,
  states?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type IFavorites = {
   __typename?: 'Favorites',
  results?: Maybe<Array<Maybe<IPart>>>,
  total?: Maybe<Scalars['Int']>,
};

export type IInvoice = {
   __typename?: 'Invoice',
  issuedAt?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  parts: Array<Maybe<IPart>>,
  total?: Maybe<Scalars['Float']>,
  trackingNumber?: Maybe<Scalars['String']>,
};

export type IInvoiceUrl = {
   __typename?: 'InvoiceURL',
  url?: Maybe<Scalars['String']>,
};

export type IMutation = {
   __typename?: 'Mutation',
  deleteSavedOrder: Scalars['Boolean'],
  favoritePart?: Maybe<IPart>,
  login: IUserSession,
  ssoLogin: IUserSession,
  saveOrder: ISavedOrder,
  submitOrder: IOrder,
  unfavoritePart?: Maybe<Scalars['Boolean']>,
  updateSavedOrder: ISavedOrder,
  requestLogin: Scalars['Boolean'],
};


export type IMutationDeleteSavedOrderArgs = {
  orderId: Scalars['String']
};


export type IMutationFavoritePartArgs = {
  partNumber: Scalars['String']
};


export type IMutationLoginArgs = {
  id: Scalars['String'],
  password: Scalars['String']
};


export type IMutationSsoLoginArgs = {
  Cert: Scalars['String']
};


export type IMutationSaveOrderArgs = {
  input: ISaveOrderInput,
  total?: Maybe<Scalars['Float']>
};


export type IMutationSubmitOrderArgs = {
  input: ISubmitOrderInput
};


export type IMutationUnfavoritePartArgs = {
  partNumber: Scalars['String']
};


export type IMutationUpdateSavedOrderArgs = {
  orderId: Scalars['String'],
  input: ISaveOrderInput,
  total?: Maybe<Scalars['Float']>
};


export type IMutationRequestLoginArgs = {
  input: IRequestLoginInput
};

export type IOrder = {
   __typename?: 'Order',
  id?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  consigneeCode?: Maybe<Scalars['String']>,
  consigneeCity?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  customer?: Maybe<Scalars['String']>,
  date?: Maybe<Scalars['String']>,
  freightCode?: Maybe<Scalars['String']>,
  invoices?: Maybe<Array<Maybe<IInvoice>>>,
  invoiceTotal?: Maybe<Scalars['Float']>,
  number?: Maybe<Scalars['String']>,
  orderType?: Maybe<Scalars['String']>,
  parts?: Maybe<Array<Maybe<IPart>>>,
  poNumber?: Maybe<Scalars['String']>,
  shippingMethod?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  submittedAt?: Maybe<Scalars['String']>,
  zip?: Maybe<Scalars['String']>,
};

export type IOrderListItem = {
   __typename?: 'OrderListItem',
  Currency?: Maybe<Scalars['String']>,
  InvoiceTotal?: Maybe<Scalars['Float']>,
  PONo?: Maybe<Scalars['String']>,
  SalesOrderNo?: Maybe<Scalars['String']>,
  SODate?: Maybe<Scalars['String']>,
  Status?: Maybe<Scalars['String']>,
};

export enum IOrderStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  OpenAndClosed = 'OPEN_AND_CLOSED'
}

export type IPart = {
   __typename?: 'Part',
  alternateFor?: Maybe<Scalars['String']>,
  ATPstockCR?: Maybe<Scalars['Float']>,
  ATPstockSC?: Maybe<Scalars['Float']>,
  ATPstockSH?: Maybe<Scalars['Float']>,
  dealerPrice?: Maybe<Scalars['Float']>,
  description?: Maybe<Scalars['String']>,
  dimensionUnit?: Maybe<Scalars['String']>,
  grossWeight?: Maybe<Scalars['Float']>,
  height?: Maybe<Scalars['Float']>,
  inputNumber?: Maybe<Scalars['String']>,
  length?: Maybe<Scalars['Float']>,
  number: Scalars['String'],
  price?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
  specialPrice?: Maybe<Scalars['Float']>,
  weight?: Maybe<Scalars['Float']>,
  weightUnit?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['Float']>,
  notServiceFlag?: Maybe<Scalars['String']>,
  masterCheckFlag?: Maybe<Scalars['String']>,
  /** Attributes for Order Details */
  invoiceAmount?: Maybe<Scalars['Float']>,
  invoiceDate?: Maybe<Scalars['String']>,
  invoiceItem?: Maybe<Scalars['Float']>,
  invoiceNumber?: Maybe<Scalars['String']>,
  payer?: Maybe<Scalars['String']>,
  soItem?: Maybe<Scalars['Int']>,
  trackingNumber?: Maybe<Scalars['String']>,
};

export type IPartInput = {
  number?: Maybe<Scalars['String']>,
  inputNumber?: Maybe<Scalars['String']>,
  quantity?: Maybe<Scalars['Int']>,
};

export type IQuery = {
   __typename?: 'Query',
  currentUser?: Maybe<ICurrentUser>,
  favoriteParts?: Maybe<IFavorites>,
  searchOrders?: Maybe<Array<Maybe<IOrder>>>,
  orderDetails?: Maybe<IOrder>,
  searchParts?: Maybe<Array<Maybe<IPart>>>,
  searchSavedOrders?: Maybe<Array<Maybe<ISavedOrder>>>,
  shippingMethods?: Maybe<Array<Maybe<IShippingMethod>>>,
  invoice?: Maybe<IInvoiceUrl>,
  searchAlerts?: Maybe<Array<Maybe<IAlert>>>,
};


export type IQueryFavoritePartsArgs = {
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>
};


export type IQuerySearchOrdersArgs = {
  days?: Maybe<Scalars['Int']>,
  search?: Maybe<Scalars['String']>,
  status?: Maybe<IOrderStatus>
};


export type IQueryOrderDetailsArgs = {
  orderNumber?: Maybe<Scalars['String']>
};


export type IQuerySearchPartsArgs = {
  parts: Array<Maybe<Scalars['String']>>,
  exact?: Maybe<Scalars['Boolean']>
};


export type IQuerySearchSavedOrdersArgs = {
  poNumber?: Maybe<Scalars['String']>
};


export type IQueryInvoiceArgs = {
  invoiceNumber: Scalars['String']
};

export type IRequestLoginInput = {
  fullName: Scalars['String'],
  dealerName: Scalars['String'],
  dealerAddress: Scalars['String'],
  dealerCity: Scalars['String'],
  dealerState: Scalars['String'],
  dealerZip: Scalars['String'],
  businessEmail: Scalars['String'],
  businessPhone: Scalars['String'],
  message?: Maybe<Scalars['String']>,
};

export type ISavedOrder = {
   __typename?: 'SavedOrder',
  address?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  consigneeCode?: Maybe<Scalars['String']>,
  customer?: Maybe<Scalars['String']>,
  date?: Maybe<Scalars['String']>,
  freightCode?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  orderType?: Maybe<Scalars['String']>,
  parts?: Maybe<Array<Maybe<IPart>>>,
  poNumber?: Maybe<Scalars['String']>,
  savedAt?: Maybe<Scalars['String']>,
  shippingMethod?: Maybe<Scalars['String']>,
  shippingMethodRaw?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  total?: Maybe<Scalars['Float']>,
  zip?: Maybe<Scalars['String']>,
};

export type ISaveOrderInput = {
  address?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  consigneeCode?: Maybe<Scalars['String']>,
  customer?: Maybe<Scalars['String']>,
  date?: Maybe<Scalars['String']>,
  freightCode?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  orderType?: Maybe<Scalars['String']>,
  parts?: Maybe<Array<Maybe<IPartInput>>>,
  poNumber?: Maybe<Scalars['String']>,
  shippingMethod?: Maybe<Scalars['String']>,
  shippingMethodRaw?: Maybe<Scalars['String']>,
  ShipToChangeFlag?: Maybe<Scalars['Boolean']>,
  state?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  zip?: Maybe<Scalars['String']>,
};

export type IShippingMethod = {
   __typename?: 'ShippingMethod',
  ChangeFlag?: Maybe<Scalars['String']>,
  ConsigneeCity?: Maybe<Scalars['String']>,
  ConsigneeCode?: Maybe<Scalars['String']>,
  ConsigneeCountry?: Maybe<Scalars['String']>,
  ConsigneeDistrict?: Maybe<Scalars['String']>,
  ConsigneeName1?: Maybe<Scalars['String']>,
  ConsigneeName2?: Maybe<Scalars['String']>,
  ConsigneePostalCode?: Maybe<Scalars['String']>,
  ConsigneeState?: Maybe<Scalars['String']>,
  ConsigneeStreet?: Maybe<Scalars['String']>,
  SalesOffice?: Maybe<Scalars['String']>,
  ShippingMethod?: Maybe<Scalars['String']>,
  SOType?: Maybe<Scalars['String']>,
};

export type ISubmitOrderInput = {
  address?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  consigneeCode: Scalars['String'],
  consigneeDistrict?: Maybe<Scalars['String']>,
  customer?: Maybe<Scalars['String']>,
  date?: Maybe<Scalars['String']>,
  freightCode?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  orderType?: Maybe<Scalars['String']>,
  parts?: Maybe<Array<Maybe<IPartInput>>>,
  poNumber?: Maybe<Scalars['String']>,
  savedCartId?: Maybe<Scalars['String']>,
  shippingMethod?: Maybe<Scalars['String']>,
  ShipToChangeFlag?: Maybe<Scalars['Boolean']>,
  state?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  zip?: Maybe<Scalars['String']>,
};


export type IUser = {
   __typename?: 'User',
  dealer_id: Scalars['String'],
  email: Scalars['String'],
  id: Scalars['String'],
  name: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  sales_org?: Maybe<Scalars['String']>,
};

export type IUserSession = {
   __typename?: 'UserSession',
  token: Scalars['String'],
  user: IUser,
};

export type IDeleteSavedOrderMutationVariables = {
  orderId: Scalars['String']
};


export type IDeleteSavedOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'deleteSavedOrder'>
);

export type IFavoritePartMutationVariables = {
  partNumber: Scalars['String']
};


export type IFavoritePartMutation = (
  { __typename?: 'Mutation' }
  & { favoritePart: Maybe<(
    { __typename?: 'Part' }
    & Pick<IPart, 'number' | 'description' | 'length' | 'width' | 'height' | 'weight' | 'weightUnit' | 'ATPstockSC' | 'ATPstockSH' | 'ATPstockCR' | 'price' | 'dealerPrice' | 'specialPrice'>
  )> }
);

export type ILoginMutationVariables = {
  id: Scalars['String'],
  password: Scalars['String']
};


export type ILoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'UserSession' }
    & Pick<IUserSession, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<IUser, 'id' | 'email' | 'phone' | 'name' | 'dealer_id' | 'sales_org'>
    ) }
  ) }
);

export type IRequestLoginMutationVariables = {
  input: IRequestLoginInput
};


export type IRequestLoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'requestLogin'>
);

export type ISsoLoginMutationVariables = {
  Cert: Scalars['String']
};


export type ISsoLoginMutation = (
  { __typename?: 'Mutation' }
  & { ssoLogin: (
    { __typename?: 'UserSession' }
    & Pick<IUserSession, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<IUser, 'id' | 'email' | 'phone' | 'name' | 'dealer_id' | 'sales_org'>
    ) }
  ) }
);

export type ISaveOrderMutationVariables = {
  orderInput: ISaveOrderInput,
  total?: Maybe<Scalars['Float']>
};


export type ISaveOrderMutation = (
  { __typename?: 'Mutation' }
  & { saveOrder: (
    { __typename?: 'SavedOrder' }
    & Pick<ISavedOrder, 'id' | 'date' | 'poNumber' | 'total' | 'orderType' | 'shippingMethod' | 'shippingMethodRaw' | 'freightCode' | 'city' | 'customer' | 'country' | 'address' | 'state' | 'zip' | 'savedAt'>
    & { parts: Maybe<Array<Maybe<(
      { __typename?: 'Part' }
      & Pick<IPart, 'number' | 'quantity'>
    )>>> }
  ) }
);

export type ISubmitOrderMutationVariables = {
  input: ISubmitOrderInput
};


export type ISubmitOrderMutation = (
  { __typename?: 'Mutation' }
  & { submitOrder: (
    { __typename?: 'Order' }
    & Pick<IOrder, 'number'>
  ) }
);

export type IUnfavoritePartMutationVariables = {
  partNumber: Scalars['String']
};


export type IUnfavoritePartMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'unfavoritePart'>
);

export type IUpdateSavedOrderMutationVariables = {
  orderId: Scalars['String'],
  orderInput: ISaveOrderInput,
  total?: Maybe<Scalars['Float']>
};


export type IUpdateSavedOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateSavedOrder: (
    { __typename?: 'SavedOrder' }
    & Pick<ISavedOrder, 'id' | 'date' | 'poNumber' | 'total' | 'orderType' | 'shippingMethod' | 'shippingMethodRaw' | 'freightCode' | 'customer' | 'city' | 'country' | 'address' | 'state' | 'zip' | 'savedAt'>
    & { parts: Maybe<Array<Maybe<(
      { __typename?: 'Part' }
      & Pick<IPart, 'number' | 'quantity'>
    )>>> }
  ) }
);

export type ICurrentUserQueryVariables = {};


export type ICurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'CurrentUser' }
    & Pick<ICurrentUser, 'states'>
    & { user: (
      { __typename?: 'User' }
      & Pick<IUser, 'id' | 'email' | 'phone' | 'name' | 'dealer_id' | 'sales_org'>
    ), favorites: Maybe<Array<Maybe<(
      { __typename?: 'Part' }
      & Pick<IPart, 'number' | 'description'>
    )>>> }
  )> }
);

export type IFavoritePartsQueryVariables = {};


export type IFavoritePartsQuery = (
  { __typename?: 'Query' }
  & { favoriteParts: Maybe<(
    { __typename?: 'Favorites' }
    & Pick<IFavorites, 'total'>
    & { results: Maybe<Array<Maybe<(
      { __typename?: 'Part' }
      & Pick<IPart, 'alternateFor' | 'ATPstockCR' | 'ATPstockSC' | 'ATPstockSH' | 'dealerPrice' | 'description' | 'grossWeight' | 'height' | 'inputNumber' | 'invoiceAmount' | 'invoiceDate' | 'invoiceItem' | 'invoiceNumber' | 'length' | 'masterCheckFlag' | 'notServiceFlag' | 'number' | 'payer' | 'price' | 'quantity' | 'soItem' | 'specialPrice' | 'trackingNumber' | 'weight' | 'weightUnit' | 'width'>
    )>>> }
  )> }
);

export type IInvoiceUrlQueryVariables = {
  invoiceNumber: Scalars['String']
};


export type IInvoiceUrlQuery = (
  { __typename?: 'Query' }
  & { invoice: Maybe<(
    { __typename?: 'InvoiceURL' }
    & Pick<IInvoiceUrl, 'url'>
  )> }
);

export type IOrderDetailsQueryVariables = {
  orderNumber: Scalars['String']
};


export type IOrderDetailsQuery = (
  { __typename?: 'Query' }
  & { orderDetails: Maybe<(
    { __typename?: 'Order' }
    & Pick<IOrder, 'address' | 'consigneeCity' | 'consigneeCode' | 'currency' | 'customer' | 'date' | 'freightCode' | 'invoiceTotal' | 'number' | 'orderType' | 'poNumber' | 'shippingMethod' | 'state' | 'country' | 'status' | 'submittedAt' | 'zip'>
    & { parts: Maybe<Array<Maybe<(
      { __typename?: 'Part' }
      & Pick<IPart, 'alternateFor' | 'ATPstockCR' | 'ATPstockSC' | 'ATPstockSH' | 'dealerPrice' | 'description' | 'dimensionUnit' | 'grossWeight' | 'height' | 'inputNumber' | 'invoiceAmount' | 'invoiceDate' | 'invoiceItem' | 'invoiceNumber' | 'length' | 'number' | 'payer' | 'price' | 'quantity' | 'soItem' | 'specialPrice' | 'trackingNumber' | 'weight' | 'weightUnit' | 'width'>
    )>>>, invoices: Maybe<Array<Maybe<(
      { __typename?: 'Invoice' }
      & Pick<IInvoice, 'number' | 'trackingNumber' | 'issuedAt' | 'total'>
      & { parts: Array<Maybe<(
        { __typename?: 'Part' }
        & Pick<IPart, 'number' | 'price' | 'quantity' | 'alternateFor'>
      )>> }
    )>>> }
  )> }
);

export type ISearchAlertsQueryVariables = {};


export type ISearchAlertsQuery = (
  { __typename?: 'Query' }
  & { searchAlerts: Maybe<Array<Maybe<(
    { __typename?: 'Alert' }
    & Pick<IAlert, 'id' | 'title' | 'subtitle'>
  )>>> }
);

export type ISearchOrdersQueryVariables = {
  days?: Maybe<Scalars['Int']>,
  status?: Maybe<IOrderStatus>,
  search?: Maybe<Scalars['String']>
};


export type ISearchOrdersQuery = (
  { __typename?: 'Query' }
  & { searchOrders: Maybe<Array<Maybe<(
    { __typename?: 'Order' }
    & Pick<IOrder, 'number' | 'status' | 'poNumber' | 'submittedAt' | 'invoiceTotal' | 'currency'>
    & { parts: Maybe<Array<Maybe<(
      { __typename?: 'Part' }
      & Pick<IPart, 'alternateFor' | 'ATPstockCR' | 'ATPstockSC' | 'ATPstockSH' | 'dealerPrice' | 'description' | 'dimensionUnit' | 'grossWeight' | 'height' | 'inputNumber' | 'invoiceAmount' | 'invoiceDate' | 'invoiceItem' | 'invoiceNumber' | 'length' | 'number' | 'payer' | 'price' | 'quantity' | 'soItem' | 'specialPrice' | 'trackingNumber' | 'weight' | 'weightUnit' | 'width'>
    )>>>, invoices: Maybe<Array<Maybe<(
      { __typename?: 'Invoice' }
      & Pick<IInvoice, 'number' | 'trackingNumber' | 'issuedAt' | 'total'>
      & { parts: Array<Maybe<(
        { __typename?: 'Part' }
        & Pick<IPart, 'number' | 'price' | 'quantity' | 'alternateFor'>
      )>> }
    )>>> }
  )>>> }
);

export type ISearchPartsQueryVariables = {
  parts: Array<Maybe<Scalars['String']>>,
  exact?: Maybe<Scalars['Boolean']>
};


export type ISearchPartsQuery = (
  { __typename?: 'Query' }
  & { searchParts: Maybe<Array<Maybe<(
    { __typename?: 'Part' }
    & Pick<IPart, 'alternateFor' | 'ATPstockCR' | 'ATPstockSC' | 'ATPstockSH' | 'dealerPrice' | 'description' | 'dimensionUnit' | 'grossWeight' | 'height' | 'inputNumber' | 'invoiceAmount' | 'invoiceDate' | 'invoiceItem' | 'invoiceNumber' | 'length' | 'masterCheckFlag' | 'notServiceFlag' | 'number' | 'payer' | 'price' | 'quantity' | 'soItem' | 'specialPrice' | 'trackingNumber' | 'weight' | 'weightUnit' | 'width'>
  )>>> }
);

export type ISearchSavedOrdersQueryVariables = {
  poNumber?: Maybe<Scalars['String']>
};


export type ISearchSavedOrdersQuery = (
  { __typename?: 'Query' }
  & { searchSavedOrders: Maybe<Array<Maybe<(
    { __typename?: 'SavedOrder' }
    & Pick<ISavedOrder, 'date' | 'id' | 'total' | 'poNumber' | 'address' | 'country' | 'consigneeCode' | 'city' | 'customer' | 'freightCode' | 'orderType' | 'savedAt' | 'shippingMethod' | 'shippingMethodRaw' | 'state' | 'zip'>
    & { parts: Maybe<Array<Maybe<(
      { __typename?: 'Part' }
      & Pick<IPart, 'alternateFor' | 'ATPstockCR' | 'ATPstockSC' | 'ATPstockSH' | 'dealerPrice' | 'description' | 'grossWeight' | 'height' | 'inputNumber' | 'invoiceAmount' | 'invoiceDate' | 'invoiceItem' | 'invoiceNumber' | 'length' | 'masterCheckFlag' | 'notServiceFlag' | 'number' | 'payer' | 'price' | 'quantity' | 'soItem' | 'specialPrice' | 'trackingNumber' | 'weight' | 'weightUnit' | 'width'>
    )>>> }
  )>>> }
);

export type IGetShippingMethodsQueryVariables = {};


export type IGetShippingMethodsQuery = (
  { __typename?: 'Query' }
  & { shippingMethods: Maybe<Array<Maybe<(
    { __typename?: 'ShippingMethod' }
    & Pick<IShippingMethod, 'SalesOffice' | 'SOType' | 'ConsigneeCode' | 'ShippingMethod' | 'ConsigneeName1' | 'ConsigneeName2' | 'ConsigneeStreet' | 'ConsigneeCity' | 'ConsigneeDistrict' | 'ConsigneeState' | 'ConsigneePostalCode' | 'ConsigneeCountry' | 'ChangeFlag'>
  )>>> }
);


export const DeleteSavedOrderDocument = gql`
    mutation deleteSavedOrder($orderId: String!) {
  deleteSavedOrder(orderId: $orderId)
}
    `;
export type IDeleteSavedOrderMutationFn = ApolloReactCommon.MutationFunction<IDeleteSavedOrderMutation, IDeleteSavedOrderMutationVariables>;
export type DeleteSavedOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<IDeleteSavedOrderMutation, IDeleteSavedOrderMutationVariables>, 'mutation'>;

    export const DeleteSavedOrderComponent = (props: DeleteSavedOrderComponentProps) => (
      <ApolloReactComponents.Mutation<IDeleteSavedOrderMutation, IDeleteSavedOrderMutationVariables> mutation={DeleteSavedOrderDocument} {...props} />
    );
    
export type IDeleteSavedOrderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<IDeleteSavedOrderMutation, IDeleteSavedOrderMutationVariables> | TChildProps;
export function withDeleteSavedOrder<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IDeleteSavedOrderMutation,
  IDeleteSavedOrderMutationVariables,
  IDeleteSavedOrderProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, IDeleteSavedOrderMutation, IDeleteSavedOrderMutationVariables, IDeleteSavedOrderProps<TChildProps>>(DeleteSavedOrderDocument, {
      alias: 'deleteSavedOrder',
      ...operationOptions
    });
};

/**
 * __useDeleteSavedOrderMutation__
 *
 * To run a mutation, you first call `useDeleteSavedOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedOrderMutation, { data, loading, error }] = useDeleteSavedOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useDeleteSavedOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteSavedOrderMutation, IDeleteSavedOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteSavedOrderMutation, IDeleteSavedOrderMutationVariables>(DeleteSavedOrderDocument, baseOptions);
      }
export type DeleteSavedOrderMutationHookResult = ReturnType<typeof useDeleteSavedOrderMutation>;
export type DeleteSavedOrderMutationResult = ApolloReactCommon.MutationResult<IDeleteSavedOrderMutation>;
export type DeleteSavedOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteSavedOrderMutation, IDeleteSavedOrderMutationVariables>;
export const FavoritePartDocument = gql`
    mutation favoritePart($partNumber: String!) {
  favoritePart(partNumber: $partNumber) {
    number
    description
    length
    width
    height
    weight
    weightUnit
    ATPstockSC
    ATPstockSH
    ATPstockCR
    price
    dealerPrice
    specialPrice
  }
}
    `;
export type IFavoritePartMutationFn = ApolloReactCommon.MutationFunction<IFavoritePartMutation, IFavoritePartMutationVariables>;
export type FavoritePartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<IFavoritePartMutation, IFavoritePartMutationVariables>, 'mutation'>;

    export const FavoritePartComponent = (props: FavoritePartComponentProps) => (
      <ApolloReactComponents.Mutation<IFavoritePartMutation, IFavoritePartMutationVariables> mutation={FavoritePartDocument} {...props} />
    );
    
export type IFavoritePartProps<TChildProps = {}> = ApolloReactHoc.MutateProps<IFavoritePartMutation, IFavoritePartMutationVariables> | TChildProps;
export function withFavoritePart<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IFavoritePartMutation,
  IFavoritePartMutationVariables,
  IFavoritePartProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, IFavoritePartMutation, IFavoritePartMutationVariables, IFavoritePartProps<TChildProps>>(FavoritePartDocument, {
      alias: 'favoritePart',
      ...operationOptions
    });
};

/**
 * __useFavoritePartMutation__
 *
 * To run a mutation, you first call `useFavoritePartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoritePartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoritePartMutation, { data, loading, error }] = useFavoritePartMutation({
 *   variables: {
 *      partNumber: // value for 'partNumber'
 *   },
 * });
 */
export function useFavoritePartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IFavoritePartMutation, IFavoritePartMutationVariables>) {
        return ApolloReactHooks.useMutation<IFavoritePartMutation, IFavoritePartMutationVariables>(FavoritePartDocument, baseOptions);
      }
export type FavoritePartMutationHookResult = ReturnType<typeof useFavoritePartMutation>;
export type FavoritePartMutationResult = ApolloReactCommon.MutationResult<IFavoritePartMutation>;
export type FavoritePartMutationOptions = ApolloReactCommon.BaseMutationOptions<IFavoritePartMutation, IFavoritePartMutationVariables>;
export const LoginDocument = gql`
    mutation login($id: String!, $password: String!) {
  login(id: $id, password: $password) {
    user {
      id
      email
      phone
      name
      dealer_id
      sales_org
    }
    token
  }
}
    `;
export type ILoginMutationFn = ApolloReactCommon.MutationFunction<ILoginMutation, ILoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ILoginMutation, ILoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<ILoginMutation, ILoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    
export type ILoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ILoginMutation, ILoginMutationVariables> | TChildProps;
export function withLogin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ILoginMutation,
  ILoginMutationVariables,
  ILoginProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ILoginMutation, ILoginMutationVariables, ILoginProps<TChildProps>>(LoginDocument, {
      alias: 'login',
      ...operationOptions
    });
};

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ILoginMutation, ILoginMutationVariables>) {
        return ApolloReactHooks.useMutation<ILoginMutation, ILoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<ILoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<ILoginMutation, ILoginMutationVariables>;
export const RequestLoginDocument = gql`
    mutation requestLogin($input: RequestLoginInput!) {
  requestLogin(input: $input)
}
    `;
export type IRequestLoginMutationFn = ApolloReactCommon.MutationFunction<IRequestLoginMutation, IRequestLoginMutationVariables>;
export type RequestLoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<IRequestLoginMutation, IRequestLoginMutationVariables>, 'mutation'>;

    export const RequestLoginComponent = (props: RequestLoginComponentProps) => (
      <ApolloReactComponents.Mutation<IRequestLoginMutation, IRequestLoginMutationVariables> mutation={RequestLoginDocument} {...props} />
    );
    
export type IRequestLoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<IRequestLoginMutation, IRequestLoginMutationVariables> | TChildProps;
export function withRequestLogin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IRequestLoginMutation,
  IRequestLoginMutationVariables,
  IRequestLoginProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, IRequestLoginMutation, IRequestLoginMutationVariables, IRequestLoginProps<TChildProps>>(RequestLoginDocument, {
      alias: 'requestLogin',
      ...operationOptions
    });
};

/**
 * __useRequestLoginMutation__
 *
 * To run a mutation, you first call `useRequestLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestLoginMutation, { data, loading, error }] = useRequestLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IRequestLoginMutation, IRequestLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<IRequestLoginMutation, IRequestLoginMutationVariables>(RequestLoginDocument, baseOptions);
      }
export type RequestLoginMutationHookResult = ReturnType<typeof useRequestLoginMutation>;
export type RequestLoginMutationResult = ApolloReactCommon.MutationResult<IRequestLoginMutation>;
export type RequestLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<IRequestLoginMutation, IRequestLoginMutationVariables>;
export const SsoLoginDocument = gql`
    mutation ssoLogin($Cert: String!) {
  ssoLogin(Cert: $Cert) {
    user {
      id
      email
      phone
      name
      dealer_id
      sales_org
    }
    token
  }
}
    `;
export type ISsoLoginMutationFn = ApolloReactCommon.MutationFunction<ISsoLoginMutation, ISsoLoginMutationVariables>;
export type SsoLoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ISsoLoginMutation, ISsoLoginMutationVariables>, 'mutation'>;

    export const SsoLoginComponent = (props: SsoLoginComponentProps) => (
      <ApolloReactComponents.Mutation<ISsoLoginMutation, ISsoLoginMutationVariables> mutation={SsoLoginDocument} {...props} />
    );
    
export type ISsoLoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ISsoLoginMutation, ISsoLoginMutationVariables> | TChildProps;
export function withSsoLogin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ISsoLoginMutation,
  ISsoLoginMutationVariables,
  ISsoLoginProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ISsoLoginMutation, ISsoLoginMutationVariables, ISsoLoginProps<TChildProps>>(SsoLoginDocument, {
      alias: 'ssoLogin',
      ...operationOptions
    });
};

/**
 * __useSsoLoginMutation__
 *
 * To run a mutation, you first call `useSsoLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSsoLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ssoLoginMutation, { data, loading, error }] = useSsoLoginMutation({
 *   variables: {
 *      Cert: // value for 'Cert'
 *   },
 * });
 */
export function useSsoLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISsoLoginMutation, ISsoLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<ISsoLoginMutation, ISsoLoginMutationVariables>(SsoLoginDocument, baseOptions);
      }
export type SsoLoginMutationHookResult = ReturnType<typeof useSsoLoginMutation>;
export type SsoLoginMutationResult = ApolloReactCommon.MutationResult<ISsoLoginMutation>;
export type SsoLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<ISsoLoginMutation, ISsoLoginMutationVariables>;
export const SaveOrderDocument = gql`
    mutation saveOrder($orderInput: SaveOrderInput!, $total: Float) {
  saveOrder(input: $orderInput, total: $total) {
    id
    date
    poNumber
    total
    parts {
      number
      quantity
    }
    orderType
    shippingMethod
    shippingMethodRaw
    freightCode
    city
    customer
    country
    address
    state
    zip
    savedAt
  }
}
    `;
export type ISaveOrderMutationFn = ApolloReactCommon.MutationFunction<ISaveOrderMutation, ISaveOrderMutationVariables>;
export type SaveOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ISaveOrderMutation, ISaveOrderMutationVariables>, 'mutation'>;

    export const SaveOrderComponent = (props: SaveOrderComponentProps) => (
      <ApolloReactComponents.Mutation<ISaveOrderMutation, ISaveOrderMutationVariables> mutation={SaveOrderDocument} {...props} />
    );
    
export type ISaveOrderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ISaveOrderMutation, ISaveOrderMutationVariables> | TChildProps;
export function withSaveOrder<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ISaveOrderMutation,
  ISaveOrderMutationVariables,
  ISaveOrderProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ISaveOrderMutation, ISaveOrderMutationVariables, ISaveOrderProps<TChildProps>>(SaveOrderDocument, {
      alias: 'saveOrder',
      ...operationOptions
    });
};

/**
 * __useSaveOrderMutation__
 *
 * To run a mutation, you first call `useSaveOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOrderMutation, { data, loading, error }] = useSaveOrderMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *      total: // value for 'total'
 *   },
 * });
 */
export function useSaveOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISaveOrderMutation, ISaveOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<ISaveOrderMutation, ISaveOrderMutationVariables>(SaveOrderDocument, baseOptions);
      }
export type SaveOrderMutationHookResult = ReturnType<typeof useSaveOrderMutation>;
export type SaveOrderMutationResult = ApolloReactCommon.MutationResult<ISaveOrderMutation>;
export type SaveOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<ISaveOrderMutation, ISaveOrderMutationVariables>;
export const SubmitOrderDocument = gql`
    mutation submitOrder($input: SubmitOrderInput!) {
  submitOrder(input: $input) {
    number
  }
}
    `;
export type ISubmitOrderMutationFn = ApolloReactCommon.MutationFunction<ISubmitOrderMutation, ISubmitOrderMutationVariables>;
export type SubmitOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ISubmitOrderMutation, ISubmitOrderMutationVariables>, 'mutation'>;

    export const SubmitOrderComponent = (props: SubmitOrderComponentProps) => (
      <ApolloReactComponents.Mutation<ISubmitOrderMutation, ISubmitOrderMutationVariables> mutation={SubmitOrderDocument} {...props} />
    );
    
export type ISubmitOrderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ISubmitOrderMutation, ISubmitOrderMutationVariables> | TChildProps;
export function withSubmitOrder<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ISubmitOrderMutation,
  ISubmitOrderMutationVariables,
  ISubmitOrderProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ISubmitOrderMutation, ISubmitOrderMutationVariables, ISubmitOrderProps<TChildProps>>(SubmitOrderDocument, {
      alias: 'submitOrder',
      ...operationOptions
    });
};

/**
 * __useSubmitOrderMutation__
 *
 * To run a mutation, you first call `useSubmitOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOrderMutation, { data, loading, error }] = useSubmitOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISubmitOrderMutation, ISubmitOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<ISubmitOrderMutation, ISubmitOrderMutationVariables>(SubmitOrderDocument, baseOptions);
      }
export type SubmitOrderMutationHookResult = ReturnType<typeof useSubmitOrderMutation>;
export type SubmitOrderMutationResult = ApolloReactCommon.MutationResult<ISubmitOrderMutation>;
export type SubmitOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<ISubmitOrderMutation, ISubmitOrderMutationVariables>;
export const UnfavoritePartDocument = gql`
    mutation unfavoritePart($partNumber: String!) {
  unfavoritePart(partNumber: $partNumber)
}
    `;
export type IUnfavoritePartMutationFn = ApolloReactCommon.MutationFunction<IUnfavoritePartMutation, IUnfavoritePartMutationVariables>;
export type UnfavoritePartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<IUnfavoritePartMutation, IUnfavoritePartMutationVariables>, 'mutation'>;

    export const UnfavoritePartComponent = (props: UnfavoritePartComponentProps) => (
      <ApolloReactComponents.Mutation<IUnfavoritePartMutation, IUnfavoritePartMutationVariables> mutation={UnfavoritePartDocument} {...props} />
    );
    
export type IUnfavoritePartProps<TChildProps = {}> = ApolloReactHoc.MutateProps<IUnfavoritePartMutation, IUnfavoritePartMutationVariables> | TChildProps;
export function withUnfavoritePart<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IUnfavoritePartMutation,
  IUnfavoritePartMutationVariables,
  IUnfavoritePartProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, IUnfavoritePartMutation, IUnfavoritePartMutationVariables, IUnfavoritePartProps<TChildProps>>(UnfavoritePartDocument, {
      alias: 'unfavoritePart',
      ...operationOptions
    });
};

/**
 * __useUnfavoritePartMutation__
 *
 * To run a mutation, you first call `useUnfavoritePartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfavoritePartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfavoritePartMutation, { data, loading, error }] = useUnfavoritePartMutation({
 *   variables: {
 *      partNumber: // value for 'partNumber'
 *   },
 * });
 */
export function useUnfavoritePartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUnfavoritePartMutation, IUnfavoritePartMutationVariables>) {
        return ApolloReactHooks.useMutation<IUnfavoritePartMutation, IUnfavoritePartMutationVariables>(UnfavoritePartDocument, baseOptions);
      }
export type UnfavoritePartMutationHookResult = ReturnType<typeof useUnfavoritePartMutation>;
export type UnfavoritePartMutationResult = ApolloReactCommon.MutationResult<IUnfavoritePartMutation>;
export type UnfavoritePartMutationOptions = ApolloReactCommon.BaseMutationOptions<IUnfavoritePartMutation, IUnfavoritePartMutationVariables>;
export const UpdateSavedOrderDocument = gql`
    mutation updateSavedOrder($orderId: String!, $orderInput: SaveOrderInput!, $total: Float) {
  updateSavedOrder(orderId: $orderId, input: $orderInput, total: $total) {
    id
    date
    poNumber
    total
    parts {
      number
      quantity
    }
    orderType
    shippingMethod
    shippingMethodRaw
    freightCode
    customer
    city
    country
    address
    state
    zip
    savedAt
  }
}
    `;
export type IUpdateSavedOrderMutationFn = ApolloReactCommon.MutationFunction<IUpdateSavedOrderMutation, IUpdateSavedOrderMutationVariables>;
export type UpdateSavedOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<IUpdateSavedOrderMutation, IUpdateSavedOrderMutationVariables>, 'mutation'>;

    export const UpdateSavedOrderComponent = (props: UpdateSavedOrderComponentProps) => (
      <ApolloReactComponents.Mutation<IUpdateSavedOrderMutation, IUpdateSavedOrderMutationVariables> mutation={UpdateSavedOrderDocument} {...props} />
    );
    
export type IUpdateSavedOrderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<IUpdateSavedOrderMutation, IUpdateSavedOrderMutationVariables> | TChildProps;
export function withUpdateSavedOrder<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IUpdateSavedOrderMutation,
  IUpdateSavedOrderMutationVariables,
  IUpdateSavedOrderProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, IUpdateSavedOrderMutation, IUpdateSavedOrderMutationVariables, IUpdateSavedOrderProps<TChildProps>>(UpdateSavedOrderDocument, {
      alias: 'updateSavedOrder',
      ...operationOptions
    });
};

/**
 * __useUpdateSavedOrderMutation__
 *
 * To run a mutation, you first call `useUpdateSavedOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedOrderMutation, { data, loading, error }] = useUpdateSavedOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      orderInput: // value for 'orderInput'
 *      total: // value for 'total'
 *   },
 * });
 */
export function useUpdateSavedOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateSavedOrderMutation, IUpdateSavedOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateSavedOrderMutation, IUpdateSavedOrderMutationVariables>(UpdateSavedOrderDocument, baseOptions);
      }
export type UpdateSavedOrderMutationHookResult = ReturnType<typeof useUpdateSavedOrderMutation>;
export type UpdateSavedOrderMutationResult = ApolloReactCommon.MutationResult<IUpdateSavedOrderMutation>;
export type UpdateSavedOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateSavedOrderMutation, IUpdateSavedOrderMutationVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    user {
      id
      email
      phone
      name
      dealer_id
      sales_org
    }
    favorites {
      number
      description
    }
    states
  }
}
    `;
export type CurrentUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ICurrentUserQuery, ICurrentUserQueryVariables>, 'query'>;

    export const CurrentUserComponent = (props: CurrentUserComponentProps) => (
      <ApolloReactComponents.Query<ICurrentUserQuery, ICurrentUserQueryVariables> query={CurrentUserDocument} {...props} />
    );
    
export type ICurrentUserProps<TChildProps = {}> = ApolloReactHoc.DataProps<ICurrentUserQuery, ICurrentUserQueryVariables> | TChildProps;
export function withCurrentUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ICurrentUserQuery,
  ICurrentUserQueryVariables,
  ICurrentUserProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ICurrentUserQuery, ICurrentUserQueryVariables, ICurrentUserProps<TChildProps>>(CurrentUserDocument, {
      alias: 'currentUser',
      ...operationOptions
    });
};

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ICurrentUserQuery, ICurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<ICurrentUserQuery, ICurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ICurrentUserQuery, ICurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ICurrentUserQuery, ICurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<ICurrentUserQuery, ICurrentUserQueryVariables>;
export const FavoritePartsDocument = gql`
    query favoriteParts {
  favoriteParts {
    total
    results {
      alternateFor
      ATPstockCR
      ATPstockSC
      ATPstockSH
      dealerPrice
      description
      grossWeight
      height
      inputNumber
      invoiceAmount
      invoiceDate
      invoiceItem
      invoiceNumber
      length
      masterCheckFlag
      notServiceFlag
      number
      payer
      price
      quantity
      soItem
      specialPrice
      trackingNumber
      weight
      weightUnit
      width
    }
  }
}
    `;
export type FavoritePartsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IFavoritePartsQuery, IFavoritePartsQueryVariables>, 'query'>;

    export const FavoritePartsComponent = (props: FavoritePartsComponentProps) => (
      <ApolloReactComponents.Query<IFavoritePartsQuery, IFavoritePartsQueryVariables> query={FavoritePartsDocument} {...props} />
    );
    
export type IFavoritePartsProps<TChildProps = {}> = ApolloReactHoc.DataProps<IFavoritePartsQuery, IFavoritePartsQueryVariables> | TChildProps;
export function withFavoriteParts<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IFavoritePartsQuery,
  IFavoritePartsQueryVariables,
  IFavoritePartsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, IFavoritePartsQuery, IFavoritePartsQueryVariables, IFavoritePartsProps<TChildProps>>(FavoritePartsDocument, {
      alias: 'favoriteParts',
      ...operationOptions
    });
};

/**
 * __useFavoritePartsQuery__
 *
 * To run a query within a React component, call `useFavoritePartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoritePartsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoritePartsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFavoritePartsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IFavoritePartsQuery, IFavoritePartsQueryVariables>) {
        return ApolloReactHooks.useQuery<IFavoritePartsQuery, IFavoritePartsQueryVariables>(FavoritePartsDocument, baseOptions);
      }
export function useFavoritePartsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFavoritePartsQuery, IFavoritePartsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IFavoritePartsQuery, IFavoritePartsQueryVariables>(FavoritePartsDocument, baseOptions);
        }
export type FavoritePartsQueryHookResult = ReturnType<typeof useFavoritePartsQuery>;
export type FavoritePartsLazyQueryHookResult = ReturnType<typeof useFavoritePartsLazyQuery>;
export type FavoritePartsQueryResult = ApolloReactCommon.QueryResult<IFavoritePartsQuery, IFavoritePartsQueryVariables>;
export const InvoiceUrlDocument = gql`
    query invoiceUrl($invoiceNumber: String!) {
  invoice(invoiceNumber: $invoiceNumber) {
    url
  }
}
    `;
export type InvoiceUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IInvoiceUrlQuery, IInvoiceUrlQueryVariables>, 'query'> & ({ variables: IInvoiceUrlQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InvoiceUrlComponent = (props: InvoiceUrlComponentProps) => (
      <ApolloReactComponents.Query<IInvoiceUrlQuery, IInvoiceUrlQueryVariables> query={InvoiceUrlDocument} {...props} />
    );
    
export type IInvoiceUrlProps<TChildProps = {}> = ApolloReactHoc.DataProps<IInvoiceUrlQuery, IInvoiceUrlQueryVariables> | TChildProps;
export function withInvoiceUrl<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IInvoiceUrlQuery,
  IInvoiceUrlQueryVariables,
  IInvoiceUrlProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, IInvoiceUrlQuery, IInvoiceUrlQueryVariables, IInvoiceUrlProps<TChildProps>>(InvoiceUrlDocument, {
      alias: 'invoiceUrl',
      ...operationOptions
    });
};

/**
 * __useInvoiceUrlQuery__
 *
 * To run a query within a React component, call `useInvoiceUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceUrlQuery({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *   },
 * });
 */
export function useInvoiceUrlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IInvoiceUrlQuery, IInvoiceUrlQueryVariables>) {
        return ApolloReactHooks.useQuery<IInvoiceUrlQuery, IInvoiceUrlQueryVariables>(InvoiceUrlDocument, baseOptions);
      }
export function useInvoiceUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IInvoiceUrlQuery, IInvoiceUrlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IInvoiceUrlQuery, IInvoiceUrlQueryVariables>(InvoiceUrlDocument, baseOptions);
        }
export type InvoiceUrlQueryHookResult = ReturnType<typeof useInvoiceUrlQuery>;
export type InvoiceUrlLazyQueryHookResult = ReturnType<typeof useInvoiceUrlLazyQuery>;
export type InvoiceUrlQueryResult = ApolloReactCommon.QueryResult<IInvoiceUrlQuery, IInvoiceUrlQueryVariables>;
export const OrderDetailsDocument = gql`
    query orderDetails($orderNumber: String!) {
  orderDetails(orderNumber: $orderNumber) {
    address
    consigneeCity
    consigneeCode
    currency
    customer
    date
    freightCode
    invoiceTotal
    number
    orderType
    poNumber
    shippingMethod
    state
    country
    status
    submittedAt
    zip
    parts {
      alternateFor
      ATPstockCR
      ATPstockSC
      ATPstockSH
      dealerPrice
      description
      dimensionUnit
      grossWeight
      height
      inputNumber
      invoiceAmount
      invoiceDate
      invoiceItem
      invoiceNumber
      length
      number
      payer
      price
      quantity
      soItem
      specialPrice
      trackingNumber
      weight
      weightUnit
      width
    }
    invoices {
      number
      trackingNumber
      issuedAt
      total
      parts {
        number
        price
        quantity
        alternateFor
      }
    }
  }
}
    `;
export type OrderDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IOrderDetailsQuery, IOrderDetailsQueryVariables>, 'query'> & ({ variables: IOrderDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrderDetailsComponent = (props: OrderDetailsComponentProps) => (
      <ApolloReactComponents.Query<IOrderDetailsQuery, IOrderDetailsQueryVariables> query={OrderDetailsDocument} {...props} />
    );
    
export type IOrderDetailsProps<TChildProps = {}> = ApolloReactHoc.DataProps<IOrderDetailsQuery, IOrderDetailsQueryVariables> | TChildProps;
export function withOrderDetails<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IOrderDetailsQuery,
  IOrderDetailsQueryVariables,
  IOrderDetailsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, IOrderDetailsQuery, IOrderDetailsQueryVariables, IOrderDetailsProps<TChildProps>>(OrderDetailsDocument, {
      alias: 'orderDetails',
      ...operationOptions
    });
};

/**
 * __useOrderDetailsQuery__
 *
 * To run a query within a React component, call `useOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsQuery({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *   },
 * });
 */
export function useOrderDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IOrderDetailsQuery, IOrderDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<IOrderDetailsQuery, IOrderDetailsQueryVariables>(OrderDetailsDocument, baseOptions);
      }
export function useOrderDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IOrderDetailsQuery, IOrderDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IOrderDetailsQuery, IOrderDetailsQueryVariables>(OrderDetailsDocument, baseOptions);
        }
export type OrderDetailsQueryHookResult = ReturnType<typeof useOrderDetailsQuery>;
export type OrderDetailsLazyQueryHookResult = ReturnType<typeof useOrderDetailsLazyQuery>;
export type OrderDetailsQueryResult = ApolloReactCommon.QueryResult<IOrderDetailsQuery, IOrderDetailsQueryVariables>;
export const SearchAlertsDocument = gql`
    query searchAlerts {
  searchAlerts {
    id
    title
    subtitle
  }
}
    `;
export type SearchAlertsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ISearchAlertsQuery, ISearchAlertsQueryVariables>, 'query'>;

    export const SearchAlertsComponent = (props: SearchAlertsComponentProps) => (
      <ApolloReactComponents.Query<ISearchAlertsQuery, ISearchAlertsQueryVariables> query={SearchAlertsDocument} {...props} />
    );
    
export type ISearchAlertsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ISearchAlertsQuery, ISearchAlertsQueryVariables> | TChildProps;
export function withSearchAlerts<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ISearchAlertsQuery,
  ISearchAlertsQueryVariables,
  ISearchAlertsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ISearchAlertsQuery, ISearchAlertsQueryVariables, ISearchAlertsProps<TChildProps>>(SearchAlertsDocument, {
      alias: 'searchAlerts',
      ...operationOptions
    });
};

/**
 * __useSearchAlertsQuery__
 *
 * To run a query within a React component, call `useSearchAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAlertsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchAlertsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ISearchAlertsQuery, ISearchAlertsQueryVariables>) {
        return ApolloReactHooks.useQuery<ISearchAlertsQuery, ISearchAlertsQueryVariables>(SearchAlertsDocument, baseOptions);
      }
export function useSearchAlertsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ISearchAlertsQuery, ISearchAlertsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ISearchAlertsQuery, ISearchAlertsQueryVariables>(SearchAlertsDocument, baseOptions);
        }
export type SearchAlertsQueryHookResult = ReturnType<typeof useSearchAlertsQuery>;
export type SearchAlertsLazyQueryHookResult = ReturnType<typeof useSearchAlertsLazyQuery>;
export type SearchAlertsQueryResult = ApolloReactCommon.QueryResult<ISearchAlertsQuery, ISearchAlertsQueryVariables>;
export const SearchOrdersDocument = gql`
    query searchOrders($days: Int, $status: OrderStatus, $search: String) {
  searchOrders(days: $days, status: $status, search: $search) {
    number
    status
    poNumber
    submittedAt
    invoiceTotal
    currency
    parts {
      alternateFor
      ATPstockCR
      ATPstockSC
      ATPstockSH
      dealerPrice
      description
      dimensionUnit
      grossWeight
      height
      inputNumber
      invoiceAmount
      invoiceDate
      invoiceItem
      invoiceNumber
      length
      number
      payer
      price
      quantity
      soItem
      specialPrice
      trackingNumber
      weight
      weightUnit
      width
    }
    invoices {
      number
      trackingNumber
      issuedAt
      total
      parts {
        number
        price
        quantity
        alternateFor
      }
    }
  }
}
    `;
export type SearchOrdersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ISearchOrdersQuery, ISearchOrdersQueryVariables>, 'query'>;

    export const SearchOrdersComponent = (props: SearchOrdersComponentProps) => (
      <ApolloReactComponents.Query<ISearchOrdersQuery, ISearchOrdersQueryVariables> query={SearchOrdersDocument} {...props} />
    );
    
export type ISearchOrdersProps<TChildProps = {}> = ApolloReactHoc.DataProps<ISearchOrdersQuery, ISearchOrdersQueryVariables> | TChildProps;
export function withSearchOrders<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ISearchOrdersQuery,
  ISearchOrdersQueryVariables,
  ISearchOrdersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ISearchOrdersQuery, ISearchOrdersQueryVariables, ISearchOrdersProps<TChildProps>>(SearchOrdersDocument, {
      alias: 'searchOrders',
      ...operationOptions
    });
};

/**
 * __useSearchOrdersQuery__
 *
 * To run a query within a React component, call `useSearchOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOrdersQuery({
 *   variables: {
 *      days: // value for 'days'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ISearchOrdersQuery, ISearchOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<ISearchOrdersQuery, ISearchOrdersQueryVariables>(SearchOrdersDocument, baseOptions);
      }
export function useSearchOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ISearchOrdersQuery, ISearchOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ISearchOrdersQuery, ISearchOrdersQueryVariables>(SearchOrdersDocument, baseOptions);
        }
export type SearchOrdersQueryHookResult = ReturnType<typeof useSearchOrdersQuery>;
export type SearchOrdersLazyQueryHookResult = ReturnType<typeof useSearchOrdersLazyQuery>;
export type SearchOrdersQueryResult = ApolloReactCommon.QueryResult<ISearchOrdersQuery, ISearchOrdersQueryVariables>;
export const SearchPartsDocument = gql`
    query searchParts($parts: [String]!, $exact: Boolean) {
  searchParts(parts: $parts, exact: $exact) {
    alternateFor
    ATPstockCR
    ATPstockSC
    ATPstockSH
    dealerPrice
    description
    dimensionUnit
    grossWeight
    height
    inputNumber
    invoiceAmount
    invoiceDate
    invoiceItem
    invoiceNumber
    length
    masterCheckFlag
    notServiceFlag
    number
    payer
    price
    quantity
    soItem
    specialPrice
    trackingNumber
    weight
    weightUnit
    width
  }
}
    `;
export type SearchPartsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ISearchPartsQuery, ISearchPartsQueryVariables>, 'query'> & ({ variables: ISearchPartsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchPartsComponent = (props: SearchPartsComponentProps) => (
      <ApolloReactComponents.Query<ISearchPartsQuery, ISearchPartsQueryVariables> query={SearchPartsDocument} {...props} />
    );
    
export type ISearchPartsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ISearchPartsQuery, ISearchPartsQueryVariables> | TChildProps;
export function withSearchParts<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ISearchPartsQuery,
  ISearchPartsQueryVariables,
  ISearchPartsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ISearchPartsQuery, ISearchPartsQueryVariables, ISearchPartsProps<TChildProps>>(SearchPartsDocument, {
      alias: 'searchParts',
      ...operationOptions
    });
};

/**
 * __useSearchPartsQuery__
 *
 * To run a query within a React component, call `useSearchPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPartsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPartsQuery({
 *   variables: {
 *      parts: // value for 'parts'
 *      exact: // value for 'exact'
 *   },
 * });
 */
export function useSearchPartsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ISearchPartsQuery, ISearchPartsQueryVariables>) {
        return ApolloReactHooks.useQuery<ISearchPartsQuery, ISearchPartsQueryVariables>(SearchPartsDocument, baseOptions);
      }
export function useSearchPartsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ISearchPartsQuery, ISearchPartsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ISearchPartsQuery, ISearchPartsQueryVariables>(SearchPartsDocument, baseOptions);
        }
export type SearchPartsQueryHookResult = ReturnType<typeof useSearchPartsQuery>;
export type SearchPartsLazyQueryHookResult = ReturnType<typeof useSearchPartsLazyQuery>;
export type SearchPartsQueryResult = ApolloReactCommon.QueryResult<ISearchPartsQuery, ISearchPartsQueryVariables>;
export const SearchSavedOrdersDocument = gql`
    query searchSavedOrders($poNumber: String) {
  searchSavedOrders(poNumber: $poNumber) {
    date
    id
    total
    poNumber
    parts {
      alternateFor
      ATPstockCR
      ATPstockSC
      ATPstockSH
      dealerPrice
      description
      grossWeight
      height
      inputNumber
      invoiceAmount
      invoiceDate
      invoiceItem
      invoiceNumber
      length
      masterCheckFlag
      notServiceFlag
      number
      payer
      price
      quantity
      soItem
      specialPrice
      trackingNumber
      weight
      weightUnit
      width
    }
    address
    country
    consigneeCode
    city
    customer
    freightCode
    orderType
    savedAt
    shippingMethod
    shippingMethodRaw
    state
    zip
  }
}
    `;
export type SearchSavedOrdersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ISearchSavedOrdersQuery, ISearchSavedOrdersQueryVariables>, 'query'>;

    export const SearchSavedOrdersComponent = (props: SearchSavedOrdersComponentProps) => (
      <ApolloReactComponents.Query<ISearchSavedOrdersQuery, ISearchSavedOrdersQueryVariables> query={SearchSavedOrdersDocument} {...props} />
    );
    
export type ISearchSavedOrdersProps<TChildProps = {}> = ApolloReactHoc.DataProps<ISearchSavedOrdersQuery, ISearchSavedOrdersQueryVariables> | TChildProps;
export function withSearchSavedOrders<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ISearchSavedOrdersQuery,
  ISearchSavedOrdersQueryVariables,
  ISearchSavedOrdersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ISearchSavedOrdersQuery, ISearchSavedOrdersQueryVariables, ISearchSavedOrdersProps<TChildProps>>(SearchSavedOrdersDocument, {
      alias: 'searchSavedOrders',
      ...operationOptions
    });
};

/**
 * __useSearchSavedOrdersQuery__
 *
 * To run a query within a React component, call `useSearchSavedOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSavedOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSavedOrdersQuery({
 *   variables: {
 *      poNumber: // value for 'poNumber'
 *   },
 * });
 */
export function useSearchSavedOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ISearchSavedOrdersQuery, ISearchSavedOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<ISearchSavedOrdersQuery, ISearchSavedOrdersQueryVariables>(SearchSavedOrdersDocument, baseOptions);
      }
export function useSearchSavedOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ISearchSavedOrdersQuery, ISearchSavedOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ISearchSavedOrdersQuery, ISearchSavedOrdersQueryVariables>(SearchSavedOrdersDocument, baseOptions);
        }
export type SearchSavedOrdersQueryHookResult = ReturnType<typeof useSearchSavedOrdersQuery>;
export type SearchSavedOrdersLazyQueryHookResult = ReturnType<typeof useSearchSavedOrdersLazyQuery>;
export type SearchSavedOrdersQueryResult = ApolloReactCommon.QueryResult<ISearchSavedOrdersQuery, ISearchSavedOrdersQueryVariables>;
export const GetShippingMethodsDocument = gql`
    query GetShippingMethods {
  shippingMethods {
    SalesOffice
    SOType
    ConsigneeCode
    ShippingMethod
    ConsigneeName1
    ConsigneeName2
    ConsigneeStreet
    ConsigneeCity
    ConsigneeDistrict
    ConsigneeState
    ConsigneePostalCode
    ConsigneeCountry
    ChangeFlag
  }
}
    `;
export type GetShippingMethodsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IGetShippingMethodsQuery, IGetShippingMethodsQueryVariables>, 'query'>;

    export const GetShippingMethodsComponent = (props: GetShippingMethodsComponentProps) => (
      <ApolloReactComponents.Query<IGetShippingMethodsQuery, IGetShippingMethodsQueryVariables> query={GetShippingMethodsDocument} {...props} />
    );
    
export type IGetShippingMethodsProps<TChildProps = {}> = ApolloReactHoc.DataProps<IGetShippingMethodsQuery, IGetShippingMethodsQueryVariables> | TChildProps;
export function withGetShippingMethods<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IGetShippingMethodsQuery,
  IGetShippingMethodsQueryVariables,
  IGetShippingMethodsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, IGetShippingMethodsQuery, IGetShippingMethodsQueryVariables, IGetShippingMethodsProps<TChildProps>>(GetShippingMethodsDocument, {
      alias: 'getShippingMethods',
      ...operationOptions
    });
};

/**
 * __useGetShippingMethodsQuery__
 *
 * To run a query within a React component, call `useGetShippingMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShippingMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShippingMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShippingMethodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetShippingMethodsQuery, IGetShippingMethodsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetShippingMethodsQuery, IGetShippingMethodsQueryVariables>(GetShippingMethodsDocument, baseOptions);
      }
export function useGetShippingMethodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetShippingMethodsQuery, IGetShippingMethodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetShippingMethodsQuery, IGetShippingMethodsQueryVariables>(GetShippingMethodsDocument, baseOptions);
        }
export type GetShippingMethodsQueryHookResult = ReturnType<typeof useGetShippingMethodsQuery>;
export type GetShippingMethodsLazyQueryHookResult = ReturnType<typeof useGetShippingMethodsLazyQuery>;
export type GetShippingMethodsQueryResult = ApolloReactCommon.QueryResult<IGetShippingMethodsQuery, IGetShippingMethodsQueryVariables>;