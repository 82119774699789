import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import ResponsiveHandler from '../ResponsiveHandler';

const Item = ResponsiveHandler(styled.div`
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-size: 0.8rem;
  line-height: 0.9rem;
`);

interface PartItemContainerProps {
  alternate?: boolean;
}

const PartItemContainer = styled(Item)<PartItemContainerProps>`
  display: ${({ alternate }) => (alternate ? 'flex' : 'block')};
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.9rem;
`;
const PartText = styled.div`
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-size: 0.8rem;
  line-height: 0.9rem;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
`;
const AltPartText = styled.div`
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.blueNeutral};
  font-size: 0.8rem;
  line-height: 0.9rem;
`;

interface PartItemProps {
  partNumber: string;
  alternateFor?: string;
}

const PartItem: FunctionComponent<PartItemProps> = ({
  partNumber,
  alternateFor,
}) => {
  const alternate = !!alternateFor;
  return (
    <PartItemContainer size="40%" alternate={alternate}>
      {alternate && (
        <AltPartText>New Part Number for: {alternateFor}</AltPartText>
      )}
      <PartText>{partNumber}</PartText>
    </PartItemContainer>
  );
};

export default PartItem;
