import React, { FunctionComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import SignIn from './pages/SignIn';
import NewOrders from './pages/NewOrders';
import SavedCarts from './pages/SavedCarts';
import OpenOrders from './pages/OpenOrders';
import AllOrders from './pages/AllOrders';

import AuthenticatedRoute from './utils/AuthenticatedRoute';
// import RequestLoginPage from './pages/RequestLoginPage';
// import RequestLoginSuccessPage from './pages/RequestLoginSuccessPage';

const Routes: FunctionComponent = () => (
  <Switch>
    <Route path="/signin" component={SignIn} />
    {/* <Route path="/request-login" component={RequestLoginPage} /> */}
    {/* <Route path="/request-login-success" component={RequestLoginSuccessPage} /> */}
    <Route path="/new-order" component={AuthenticatedRoute(NewOrders)} />
    <Route path="/saved-carts" component={AuthenticatedRoute(SavedCarts)} />
    <Route path="/open-orders" component={AuthenticatedRoute(OpenOrders)} />
    <Route path="/all-orders" component={AuthenticatedRoute(AllOrders)} />
    <Redirect from="*" to="/signin" />
  </Switch>
);

export default Routes;
