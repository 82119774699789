import { setUser } from '../store/user';

export interface User {
  dealer_id: string;
  dealerType?: string;
  email?: string;
  id: string;
  name: string;
  token?: string;
  sales_org?: string | null;
}

export function saveToken(token: string): void {
  localStorage.setItem('token', token);
}

export function getToken(): string | null {
  return localStorage.getItem('token');
}

export function saveUser(user: Partial<User>): void {
  localStorage.setItem('user', JSON.stringify(user));
  setUser(user);
}

export function getUser(): User | null {
  const user = localStorage.getItem('user');
  if (user) {
    try {
      return JSON.parse(user);
    } catch (_e) {
      // pass
    }
  }
  return null;
}

export function clearSession(): void {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('alerts');
}

export function saveAlerts(alertid: string): void {
  const alerts = getAlerts() || [];
  alerts.push(alertid);
  localStorage.setItem('alerts', JSON.stringify(alerts));
}

export function getAlerts(): Array<string> | null {
  const alerts = localStorage.getItem('alerts');
  if (alerts) {
    try {
      return JSON.parse(alerts);
    } catch (_e) {
      //pass
    }
  }
  return null;
}

export default {
  saveToken,
  clearSession,
  getToken,
  saveUser,
  saveAlerts,
};
