/* eslint-disable @typescript-eslint/indent */
import styled from 'styled-components';
import React, { FunctionComponent } from 'react';

import {
  useFavoritePartMutation,
  useUnfavoritePartMutation,
} from '../../generated/graphql';
import useWindowSize from '../../hooks/useWindowSize';

import theme from '../../constants/theme';
import StarSwitch from '../Inputs/StarSwitch';

import ToRGB from '../../utils/color';
import formatters from '../../utils/formatters';

const PartItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PartItemRow = styled.div<{ stockHasChanged?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.8rem;
  box-sizing: border-box;
  padding: ${({ stockHasChanged }) =>
    stockHasChanged ? '0.4rem 0' : undefined};
  border: ${({ theme: { colors }, stockHasChanged }) =>
    stockHasChanged ? `0.1rem solid ${colors.blueDark}` : undefined};
`;

const { r, g, b } = ToRGB(theme.colors.blueNeutral);
const alternatePartColor = `rgba(${r}, ${g}, ${b}, 0.5)`;

const PartItemCell = styled.div<{
  flex: number;
  isPartNumber?: boolean;
  isAlternatePart?: boolean;
  align: 'flex-start' | 'flex-end' | 'center';
}>`
  display: flex;
  font-size: 1rem;
  min-height: 3.4rem;
  line-height: normal;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  flex: ${({ flex }) => flex};
  align-items: ${({ align }) => align};
  font-family: ${({ theme: { typography } }) => typography.museo};
  padding: ${({ isAlternatePart }) =>
    isAlternatePart ? '0.4rem 0.4rem' : '1rem 0.4rem'};
  color: ${({ theme: { colors }, isPartNumber }) =>
    isPartNumber ? colors.blueDark : colors.grayDark};
  background-color: ${({ theme: { colors }, isPartNumber }) =>
    isPartNumber ? colors.grayLight : 'transparent'};
  border-left: 0.4rem solid
    ${({ isAlternatePart }) =>
      isAlternatePart ? alternatePartColor : 'transparent'};
`;

const PartAlternateFor = styled.span`
  font-size: 1rem;
  margin-top: 0.2rem;
  font-style: italic;
  line-height: normal;
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const StockHasChangedLabel = styled.div`
  width: auto;
  font-size: 0.8rem;
  line-height: normal;
  padding: 0.2rem 0.4rem;
  color: ${({ theme: { colors } }) => colors.white};
  background-color: ${({ theme: { colors } }) => colors.blueDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const MobilePartItemContainer = styled.div<{ isAlternatePart?: boolean }>`
  margin-left: -0.8rem;
  padding: 0.8rem 0 0.8rem 0.4rem;
  border-bottom: 0.1rem solid
    ${({ theme: { colors } }) => colors.blueUltraLight};
  border-left: 0.4rem solid
    ${({ isAlternatePart }) =>
      isAlternatePart ? alternatePartColor : 'transparent'};
`;

const MobilePartItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const MobilePartItemAction = styled.div`
  flex: 0.625;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const MobilePartNumber = styled.span`
  flex: 2.5;
  font-size: 1rem;
  line-height: normal;
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const MobilePartTrait = styled.span`
  flex: 0.265;
  font-size: 1rem;
  line-height: normal;
  color: ${({ theme: { colors } }) => colors.grayDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const MobilePartUnitCost = styled.span`
  font-size: 1rem;
  line-height: normal;
  color: ${({ theme: { colors } }) => colors.grayADA};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const MobilePartAlternateFor = styled.span`
  font-size: 1rem;
  font-style: italic;
  line-height: normal;
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const EditPartButton = styled.span`
  cursor: pointer;
  font-size: 1rem;
  line-height: normal;
  color: ${({ theme: { colors } }) => colors.blueKobelco};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

interface PartItemProps {
  alternateFor?: string;
  isFavorite?: boolean;
  isLocked?: boolean;
  onEdit?: () => void;
  part: any;
  partNumber: string;
  qty: number;
  stockHasChanged?: boolean;
  unitCost: number;
}

const PartItem: FunctionComponent<PartItemProps> = ({
  alternateFor,
  isFavorite = false,
  isLocked = false,
  onEdit = () => {},
  part,
  partNumber,
  qty,
  stockHasChanged = false,
  unitCost,
}) => {
  const [favoriteMutation] = useFavoritePartMutation({
    variables: { partNumber },
    optimisticResponse: {
      __typename: 'Mutation',
      favoritePart: {
        __typename: 'Part',
        number: partNumber,
        // Pass part in so Apollo doesn't complain about missing fields
        ...part,
      },
    },
    refetchQueries: ['currentUser', 'favoriteParts'],
  });
  const [unfavoriteMutation] = useUnfavoritePartMutation({
    variables: { partNumber },
    refetchQueries: ['currentUser', 'favoriteParts'],
  });

  const { width } = useWindowSize();
  const mobileBreakpoint = Number(theme.breakpoints.mobile.replace('px', ''));

  const isAltPartNumber = !!alternateFor;
  const formattedUnitCost = formatters.currency(unitCost);
  const formattedTotalCost = formatters.currency(qty * unitCost);
  const mutation = isFavorite ? unfavoriteMutation : favoriteMutation;

  if (width && width < mobileBreakpoint) {
    return (
      <MobilePartItemContainer isAlternatePart={isAltPartNumber}>
        <MobilePartItemRow>
          <MobilePartNumber>{partNumber}</MobilePartNumber>
          <MobilePartTrait>{qty}</MobilePartTrait>
          <MobilePartTrait>{formattedTotalCost}</MobilePartTrait>
          <MobilePartItemAction>
            <StarSwitch isFavorite={isFavorite} onClick={() => mutation()} />
          </MobilePartItemAction>
          {!isLocked && (
            <MobilePartItemAction>
              <EditPartButton onClick={onEdit}>Edit</EditPartButton>
            </MobilePartItemAction>
          )}
        </MobilePartItemRow>
        {isAltPartNumber && (
          <MobilePartItemRow style={{ marginTop: '0.4rem' }}>
            <MobilePartAlternateFor>
              New Part Number for: {alternateFor}
            </MobilePartAlternateFor>
          </MobilePartItemRow>
        )}
        <MobilePartItemRow style={{ marginTop: '0.2rem' }}>
          <MobilePartUnitCost>{formattedUnitCost}/unit</MobilePartUnitCost>
        </MobilePartItemRow>
      </MobilePartItemContainer>
    );
  }

  return (
    <PartItemContainer>
      {stockHasChanged && (
        <StockHasChangedLabel>Stock has changed</StockHasChangedLabel>
      )}
      <PartItemRow stockHasChanged={stockHasChanged}>
        <PartItemCell
          flex={2}
          isPartNumber
          align="flex-start"
          isAlternatePart={isAltPartNumber}
        >
          {partNumber}
          {isAltPartNumber && (
            <PartAlternateFor>
              New Part Number for: {alternateFor}
            </PartAlternateFor>
          )}
        </PartItemCell>
        <PartItemCell flex={1} align="flex-start">
          {formattedUnitCost}
        </PartItemCell>
        <PartItemCell flex={1} align="flex-start">
          {qty}
        </PartItemCell>
        <PartItemCell flex={1} align="flex-start">
          {formattedTotalCost}
        </PartItemCell>
        <PartItemCell flex={0.5} align="center">
          <StarSwitch isFavorite={isFavorite} onClick={() => mutation()} />
        </PartItemCell>
        <PartItemCell flex={0.5} align="flex-end">
          {isLocked ? null : (
            <EditPartButton onClick={onEdit}>Edit</EditPartButton>
          )}
        </PartItemCell>
      </PartItemRow>
    </PartItemContainer>
  );
};

export default PartItem;
