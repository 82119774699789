import { entity } from 'simpler-state';
import { User } from '../utils/localStorage';

export const userStore = entity<User | null>(null);

export const unsetUser = () => {
  userStore.set(null);
};

export const setUser = (userObj: Partial<User>) => {
  userStore.set(userObj as User);
};
