import to from 'await-to-js';
import styled from 'styled-components';

import { useHistory, Link } from 'react-router-dom';
import React, { FunctionComponent, useState, useEffect } from 'react';

import { useLoginMutation, useSsoLoginMutation } from '../generated/graphql';

import Spinner from '../components/Spinner';
import withLayout from '../components/withLayout';
import TextField from '../components/Inputs/TextField';
import { PrimaryButton } from '../components/styled/buttons';

import utils from '../utils';

import { ReactComponent as chevron } from '../assets/images/chevron.svg';
import constants from '../constants';
import formatters from '../utils/formatters';

const LoginButton = styled(PrimaryButton)`
  width: calc(-0.55rem + 50%);
  padding: 1.1rem;
  margin-bottom: 2.4rem;
  font-size: 1.5rem;
  line-height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginFormContainer = styled.div`
  width: 27.1rem;
`;

const LoginTitle = styled.h1`
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-weight: 400;
  font-size: 2.3rem;
  line-height: 2.3rem;
  margin-bottom: 2.1rem;
`;
const LoginButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Line = styled.div`
  width: 100%;
  border-width: 0.1rem;
  border-color: ${({ theme: { colors } }) => colors.grayLight};
  border-style: solid;
  margin-bottom: 0.7rem;
`;

const FormLink = styled.a`
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.blueKobelco};
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 1.3rem;
  line-height: 1.6rem;
`;

const InputContainer = styled.div`
  margin-bottom: 1.6rem;
`;

const Chevron = styled(chevron)`
  height: 1rem;
  transform: rotate(-90deg);
`;

const SignIn: FunctionComponent = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const [errors, setErrors] = useState<{
    username?: string;
    password?: string;
  }>({
    username: undefined,
    password: undefined,
  });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const cert = query.get('cert');
    cert && ssoLogin(cert);
  }, []);

  useEffect(() => {
    const token = utils.storage.getToken();
    if (token) {
      history.replace('/new-order');
    }
  }, [history]);

  const [loginMutation, { loading }] = useLoginMutation({
    variables: { id: username, password },
  });

  const [ssoLoginMutation, { loading: ssoLoading }] = useSsoLoginMutation();

  const ssoLogin = async (Cert: string) => {
    const [error, res] = await to(ssoLoginMutation({ variables: { Cert } }));
    if (error) {
      const errorMsg = formatters.error(error);
      utils.toast.show(errorMsg, 'error');
    } else if (
      res &&
      res.data &&
      res.data.ssoLogin &&
      res.data.ssoLogin.token
    ) {
      utils.storage.saveUser(res.data.ssoLogin.user);
      utils.storage.saveToken(res.data.ssoLogin.token);
      history.replace('/new-order');
    } else {
      utils.toast.show('Failed to Log In', 'error');
    }
    history.replace('/signin');
  };

  const submitHandler = async (e: React.FormEvent) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (!username || !password) {
      setErrors({
        password: !password ? 'Password is required' : undefined,
        username: !username ? 'Username is required' : undefined,
      });
      return;
    }
    const [error, res] = await to(loginMutation());

    if (error) {
      const errorMsg = formatters.error(error);
      utils.toast.show(errorMsg, 'error');
    } else if (res && res.data && res.data.login && res.data.login.token) {
      utils.storage.saveUser(res.data.login.user);
      utils.storage.saveToken(res.data.login.token);
      history.replace('/new-order');
    } else {
      utils.toast.show('Failed to Log In', 'error');
    }
  };

  return (
    <>
      {ssoLoading ? (
        <LoginForm>
          <Spinner size="large" color={`#009FB3`} />
        </LoginForm>
      ) : (
        <LoginForm onSubmit={submitHandler}>
          <LoginFormContainer>
            <LoginTitle>Log In</LoginTitle>
            <InputContainer>
              <TextField
                medium
                type="text"
                name="username"
                label="Username"
                value={username}
                autoCapitalize="none"
                errorMessage={errors.username}
                onChange={({ target: { value } }) => setUsername(value)}
              />
            </InputContainer>
            <InputContainer>
              <TextField
                medium
                type="password"
                name="password"
                label="Password"
                value={password}
                autoCapitalize="none"
                errorMessage={errors.password}
                onChange={({ target: { value } }) => setPassword(value)}
              />
            </InputContainer>
            <LoginButtonContainer>
              <LoginButton disabled={loading}>
                {loading ? <Spinner /> : 'Log In'}
              </LoginButton>
            </LoginButtonContainer>
            <Line />
            <div>
              <FormLink
                as="a"
                target="_blank"
                href={constants.configs.PASSWORD_RESET}
              >
                Forgot Password <Chevron />
              </FormLink>
            </div>
            {/* <div>
              <FormLink as={Link} to={'/request-login'}>
                Request Kobelco Parts Login <Chevron />
              </FormLink>
            </div> */}
          </LoginFormContainer>
        </LoginForm>
      )}
    </>
  );
};

export default withLayout(SignIn);
