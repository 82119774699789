import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Spinner from '../Spinner';
import theme from '../../constants/theme';

const LoaderContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;
const Loader: FunctionComponent = () => {
  return (
    <LoaderContainer>
      <Spinner size="medium" color={theme.colors.blueKobelco} />
    </LoaderContainer>
  );
};

export default Loader;
