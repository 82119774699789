import styled from 'styled-components';
import React, { FunctionComponent } from 'react';

import { Row } from '../PartRow';
import InvoiceItem from '../InvoiceItem';

import constants from '../../constants';
import useWindowSize from '../../hooks/useWindowSize';
import { ColumnTitle } from '../InvoiceItem/styled';

const Container = styled.div`
  width: 100%;
  margin-top: 1.6rem;
  margin-bottom: 4rem;
`;

const InvoicesTitle = styled.span`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.typography.museo};
`;

interface InvoiceListProps {
  order: any;
}

const InvoiceList: FunctionComponent<InvoiceListProps> = ({ order }) => {
  const invoices: any[] = order;

  const { width } = useWindowSize();
  const mobileBreakpoint = Number(
    constants.theme.breakpoints.mobile.replace('px', ''),
  );

  return (
    <Container>
      <InvoicesTitle>Invoices</InvoicesTitle>
      {width && width >= mobileBreakpoint && (
        <Row verPadding={0.4} style={{ marginTop: '1.6rem' }}>
          <ColumnTitle flex={1}>Invoice No.</ColumnTitle>
          <ColumnTitle flex={1}>Tracking No.</ColumnTitle>
          <ColumnTitle flex={1}>Date Issued</ColumnTitle>
          <ColumnTitle flex={3}>Invoice Total</ColumnTitle>
        </Row>
      )}
      {invoices.map(invoice => (
        <InvoiceItem
          order={order}
          invoice={invoice}
          invoiceParts={invoice.parts}
          key={`invoice-${invoice.invoiceNumber}`}
        />
      ))}
    </Container>
  );
};

export default InvoiceList;
