import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button';
import ResponsiveHandler from '../ResponsiveHandler';
import { FlatButtonColor } from '../styled/buttons';
import theme from '../../constants/theme';
import { ReactComponent as MeatballIcon } from '../../assets/images/meatballs.svg';
import formatters from '../../utils/formatters';

interface SelectedProps {
  selected?: boolean;
}

const Container = styled.div<SelectedProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.1rem 0;
  transform: translate3d(0, 0, 0);
  background-color: ${({ theme: { colors } }) => colors.white};
  ${up('mobile')} {
    background-color: ${({ theme: { colors }, selected }) =>
      selected ? colors.grayLight : colors.white};
  }
  border-bottom: 0.1rem solid ${({ theme: { colors } }) => colors.grayLight};
`;

const TableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
`;

const ViewOrder = styled(FlatButtonColor)`
  padding: 0.8rem 1.7rem;
  font-weight: light;
  font-size: 1.2rem;
`;

interface TableEntryProps {
  primary?: boolean;
  noPadding?: boolean;
  right?: boolean;
}

const TableEntry = ResponsiveHandler(styled.div<TableEntryProps>`
  font-size: ${({ right }) => (right ? '1.2rem' : '1rem')};
  padding-top: ${({ noPadding }) => (noPadding ? '0' : '0.8rem')};
  padding-bottom: ${({ noPadding }) => (noPadding ? '0' : '0.8rem')};
  line-height: 0.7rem;
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors }, primary }) =>
    primary ? colors.blueDark : colors.grayDark};
  text-transform: ${({ right }) => (right ? 'none' : 'none')};
  display: flex;
  align-items: center;
  justify-content: ${({ right }) => (right ? 'flex-end' : 'flex-start')};
  word-break: break-word;
  line-height: 1.2;
  padding-right: 20px;
`);

const CurrentlyViewing = styled.div`
  font-style: italic;
  height: 3.1rem;
  display: flex;
  line-height: normal;
  align-items: center;
`;

const ActionContainer = styled.div`
  margin-left: 2rem;
`;

const DropdownButton = styled(MenuButton)`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

const DropdownItem = styled(MenuItem)`
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-size: 1.2rem;
  &:hover {
    background: ${({ theme: { colors } }) => colors.blueDark};
    border: ${({ theme: { colors } }) => colors.blueDark};
    color: ${({ theme: { colors } }) => colors.white};
  }
`;

const DropdownListContainer = styled(MenuList)`
  padding: 0;
`;

interface OrderItemProps {
  order: {
    number: string;
    poNumber: string;
    submittedAt: string;
    total: number;
    status: string;
    savedAt?: number;
  };
  selected?: boolean;
  onView?: () => void;
  displayInvoice?: boolean;
  isSavedOrders?: boolean;
  actions?: Array<{
    label: string;
    method: any;
  }>;
}

const OrderItem: FunctionComponent<OrderItemProps> = ({
  order,
  actions = null,
  selected = false,
  displayInvoice = true,
  isSavedOrders = false,
  onView = () => {},
}) => (
  <Container selected={selected}>
    <TableContainer>
      {!isSavedOrders ? (
        <TableEntry primary size="24%" mobileSize="50%">
          {order.number || '--'}
        </TableEntry>
      ) : null}
      <TableEntry primary desktop size={isSavedOrders ? '32%' : '20%'}>
        {order.poNumber || '--'}
      </TableEntry>
      <TableEntry size="15%">{order.submittedAt || order.savedAt}</TableEntry>
      {isSavedOrders && (
        <TableEntry size="12.5%">
          {order.total ? formatters.currency(order.total) : '--'}
        </TableEntry>
      )}
      {!isSavedOrders ? (
        <TableEntry size="12.5%">
          {order.status === '1'
            ? 'Open'
            : `Closed ($${Number(order.total || 0).toFixed(2)})`}
        </TableEntry>
      ) : null}
      <TableEntry right noPadding desktop size="28%">
        {selected ? (
          <CurrentlyViewing>Currently Viewing</CurrentlyViewing>
        ) : (
          <ViewOrder
            onClick={onView}
            color={theme.colors.blueDark}
            hoverColor={theme.colors.blueDark}
            borderColor={theme.colors.blueDark}
          >
            View Order
          </ViewOrder>
        )}
        {actions ? (
          <ActionContainer>
            <Menu>
              <DropdownButton>
                <MeatballIcon />
              </DropdownButton>
              <DropdownListContainer>
                {actions.map(action => (
                  <DropdownItem
                    key={`action-${action.label}`}
                    onSelect={() => action.method(order.number)}
                  >
                    {action.label}
                  </DropdownItem>
                ))}
              </DropdownListContainer>
            </Menu>
          </ActionContainer>
        ) : null}
      </TableEntry>
    </TableContainer>
  </Container>
);

export default OrderItem;
