import React, { FunctionComponent } from 'react';

import styled from 'styled-components';

const Text = styled.h1`
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: normal;
  font-style: normal;
  color: ${({ theme: { colors } }) => colors.blueDark};
`;

interface TitleProps {
  label?: string;
}

const Title: FunctionComponent<TitleProps> = ({ label = '' }) => (
  <Text>{label}</Text>
);

export default Title;
