import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { PrimaryButton, TertiaryButton } from '../styled/buttons';
import ResponsiveHandler from '../ResponsiveHandler';
// import ToRGB from '../../utils/color';
import { ReactComponent as ChevronIcon } from '../../assets/images/chevron.svg';
import InventoryItem from './InventoryItem';
import InventoryStatusModal from './InventoryStatusModal';

import InventoryStatusMobile from './InventoryStatusMobile';
import useUser from '../../hooks/useUser';
import { dealerTypes } from '../../constants';

export { InventoryStatusMobile };

interface InventoryStatusBackgroundProps {
  show?: boolean;
}

export const WPadding = styled.div`
  padding-right: 3.2rem;
  padding-left: 3.2rem;
`;

export const Item = ResponsiveHandler(styled.div`
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-size: 0.8rem;
  line-height: 0.9rem;
`);

const ChevronLeft = styled(ChevronIcon)`
  transform: rotate(90deg);
`;

const EditPart = styled(TertiaryButton)`
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
`;
const Continue = styled(PrimaryButton)`
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
`;

// const InventoryStatusBackground = styled.div<InventoryStatusBackgroundProps>`
//   position: absolute;
//   z-index: 10000000;
//   width: 100%;
//   height: 100%;
//   display: ${({ show }) => (show ? 'flex' : 'none')};
//   justify-content: center;
//   align-items: center;
//   background-color: ${({ theme: { colors } }) => {
//     const { r, g, b } = ToRGB(colors.blueKobelco);
//     return `rgba(${r},${g},${b}, 0.85)`;
//   }};
// `;

const InventoryStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const InventoryTitle = styled(WPadding)`
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding-top: 3.1rem;
  padding-bottom: 1.3rem;
  color: ${({ theme: { colors } }) => colors.black};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const InventoryDescription = styled(WPadding)`
  font-size: 1rem;
  line-height: 1.3rem;
  margin-bottom: 3.7rem;
  color: ${({ theme: { colors } }) => colors.grayDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const InventoryListHeaderContainer = styled(WPadding)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: ${({ theme: { colors } }) =>
    `0.1rem solid ${colors.whiteSmoke}`};
`;

const InventoryListHeaderItem = ResponsiveHandler(styled.div`
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-size: 0.6rem;
  line-height: 0.7rem;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  color: ${({ theme: { colors } }) => colors.blueNeutral};
`);

const InventoryListHeader: FunctionComponent = () => {
  const user = useUser();
  return (
    <InventoryListHeaderContainer>
      <InventoryListHeaderItem size="40%">Part</InventoryListHeaderItem>
      <InventoryListHeaderItem size="27%">Description</InventoryListHeaderItem>
      {user?.dealerType === dealerTypes.excavator ? (
        <>
          <InventoryListHeaderItem size="10%">
            Unit Price
          </InventoryListHeaderItem>
          <InventoryListHeaderItem size="10%">
            D-NET Price
          </InventoryListHeaderItem>
        </>
      ) : (
        <InventoryListHeaderItem size="20%">Unit Price</InventoryListHeaderItem>
      )}
      <InventoryListHeaderItem size="13%">
        Placed On Back Order
      </InventoryListHeaderItem>
    </InventoryListHeaderContainer>
  );
};

const InventoryStatusNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.4rem;
`;

interface InventoryInfoContainerProps {
  alternate?: boolean;
}
const InventoryInfoContainer = styled.div<InventoryInfoContainerProps>`
  background: ${({ theme: { colors }, alternate }) =>
    alternate ? colors.blueUltraLight : colors.white};
`;

interface InventoryStatusProps {
  partNumber?: string;
  description?: string;
  unitPrice?: string;
  dealerPrice?: string;
  placedOnBackOrder?: number;
  alternateFor?: string;
  isOpen?: boolean;
  onClose?: () => void;
  onEditParts?: () => void;
  onContinue?: () => void;
  items?: Array<{
    number: string;
    alternateFor?: string;
    description: string;
    price: string;
    dealerPrice: string;
    qty: number;
    stock: number;
  }>;
}

const InventoryStatus: FunctionComponent<InventoryStatusProps> = ({
  isOpen = true,
  onClose = (): void => {},
  onEditParts = () => {},
  onContinue = () => {},
  items = [],
}) => {
  return (
    <InventoryStatusModal isOpen={isOpen} onClose={onClose}>
      <InventoryStatusContainer>
        <InventoryInfoContainer>
          <InventoryTitle>
            In-Stock status changed on the following items
          </InventoryTitle>
          <InventoryDescription>
            Selecting continue will add the following items to a back order and
            will be invoiced separately from the parts that are in-stock.
          </InventoryDescription>
          <InventoryListHeader />
          {items.map(item => (
            <InventoryItem
              key={item.number}
              partNumber={item.number}
              alternateFor={item.alternateFor}
              description={item.description}
              unitPrice={item.price ? parseFloat(item.price) : 0}
              dealerUnitPrice={
                item.dealerPrice ? parseFloat(item.dealerPrice) : 0
              }
              placedOnBackOrder={item.qty - item.stock}
            />
          ))}
        </InventoryInfoContainer>
        <InventoryStatusNavContainer>
          <EditPart onClick={onEditParts}>
            <ChevronLeft /> Edit Order
          </EditPart>
          <Continue onClick={onContinue}>Order Preview</Continue>
        </InventoryStatusNavContainer>
      </InventoryStatusContainer>
    </InventoryStatusModal>
  );
};

export default InventoryStatus;
