/* eslint-disable @typescript-eslint/indent */
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import React, { FunctionComponent } from 'react';

import StarSwitch from '../Inputs/StarSwitch';
import { useUnfavoritePartMutation } from '../../generated/graphql';
import { ReactComponent as chevron } from '../../assets/images/chevron.svg';
import ToRGB from '../../utils/color';
import PartEvent from '../../utils/partEvent';
import formatters from '../../utils/formatters';
import constants, { dealerTypes } from '../../constants';
import useUser from '../../hooks/useUser';

const { r, g, b } = ToRGB(constants.theme.colors.blueNeutral);
const Row = styled.div<{ hasBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.6rem 0;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.whiteSmoke};
  border-left: 0.4rem solid
    ${({ hasBorder }) =>
      hasBorder ? `rgba(${r}, ${g}, ${b}, 0.5)` : 'transparent'};
`;

interface ContentProps {
  flex: number;
  tooltip?: boolean;
  isNumber?: boolean;
  isAlternate?: boolean;
}

const Content = styled.div<ContentProps>`
  font-size: 1rem;
  line-height: normal;
  flex: ${({ flex }) => flex};
  font-family: ${({ theme }) => theme.typography.museo};
  padding: 0.6rem ${({ isNumber }) => (isNumber ? '0.5rem' : '0.9rem')};
  padding-top: ${({ isAlternate }) => (isAlternate ? 0 : '0.6rem')};
  padding-bottom: ${({ isAlternate }) => (isAlternate ? 0 : '0.6rem')};
  overflow: ${({ tooltip }) => (tooltip ? 'hidden' : undefined)};
  white-space: ${({ tooltip }) => (tooltip ? 'nowrap' : undefined)};
  text-overflow: ${({ tooltip }) => (tooltip ? 'ellipsis' : undefined)};
  color: ${({ theme, isNumber }) =>
    isNumber ? theme.colors.blueDark : theme.colors.grayDark};
`;

const AlternatePartLabel = styled.span<{ tooltip?: boolean }>`
  font-size: 1rem;
  font-style: italic;
  line-height: normal;
  color: ${({ theme }) => theme.colors.blueDark};
  font-family: ${({ theme }) => theme.typography.museo};
  overflow: ${({ tooltip }) => (tooltip ? 'hidden' : undefined)};
  white-space: ${({ tooltip }) => (tooltip ? 'nowrap' : undefined)};
  text-overflow: ${({ tooltip }) => (tooltip ? 'ellipsis' : undefined)};
`;

const AddToOrderAction = styled.span`
  cursor: pointer;
  user-select: none;
  line-height: normal;
  color: ${({ theme }) => theme.colors.blueKobelco};
  font-family: ${({ theme }) => theme.typography.museo};
`;

const MobileRowContainer = styled.div<{ hasBorder?: boolean }>`
  padding: 0.8rem 0.8rem 1.6rem 0.8rem;
  border-left: 0.4rem solid
    ${({ hasBorder }) =>
      hasBorder ? `rgba(${r}, ${g}, ${b}, 0.5)` : 'transparent'};
`;

const MobileRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface MobileContentProps {
  flex: number;
  tooltip?: boolean;
  align?: 'left' | 'right';
}

const MobileContent = styled.div<MobileContentProps>`
  font-size: 1rem;
  line-height: normal;
  flex: ${({ flex }) => flex};
  text-align: ${({ align }) => align};
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.typography.museo};
  overflow: ${({ tooltip }) => (tooltip ? 'hidden' : undefined)};
  white-space: ${({ tooltip }) => (tooltip ? 'nowrap' : undefined)};
  text-overflow: ${({ tooltip }) => (tooltip ? 'ellipsis' : undefined)};
`;

const ChevronIcon = styled(chevron)<{ up?: boolean }>`
  transition: transform 0.12s ease-in-out;
  color: ${({ theme }) => theme.colors.grayADA};
  transform: ${({ up }) => (up ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const MoreInfoAction = styled.span`
  font-size: 1rem;
  margin-left: 1.2rem;
  line-height: normal;
  color: ${({ theme }) => theme.colors.grayADA};
  font-family: ${({ theme }) => theme.typography.museo};
`;

const CustomReactTooltip = styled(ReactTooltip)`
  font-size: 1.14rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  opacity: 1 !important;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  font-family: ${({ theme }) => theme.typography.museo};
  color: ${({ theme }) => theme.colors.black} !important;
  background-color: ${({ theme }) => theme.colors.white} !important;
  &::after,
  &::before {
    display: none;
  }
`;

interface FavoritePartProps {
  part: any;
  sg: number;
  kty: number;
  jpn: number;
  price: number;
  partNum: string;
  partDesc: string;
  dealerPrice?: number;
  isFavorite?: boolean;
  alternateFor?: string;
  isMobile?: boolean;
}

const FavoritePart: FunctionComponent<FavoritePartProps> = ({
  sg,
  kty,
  jpn,
  part,
  price,
  partNum,
  partDesc,
  dealerPrice,
  alternateFor,
  isMobile,
}) => {
  const [unfavoriteMutation] = useUnfavoritePartMutation({
    variables: { partNumber: partNum },
    refetchQueries: ['currentUser', 'favoriteParts'],
  });

  const user = useUser();

  const handleAddToOrder = () => {
    const event = new PartEvent('AddedToOrder');
    event.part = part;
    window.dispatchEvent(event);
  };

  const formattedPrice = formatters.currency(price);
  const formattedDealerPrice = formatters.currency(dealerPrice || 0);

  if (isMobile) {
    return (
      <MobileRowContainer hasBorder={!!alternateFor}>
        <MobileRow>
          <MobileContent flex={2} tooltip>
            {partNum}
          </MobileContent>
          {user?.dealerType === dealerTypes.excavator ? (
            <>
              <MobileContent flex={0.5}>{kty}</MobileContent>
              <MobileContent flex={0.5}>{jpn}</MobileContent>
            </>
          ) : (
            <>
              <MobileContent flex={0.33}>{kty}</MobileContent>
              <MobileContent flex={0.33}>{jpn}</MobileContent>
              <MobileContent flex={0.33}>{sg}</MobileContent>
            </>
          )}
          <MobileContent flex={2} align="right">
            {formattedPrice}
            {dealerPrice && <br />}
            {dealerPrice && formattedDealerPrice}
          </MobileContent>
        </MobileRow>
        {!!alternateFor && (
          <MobileRow>
            <AlternatePartLabel>
              New Part Number for: {alternateFor}
            </AlternatePartLabel>
          </MobileRow>
        )}
        <MobileRow style={{ marginTop: '0.8rem' }}>
          <MobileRow>
            <StarSwitch isFavorite onClick={unfavoriteMutation} />
            <MoreInfoAction>
              More Info <ChevronIcon />
            </MoreInfoAction>
          </MobileRow>
          <AddToOrderAction onClick={handleAddToOrder}>
            Add to Order
          </AddToOrderAction>
        </MobileRow>
      </MobileRowContainer>
    );
  }

  const numberTooltip = `Number: ${partNum}${
    alternateFor ? ` (New Part Number for: ${alternateFor})` : ''
  }`;
  return (
    <Row hasBorder={!!alternateFor}>
      <Content
        tooltip
        isNumber
        flex={2}
        data-tip
        data-for={`${partNum}-number`}
        isAlternate={!!alternateFor}
      >
        {partNum}
        <br />
        {!!alternateFor && (
          <AlternatePartLabel>
            New Part Number for: {alternateFor}
          </AlternatePartLabel>
        )}
      </Content>
      <CustomReactTooltip multiline effect="solid" id={`${partNum}-number`}>
        {numberTooltip}
      </CustomReactTooltip>

      <Content tooltip flex={2} data-tip data-for={`${partNum}-description`}>
        {partDesc}
      </Content>
      <CustomReactTooltip
        multiline
        effect="solid"
        id={`${partNum}-description`}
      >
        {partDesc}
      </CustomReactTooltip>

      {user?.dealerType === dealerTypes.excavator ? (
        <>
          <Content flex={0.45}>{kty}</Content>
          <Content flex={0.45}>{jpn}</Content>
        </>
      ) : (
        <>
          <Content flex={0.3}>{kty}</Content>
          <Content flex={0.3}>{jpn}</Content>
          <Content flex={0.3}>{sg}</Content>
        </>
      )}

      {user?.dealerType === dealerTypes.excavator ? (
        <>
          <Content flex={0.8}>{formattedPrice}</Content>
          <Content flex={0.8}>{formattedDealerPrice}</Content>
        </>
      ) : (
        <>
          <Content flex={1.6}>{formattedPrice}</Content>
        </>
      )}

      <Content flex={0.3}>
        <StarSwitch isFavorite onClick={unfavoriteMutation} />
      </Content>
      <Content flex={1.2}>
        <AddToOrderAction onClick={handleAddToOrder}>
          Add to Order
        </AddToOrderAction>
      </Content>
    </Row>
  );
};

export default FavoritePart;
