import { ApolloError } from 'apollo-client';

const replaceFromMessage = (text = '') => text.replace('GraphQL', '');

export const getErrorMessage = (error: Partial<ApolloError> | string) => {
  if (typeof error !== 'string') {
    if (error.networkError) {
      return '';
    }

    let displayedMessage;

    const { graphQLErrors = [], message } = error;
    const [firstGraphqlError] = graphQLErrors;

    if (firstGraphqlError) {
      displayedMessage = firstGraphqlError.message;
    } else {
      displayedMessage = message;
    }

    return replaceFromMessage(displayedMessage);
  }
  return replaceFromMessage(error);
};
