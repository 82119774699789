import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FlatButton } from '../styled/buttons';
import useWindowSize from '../../hooks/useWindowSize';
import { clearSession, getUser } from '../../utils/localStorage';
import apolloClient from '../../utils/apolloClient';

import { ReactComponent as User } from '../../assets/images/user.svg';
import { ReactComponent as logo } from '../../assets/images/logo.svg';
import { ReactComponent as menu } from '../../assets/images/menu.svg';
import { ReactComponent as plus } from '../../assets/images/plus-white.svg';

import ToRGB from '../../utils/color';
import theme from '../../constants/theme';

const NavbarContainer = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.1rem 1rem 1.85rem;
  background: ${({ theme: { colors } }) => colors.blueKobelco};
`;

const { r, g, b } = ToRGB(theme.colors.blueKobelco);
const ExpandedNavbar = styled.div`
  left: 0;
  top: 72px;
  z-index: 35;
  width: 100vw;
  height: calc(100vh - 72px);
  position: absolute;
  background-color: rgba(${r}, ${g}, ${b}, 0.9);
`;

const LogoContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${({ theme: { colors } }) => colors.white};
  font-size: 1.5rem;
  text-decoration: none;
`;

const Logo = styled(logo)`
  width: 10rem;
  height: 2rem;
`;

const Menu = styled(menu)`
  cursor: pointer;
`;

const Close = styled(plus)`
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  transform: rotate(45deg);
`;

const Lettering = styled.span`
  font-family: ${({ theme: { typography } }) => typography.ptsans};
`;

const NavLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 2.9rem;
`;

const Route = styled(({ mobile, ...rest }) => <Link {...rest} />)<{
  mobile?: boolean;
}>`
  font-weight: 300;
  font-size: 1.2rem;
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.white};
  margin-right: ${({ mobile }) => (mobile ? 0 : '1.2rem')};
  margin-bottom: ${({ mobile }) => (mobile ? '1.7rem' : 0)};
  font-family: ${({ theme: { typography } }) => typography.museo};
  &:first-child {
    margin-top: ${({ mobile }) => (mobile ? '1.7rem' : 0)};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SupportRow = styled.div`
  align-items: center;
  color: ${({ theme: { colors } }) => colors.white};
  display: flex;
  flex-direction: row;
  flex: 1;
  font-family: ${({ theme: { typography } }) => typography.museo};
  justify-content: flex-end;
  opacity: 0.75;
  padding: 0 20px;
  font-size: 0.95rem;

  a {
    color: ${({ theme: { colors } }) => colors.white};
    text-decoration: none;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserName = styled.h3`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  margin: 0;
  vertical-align: middle;
`;

const Avatar = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 1.1rem;
  background-color: white;
  margin: 0 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const routes = [
  { label: 'Whole Goods', url: '' },
  { label: 'Marketing', url: '' },
  { label: 'Parts', url: '' },
  { label: 'KOMEXS', url: '' },
  { label: 'Warranty', url: '' },
  { label: 'Directory', url: '' },
];

const Navbar: React.FC = () => {
  const user = getUser();
  const history = useHistory();

  const [isExpanded, setExpanded] = useState<boolean>(false);

  const { width } = useWindowSize();
  const tabletBreakpoint = Number(theme.breakpoints.tablet.replace('px', ''));

  useEffect(() => {
    if (width && width >= tabletBreakpoint && isExpanded) {
      setExpanded(false);
    }
    // eslint-disable-next-line
  }, [width, tabletBreakpoint]);

  const handleLogout = () => {
    clearSession();
    apolloClient.clearStore();
    history.replace('/signin');
  };

  const renderRoutes = () => {
    return routes.map(route => (
      // NO Links use label for key
      <Route to={route.url} mobile={isExpanded} key={`route-${route.label}`}>
        {route.label}
      </Route>
    ));
  };

  return (
    <>
      <NavbarContainer>
        <Row>
          <LogoContainer to="/">
            <Logo />
            <Lettering>Parts</Lettering>
          </LogoContainer>
          {/* UNCOMMENT TO ACTIVATE NAV LINKS */}
          {/* {width && width >= tabletBreakpoint && (
            <NavLinkContainer>{renderRoutes()}</NavLinkContainer>
          )} */}
        </Row>
        <SupportRow>
          <p>
            For Support Email:{' '}
            <a
              href="mailto:kcmu.websupport@kobelco.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              kcmu.websupport@kobelco.com
            </a>
          </p>
        </SupportRow>
        {width && width >= tabletBreakpoint
          ? user && (
              <ProfileContainer>
                <UserName>{user.name}</UserName>
                <Avatar>
                  <User />
                </Avatar>
                <FlatButton onClick={handleLogout}>Log Out</FlatButton>
              </ProfileContainer>
            )
          : user && (
              <>
                {isExpanded ? (
                  <Close onClick={() => setExpanded(false)} />
                ) : (
                  <Menu onClick={() => setExpanded(true)} />
                )}
              </>
            )}
      </NavbarContainer>
      {isExpanded && (
        <ExpandedNavbar>
          <Column>
            {/* {renderRoutes()} */}
            {user && (
              <>
                <Row style={{ marginBottom: '2.4rem' }}>
                  <UserName>{user.name}</UserName>
                  <Avatar>
                    <User />
                  </Avatar>
                </Row>
                <FlatButton
                  onClick={handleLogout}
                  style={{ marginBottom: '2.4rem' }}
                >
                  Log Out
                </FlatButton>
              </>
            )}
          </Column>
        </ExpandedNavbar>
      )}
    </>
  );
};

export default Navbar;
