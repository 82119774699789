import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import ResponsiveHandler from '../ResponsiveHandler';
import { TextButton } from '../styled/buttons';
import theme from '../../constants/theme';
import Spacer from '../Spacer';
import formatters from '../../utils/formatters';
import { ReactComponent as ChevronIcon } from '../../assets/images/chevron.svg';

interface SelectedProps {
  selected?: boolean;
}

const Container = styled.div<SelectedProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.1rem 0;
  transform: translate3d(0, 0, 0);
  background-color: ${({ theme: { colors } }) => colors.white};
  ${up('mobile')} {
    background-color: ${({ theme: { colors }, selected }) =>
      selected ? colors.grayLight : colors.white};
  }
  border-bottom: 0.1rem solid ${({ theme: { colors } }) => colors.grayLight};
`;

const TableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 1rem;
`;

const ViewOrder = styled(TextButton)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.2rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: right;
  color: ${({ theme: { colors } }) => colors.blueDark};
`;

const Chevron = styled(ChevronIcon)`
  transform: rotate(-90deg);
  margin-left: 0.8rem;
`;

interface TableEntryProps {
  primary?: boolean;
  noPadding?: boolean;
  right?: boolean;
}

const TableEntry = ResponsiveHandler(styled.div<TableEntryProps>`
  font-size: ${({ right }) => (right ? '1.2rem' : '1rem')};
  padding-top: ${({ noPadding }) => (noPadding ? '0' : '0.8rem')};
  padding-bottom: ${({ noPadding }) => (noPadding ? '0' : '0.8rem')};
  line-height: 0.7rem;
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors }, primary }) =>
    primary ? colors.blueKobelco : colors.grayDark};
  text-transform: none;
  display: flex;
  flex-direction: column;
  align-items: ${({ right }) => (right ? 'flex-end' : 'flex-start')};
  justify-content: space-between;
`);

const EntryTitle = styled.div`
  font-size: 0.6rem;
  line-height: 0.7rem;
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.grayADA};
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
`;

interface OrderItemProps {
  isSavedOrders?: boolean;
  order: {
    id: string;
    total: number;
    number: string;
    poNumber: string;
    submittedAt: string;
  };
  selected?: boolean;
  onView?: () => void;
}

const OrderItem: FunctionComponent<OrderItemProps> = ({
  order,
  selected = false,
  onView = () => {},
  isSavedOrders,
}) => (
  <Container selected={selected}>
    <TableContainer>
      <TableEntry size="40%">
        <EntryTitle>Order no.</EntryTitle>
        {order.number === order.id ? '--' : order.number}
        <Spacer vertical size="1.5rem" />
        <EntryTitle>Created</EntryTitle>
        {formatters.date(order.submittedAt)}
      </TableEntry>
      <TableEntry size="30%">
        <EntryTitle>PO NO.</EntryTitle>
        {order.poNumber || '--'}
      </TableEntry>
      {isSavedOrders && (
        <TableEntry size="30%">
          <EntryTitle>Saved Total</EntryTitle>
          {order.total ? formatters.currency(order.total) : '--'}
        </TableEntry>
      )}
      <TableEntry right size="30%">
        <EntryTitle>status</EntryTitle>
        Open
        <Spacer vertical size="1.5rem" />
        <ViewOrder onClick={onView} color={theme.colors.blueNeutral}>
          View Order <Chevron />
        </ViewOrder>
      </TableEntry>
    </TableContainer>
  </Container>
);

export default OrderItem;
