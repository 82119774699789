import React, { useEffect, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { getToken, getUser, clearSession } from './localStorage';

function AuthenticatedRoute(Component: any) {
  const WrappedComponent: FunctionComponent = (props: any) => {
    const history = useHistory();
    useEffect(() => {
      const user = getUser();
      const token = getToken();
      if (!user || !token) {
        clearSession();
        history.replace('/signin');
      }
    }, [history]);
    return <Component {...props} />;
  };
  return WrappedComponent;
}

export default AuthenticatedRoute;
