const ToRGB = (color: string): { r: number; g: number; b: number } => {
  let r = 255;
  let g = 255;
  let b = 255;
  if (color[0] === '#') {
    const hex = color.replace('#', '');
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else if (color.startsWith('rgb')) {
    const colorstr = /(\(.+\))/.exec(color);
    const rgb = colorstr && colorstr[0].replace('(', '').replace(')', '');
    if (rgb) {
      r = parseInt(rgb.split(',')[0], 10);
      g = parseInt(rgb.split(',')[1], 10);
      b = parseInt(rgb.split(',')[2], 10);
    }
  }
  return { r, g, b };
};

export default ToRGB;
