import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Option } from '../../constants';

import { ReactComponent as chevron } from '../../assets/images/chevron.svg';

const Container = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 25;
  padding: 1.2rem;
  position: fixed;
  background: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px -9px 16px rgba(0, 0, 0, 0.1), 0px -1px 4px rgba(0, 0, 0, 0.15);
`;

const Row = styled.div<{ between?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ between }) => (between ? 'space-between' : undefined)};
`;

const ActionLink = styled.span`
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.blueKobelco};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const ActionsSeparator = styled.div`
  width: 0.1rem;
  height: 1.8rem;
  margin-left: 0.85rem;
  margin-right: 0.85rem;
  background-color: ${({ theme: { colors } }) => colors.grayLight};
`;

const SubmitButton = styled.button`
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.6rem 1.2rem;
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { typography } }) => typography.museo};
  background-color: ${({ theme: { colors } }) => colors.blueDark};
  :disabled {
    color: ${({ theme: { colors } }) => colors.grayDark};
    background-color: ${({ theme: { colors } }) => colors.grayLight};
  }
`;

const ChevronLeft = styled(chevron)`
  transform: rotate(90deg);
  color: ${({ theme: { colors } }) => colors.blueNeutral};
`;

interface DeliveryOptionProps {
  form: any;
  isOpen?: boolean;
  onSubmit?: () => void;
  onDoneforNow?: () => void;
  onAddMoreParts?: () => void;
  onSelectOption?: (option: Option) => void;
}

const DeliveryOption: FunctionComponent<DeliveryOptionProps> = ({
  form,
  isOpen,
  onSubmit,
  onDoneforNow,
  onAddMoreParts,
}) => {
  const formik = form;

  return isOpen ? (
    <>
      <Container>
        <Row between>
          <Row>
            <ActionLink onClick={onAddMoreParts}>
              <ChevronLeft /> Edit Order
            </ActionLink>
            <ActionsSeparator />
            <ActionLink onClick={onDoneforNow}>
              I&apos;m done for now
            </ActionLink>
          </Row>
          <SubmitButton
            type="button"
            onClick={onSubmit}
            disabled={!formik.values.shippingMethod}
          >
            Submit Order
          </SubmitButton>
        </Row>
      </Container>
    </>
  ) : null;
};

export default DeliveryOption;
