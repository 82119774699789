enum SHIPPING_COMPANIES {
  FED_EX = 'FED_EX',
}

type shippingCompaniesKeys = keyof typeof SHIPPING_COMPANIES;

interface TrackingUrlsInterface {
  [key: string]: string;
}

interface TrackingUrlsReturn {
  company: string | undefined;
  url: string;
}

const TRACKING_URLS: TrackingUrlsInterface = {
  [SHIPPING_COMPANIES.FED_EX]:
    'https://www.fedex.com/apps/fedextrack/?tracknumbers=',
};

// FedEx Regex matching
const fedExRegularExpressionsRaw = [
  /^[0-9]{20}$/,
  /^[0-9]{15}$/,
  /^[0-9]{12}$/,
  /^[0-9]{22}$/,
];

// Other Companies

// Test Tracking Id against shipping RegExes

const FedExRegularExpressions = fedExRegularExpressionsRaw.map(
  rawString => new RegExp(rawString),
);

function isFedEx(trackingId: string) {
  return FedExRegularExpressions.some(regEx => regEx.test(trackingId));
}

function getTrackingUrl(trackingId: shippingCompaniesKeys) {
  return TRACKING_URLS[trackingId];
}

// Grab and send back the company and the url
export function getTrackingCompanyUrl(
  trackingId: string | undefined,
): TrackingUrlsReturn {
  if (!trackingId) {
    return {
      company: undefined,
      url: '#',
    };
  }

  if (isFedEx(trackingId)) {
    return {
      company: SHIPPING_COMPANIES.FED_EX,
      url: getTrackingUrl(SHIPPING_COMPANIES.FED_EX) + trackingId,
    };
  }

  return {
    company: undefined,
    url: `https://www.google.com/search?q=Tracking%20Number%20${trackingId}`,
  };
}
