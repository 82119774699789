import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';

const TabsMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const activeClassName = 'active';

const PlusIconStyle = styled(PlusIcon)`
  padding-right: 0.8rem;
`;

const TabLinks = styled(NavLink)`
  flex: 1;
  font-size: 1.2rem;
  background-color: ${({ theme: { colors } }) => colors.white};
  color: ${({ theme: { colors } }) => colors.blueKobelco};
  font-family: ${({ theme: { typography } }) => typography.museo};
  padding: 1.4rem 2.1rem;
  border-right: 0.1rem solid ${({ theme: { colors } }) => colors.grayLight};
  &.${activeClassName} {
    color: ${({ theme: { colors } }) => colors.blueDark};
    background-color: ${({ theme: { colors } }) => colors.grayActive};
    svg line {
      stroke: ${({ theme: { colors } }) => colors.blueDark};
    }
  }
  &:hover {
    color: ${({ theme: { colors } }) => colors.blueDark};
  }
  text-align: center;
  text-decoration: none;
`;

const TabsMenuDesktop = () => {
  return (
    <TabsMenuContainer>
      <TabLinks to="/new-order">
        <PlusIconStyle />
        New Order
      </TabLinks>
      <TabLinks to="/saved-carts">Saved Carts</TabLinks>
      <TabLinks to="/open-orders">Open Orders</TabLinks>
      <TabLinks to="/all-orders">All Orders</TabLinks>
    </TabsMenuContainer>
  );
};
export default TabsMenuDesktop;
