import styled from 'styled-components';
import React, { FunctionComponent, InputHTMLAttributes, useRef } from 'react';

const TextFieldContainer = styled.div`
  position: relative;
`;

interface TextFieldHelperProps {
  medium?: boolean;
  hasValue?: boolean;
  hasError?: boolean;
  disabled?: boolean;
}

export const TextFieldLabel = styled.span<TextFieldHelperProps>`
  left: 0.8rem;
  user-select: none;
  position: absolute;
  transition: all 0.12s ease-in-out;
  font-family: ${({ theme }) => theme.typography.museo};
  top: ${({ hasValue }) => (hasValue ? '0.3rem' : '50%')};
  cursor: ${({ disabled }) => (disabled ? undefined : 'text')};
  line-height: ${({ hasValue }) => (hasValue ? 'normal' : 'inherit')};
  transform: ${({ hasValue }) => (hasValue ? undefined : 'translateY(-50%)')};
  color: ${({ theme, hasValue }) =>
    hasValue ? theme.colors.blueDark : theme.colors.grayDark};
  font-size: ${({ medium, hasValue }) => {
    if (medium) {
      return hasValue ? '1rem' : '1.4rem';
    }
    return hasValue ? '0.6rem' : '1rem';
  }};
`;

const TextFieldInput = styled.input<TextFieldHelperProps>`
  width: 100%;
  outline: none;
  display: block;
  padding: 1rem 0.8rem;
  box-sizing: border-box;
  background-clip: padding-box;
  transition: all 0.12s ease-in-out;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.typography.museo};
  font-size: ${({ medium }) => (medium ? '1.4rem' : '1rem')};
  min-height: ${({ medium }) => (medium ? '4.5rem' : '3.8rem')};
  padding-right: ${({ hasError }) => (hasError ? '3.2rem' : '0.8rem')};
  border: ${({ theme, hasError }) =>
    hasError ? `2px solid ${theme.colors.red}` : 'none'};
  background-color: ${({ theme, hasValue }) =>
    hasValue ? theme.colors.grayLight : theme.colors.blueUltraLight};
`;

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  medium?: boolean;
  errorMessage?: string;
  autoCapitalize?: string;
}

const TextField: FunctionComponent<TextFieldProps> = ({
  type,
  value,
  label,
  medium,
  disabled,
  required,
  errorMessage,
  autoCapitalize,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const focusOnInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <TextFieldContainer>
      {!!label && (
        <TextFieldLabel
          medium={medium}
          hasValue={!!value}
          disabled={disabled}
          onFocus={focusOnInput}
          onClick={focusOnInput}
        >
          {label}
        </TextFieldLabel>
      )}
      <TextFieldInput
        value={value}
        ref={inputRef}
        medium={medium}
        hasValue={!!value}
        disabled={disabled}
        type={type || 'text'}
        autoCapitalize={autoCapitalize}
        hasError={required && !!errorMessage}
        {...rest}
      />
    </TextFieldContainer>
  );
};

export default TextField;
