import styled from 'styled-components';
import React, { FunctionComponent } from 'react';

import ToRGB from '../../utils/color';
import formatter from '../../utils/formatters';
import theme from '../../constants/theme';

import { ReactComponent as chevron } from '../../assets/images/chevron.svg';
import useUser from '../../hooks/useUser';
import { dealerTypes } from '../../constants';

const Container = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 30;
  height: calc(100vh - 72px);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

const TopSection = styled.div`
  padding: 3.2rem 1.6rem;
`;
const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.6rem;
  border-top: 0.1rem solid ${({ theme: { colors } }) => colors.grayLight};
`;

const TopSectionTitle = styled.span`
  display: block;
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  color: ${({ theme: { colors } }) => colors.black};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const TopSectionDescription = styled.span`
  display: block;
  font-weight: 300;
  font-size: 1.4rem;
  color: ${({ theme: { colors } }) => colors.grayDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const TableTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: normal;
  padding: 0.5rem 1.6rem;
  border-bottom: 0.1rem solid ${({ theme: { colors } }) => colors.whiteSmoke};
`;

const { r, g, b } = ToRGB(theme.colors.blueNeutral);
const TableContentRowWrapper = styled.div<{ hasBorder?: boolean }>`
  padding: 0.8rem 1.6rem 0.8rem 1.4rem;
  border-left: 0.2rem solid
    ${({ hasBorder }) =>
      hasBorder ? `rgba(${r}, ${g}, ${b}, 0.5)` : 'transparent'};
`;

const TableContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: normal;
`;

type TableContentProps = {
  flex: number;
  align: 'left' | 'right' | 'center';
};

const TableTitle = styled.span<TableContentProps>`
  font-size: 1.2rem;
  line-height: 1.1rem;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  flex: ${({ flex }) => flex};
  text-align: ${({ align }) => align};
  color: ${({ theme: { colors } }) => colors.blueNeutral};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const TableContent = styled.span<TableContentProps & { blue?: boolean }>`
  font-size: 1.2rem;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  flex: ${({ flex }) => flex};
  text-align: ${({ align }) => align};
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors }, blue }) =>
    blue ? colors.blueDark : colors.grayDark};
`;

const TableContentAlternateFor = styled.span`
  flex: 1;
  font-size: 1.2rem;
  font-style: italic;
  color: ${({ theme: { colors } }) => colors.blueNeutral};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const ChevronLeft = styled(chevron)`
  width: 1.2rem;
  height: 1.2rem;
  transform: rotate(90deg);
  color: ${({ theme: { colors } }) => colors.blueNeutral};
`;
const EditPartsAction = styled.span`
  cursor: pointer;
  font-size: 1.6rem;
  color: ${({ theme: { colors } }) => colors.blueNeutral};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const ContinueButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.6rem;
  padding: 1.4rem 2.5rem;
  color: ${({ theme: { colors } }) => colors.white};
  background-color: ${({ theme: { colors } }) => colors.blueDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

interface InventoryStatusMobileProps {
  items: any[];
  isOpen?: boolean;
  onContinue: () => void;
  onEditParts: () => void;
}

const InventoryStatusMobile: FunctionComponent<InventoryStatusMobileProps> = ({
  items,
  isOpen,
  onContinue,
  onEditParts,
}) => {
  const user = useUser();
  return isOpen ? (
    <Container>
      <div>
        <TopSection>
          <TopSectionTitle>
            In-Stock status changed on the following items
          </TopSectionTitle>
          <TopSectionDescription>
            Selecting continue will add the following items to a back order and
            will be invoiced separately from the parts that are in-stock.
          </TopSectionDescription>
        </TopSection>
        <TableTitleRow>
          <TableTitle flex={1.5} align="left">
            Part
          </TableTitle>
          {user?.dealerType === dealerTypes.excavator ? (
            <>
              <TableTitle flex={0.325} align="left">
                Unit Price
              </TableTitle>
              <TableTitle flex={0.325} align="left">
                D-NET Price
              </TableTitle>
            </>
          ) : (
            <TableTitle flex={0.75} align="left">
              Unit Price
            </TableTitle>
          )}
          <TableTitle flex={0.75} align="center">
            Placed on Back Order
          </TableTitle>
        </TableTitleRow>
        {items.map((item: any) => (
          <TableContentRowWrapper
            key={`part-${item.number}`}
            hasBorder={!!item.alternateFor}
          >
            <TableContentRow>
              <TableContent blue flex={1.5} align="left">
                {item.number}
              </TableContent>
              {user?.dealerType === dealerTypes.excavator ? (
                <>
                  <TableContent flex={0.325} align="left">
                    {formatter.currency(item.price)}
                  </TableContent>
                  <TableContent flex={0.325} align="left">
                    {formatter.currency(item.dealerPrice)}
                  </TableContent>
                </>
              ) : (
                <TableContent flex={0.75} align="left">
                  {formatter.currency(item.price)}
                </TableContent>
              )}
              <TableContent blue flex={0.75} align="center">
                {item.qty - item.stock}
              </TableContent>
            </TableContentRow>
            {!!item.alternateFor && (
              <TableContentRow style={{ marginTop: '0.2rem' }}>
                <TableContentAlternateFor>
                  New Part Number for: {item.alternateFor}
                </TableContentAlternateFor>
              </TableContentRow>
            )}
          </TableContentRowWrapper>
        ))}
      </div>
      <BottomSection>
        <EditPartsAction onClick={onEditParts}>
          <ChevronLeft /> Edit Order
        </EditPartsAction>
        <ContinueButton type="button" onClick={onContinue}>
          Order Preview
        </ContinueButton>
      </BottomSection>
    </Container>
  ) : null;
};

export default InventoryStatusMobile;
