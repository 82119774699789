import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import ResponsiveHandler from '../ResponsiveHandler';
import PartItem from './InventoryPartItem';
import formatters from '../../utils/formatters';
import useUser from '../../hooks/useUser';
import { dealerTypes } from '../../constants';

export const WPadding = styled.div`
  padding-right: 3.2rem;
  padding-left: 3.2rem;
`;

const Item = ResponsiveHandler(styled.div`
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-size: 0.8rem;
  line-height: 0.9rem;
`);

interface InventoryItemProps {
  partNumber?: string;
  description?: string;
  unitPrice?: number;
  dealerUnitPrice?: number;
  placedOnBackOrder?: number;
  alternateFor?: string;
}

const InventoryItemContainer = styled(WPadding)`
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.2rem;
  padding-bottom: 1rem;
  align-items: center;
`;

const PriceDescription = styled(Item)`
  color: ${({ theme: { colors } }) => colors.greyDark};
`;
const PriceItem = styled(Item)`
  color: ${({ theme: { colors } }) => colors.greyDark};
`;
const PlacedOnBackOrder = styled(Item)`
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: center;
`;

const InventoryItem: FunctionComponent<InventoryItemProps> = ({
  partNumber = '',
  description,
  unitPrice,
  dealerUnitPrice,
  placedOnBackOrder,
  alternateFor = '',
}) => {
  const user = useUser();
  return (
    <InventoryItemContainer>
      <PartItem partNumber={partNumber} alternateFor={alternateFor} />
      <PriceDescription size="27%">{description}</PriceDescription>
      {user?.dealerType === dealerTypes.excavator ? (
        <>
          <PriceItem size="10%">
            {formatters.currency(unitPrice || 0)}
          </PriceItem>
          <PriceItem size="10%">
            {formatters.currency(dealerUnitPrice || 0)}
          </PriceItem>
        </>
      ) : (
        <PriceItem size="20%">{formatters.currency(unitPrice || 0)}</PriceItem>
      )}

      <PlacedOnBackOrder size="13%">{placedOnBackOrder}</PlacedOnBackOrder>
    </InventoryItemContainer>
  );
};

export default InventoryItem;
