import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const ActionBarWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ActionBarRow = styled.div<{ hasBothActions?: boolean }>`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ hasBothActions }) =>
    hasBothActions ? 'space-between' : 'flex-end'};
  ${up('mobile')} {
    padding: 0;
  }
`;

export const ActionBarLine = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 0;
  background-color: ${({ theme }) => theme.colors.grayLight};
  ${up('mobile')} {
    margin-bottom: 1.3rem;
  }
`;

export const PrimaryAction = styled.button`
  border: none;
  outline: none;
  font-size: 1.3rem;
  line-height: normal;
  padding: 1rem 1.7rem;
  font-family: ${({ theme }) => theme.typography.museo};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grayDark : theme.colors.white};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grayLight : theme.colors.blueDark};
  ${up('mobile')} {
    font-size: 1rem;
    padding: 1.2rem 1.7rem;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const SecondaryAction = styled.button`
  padding: 0;
  border: none;
  outline: none;
  font-size: 1.3rem;
  line-height: normal;
  transition: all 0.12s ease-in-out;
  color: ${({ theme }) => theme.colors.blueDark};
  font-family: ${({ theme }) => theme.typography.museo};
  background-color: ${({ theme }) => theme.colors.white};
  ${up('mobile')} {
    font-size: 1rem;
    padding: 1.2rem 1.7rem;
    border: 1px solid ${({ theme }) => theme.colors.blueDark};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    &:hover {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.blueDark};
    }
  }
`;
