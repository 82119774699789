/* eslint-disable @typescript-eslint/indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import React, { FunctionComponent, useState } from 'react';

import { ReactComponent as Plus } from '../../assets/images/plus.svg';

const StyledPlus = styled(Plus)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.6rem;

  ${up('mobile')} {
    width: 0.6rem;
  }

  line {
    stroke: ${({ theme: { colors } }) => colors.blueDark};
  }
`;

const StyledMinusSign = styled(StyledPlus)<{ isZero?: boolean }>`
  line:first-child {
    display: none;
  }
  line {
    stroke: ${({ theme: { colors }, isZero }) =>
      isZero ? colors.gray : colors.blueDark};
  }
`;

const StepCounterContainer = styled.div<{ disabled?: boolean }>`
  pointer-events: ${({ disabled = false }) => (disabled ? 'none' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Clickable = styled.div`
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const CounterInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 0.9rem;
  max-width: 2.55rem;
  max-height: 1.7rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: none;
  outline: none;
  text-align: center;
  box-sizing: border-box;
  color: ${({ theme: { colors } }) => colors.black};
  font-family: ${({ theme: { typography } }) => typography.museo};
  background-color: ${({ theme: { colors } }) => colors.whiteSmoke};
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
`;

interface StepCounterProps {
  disabled?: boolean;
  num: number;
  setNum: (num: number) => void;
}

const StepCounter: FunctionComponent<StepCounterProps> = ({
  disabled,
  num,
  setNum,
}) => {
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  let placeholder: string | undefined = '';
  if (!hasFocus) {
    placeholder = !num || num === 0 ? '0' : undefined;
  }

  const incrementNum = () => {
    if (!num) {
      setNum(1);
    } else {
      setNum(num + 1);
    }
  };

  return (
    <StepCounterContainer disabled={disabled}>
      <Clickable tabIndex={0} onClick={() => setNum(num - 1)}>
        <StyledMinusSign isZero={num === 0} />
      </Clickable>
      <CounterInput
        type="number"
        placeholder={placeholder}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        value={!num && !hasFocus ? '0' : num}
        onChange={e => setNum(Number(e.target.value))}
      />
      <Clickable tabIndex={0} onClick={incrementNum}>
        <StyledPlus />
      </Clickable>
    </StepCounterContainer>
  );
};

export default StepCounter;
