import { HttpLink } from 'apollo-link-http';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getToken, getUser } from './localStorage';
import constants from '../constants';

/** The HTTP link to the API */
const httpLink = new HttpLink({
  uri: constants.configs.GRAPHQL_URL,
});

/**
 * Middleware for the HTTP link. Sets the `Authorization` header
 * on the HTTP request if the `accessToken` is set
 */
const authLink = setContext(() => {
  const accessToken = getToken();
  const user = getUser();
  return {
    headers: {
      ...(accessToken ? { Authorization: accessToken } : {}),
      ...(user ? { dealerType: user.dealerType?.toLowerCase() } : {}),
    },
  };
});

/** The Apollo client */
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  // Part Search Favorites
  // When favoritting two items back to back
  // Cause the second refetch to get 'swallowed'
  queryDeduplication: false,
});

export default client;
