import styled from 'styled-components';
import React, { FunctionComponent } from 'react';

import { ReactComponent as Search } from '../../assets/images/search.svg';

const SearchInputContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: ${({ theme: { colors } }) => colors.grayLight};
`;

const SearchInput = styled.input<{ big?: boolean }>`
  width: calc(100% - 3.8rem);
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-size: ${({ big }) => (big ? '1.6rem' : '0.9rem')};
  line-height: ${({ big }) => (big ? '1.8rem' : '1rem')};
  height: ${({ big }) => (big ? '4.8rem' : '3rem')};
  background-color: transparent;
  padding-left: ${({ big }) => (big ? '3.7rem' : '3rem')};
  padding-right: 1.8rem;
  border: none;
  z-index: 1;
  &:focus {
    outline: none;
  }
`;

const SearchIcon = styled(Search)<{ big?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.7rem;
  pointer-events: none;
  height: ${({ big }) => (big ? '2rem' : '1.5rem')};
  width: ${({ big }) => (big ? '2rem' : '1.5rem')};
  background-repeat: no-repeat;
  background-size: contain;
`;

interface SearchInputComponentProps {
  big?: boolean;
  value: string;
  placeholder?: string;
  onChange: (e: string) => void;
}

const SearchInputComponent: FunctionComponent<SearchInputComponentProps> = ({
  big,
  value,
  onChange,
  placeholder,
}) => (
  <SearchInputContainer>
    <SearchIcon big={big} />
    <SearchInput
      big={big}
      value={value}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
    />
  </SearchInputContainer>
);

export default SearchInputComponent;
