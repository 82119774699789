export function getOrderTotal(parts: any[], useDealerPrice = false): number {
  return (parts || []).reduce((acc, part) => {
    const price = useDealerPrice ? part.dealerPrice || part.price : part.price;
    if (!part.invoiceAmount) {
      return acc + (part.qty || part.quantity) * price;
    }

    return acc + part.invoiceAmount;
  }, 0);
}
