import { useEffect } from 'react';

function useEventListener(
  eventName: string,
  handler: any,
  listened: any,
  element = window,
): void {
  useEffect(() => {
    element.addEventListener(eventName, handler);

    return (): void => {
      element.removeEventListener(eventName, handler);
    };
  }, [eventName, element, handler, listened]);
}

export default useEventListener;
