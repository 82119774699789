import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import React, { FunctionComponent, useEffect, useMemo } from 'react';

import DropDown from '../Inputs/DropDown';
import TextField from '../Inputs/TextField';

import formatters from '../../utils/formatters';
import {
  dealerTypes,
  Option,
  stateOptions,
  TStateOptions,
} from '../../constants';
import useShippingMethods from '../../hooks/useShippingMethods';
import { useCurrentUserQuery } from '../../generated/graphql';
import useUser from '../../hooks/useUser';
import { dealerWhitelistStates } from '../../utils';

const InputsRow = styled.div<{ hasBottom?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ hasBottom }) => (hasBottom ? '0.8rem' : undefined)};
`;

interface InputContainerFlex {
  order: number;
  basis: number;
}

interface InputContainerProps {
  mobile: InputContainerFlex;
  desktop: InputContainerFlex;
}

const InputContainer = styled.div<InputContainerProps>`
  margin-bottom: 0.8rem;
  order: ${({ desktop }) => desktop.order};
  flex: ${({ desktop }) => `0 0 ${desktop.basis}%`};
  ${down('tablet')} {
    order: ${({ mobile }) => mobile.order};
    flex: ${({ mobile }) => `0 0 ${mobile.basis}%`};
  }
`;

type TCountry = keyof TStateOptions;

const CountryOptions: Array<Option> = [
  {
    label: 'USA',
    value: 'US',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
];

interface Props {
  disabled?: boolean;
  formController?: any;
}

const CustomerDetails: FunctionComponent<Props> = ({
  formController,
  disabled = false,
}) => {
  const {
    soTypes,
    loading: shippingMethodsLoading,
    shippingMethods,
    canChangeAddress,
    results: allShippingMethods,
    selectedMethod,
  } = useShippingMethods(formController, !disabled);

  const { data: currentUser } = useCurrentUserQuery();

  const user = useUser();

  useEffect(() => {
    if (allShippingMethods && formController?.values?.consigneeCode) {
      const method = allShippingMethods?.find(
        mt => mt?.ConsigneeCode === formController.values.consigneeCode,
      );
      if (method) {
        formController.setValues(
          {
            ...formController.values,
            shippingMethod: method?.ShippingMethod,
          },
          false,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allShippingMethods, formController.values.consigneeCode]);

  const handleChange = (valueKey: string, option: Option) =>
    formController.setValues(
      {
        ...formController.values,
        [valueKey]: option.value,
      },
      false,
    );

  let date = formController.values.date || formController.values.submittedAt;

  date =
    date && (date.includes('/') || date.includes('-'))
      ? date
      : formatters.date(date, '/');

  const orderTypeDisabled = shippingMethodsLoading;

  const isDisabled = disabled || orderTypeDisabled;

  const noOptionsMessage = !formController.values.orderType
    ? 'Order Type Required'
    : undefined;

  const userStates = useMemo(() => {
    const userStatesList = [...(currentUser?.currentUser?.states || [])];

    if (selectedMethod?.ConsigneeState) {
      userStatesList.push(selectedMethod?.ConsigneeState);
    }

    return userStatesList;
  }, [currentUser, selectedMethod]);

  const states = useMemo(() => {
    const country: TCountry = formController.values.country || 'US';
    if (country === 'CA' && canChangeAddress) {
      return stateOptions[country];
    }

    // eslint-disable-next-line @typescript-eslint/camelcase
    const { dealer_id: dealerId } = user || {};

    const currentWhitelist = dealerWhitelistStates[dealerId || ''] || undefined;
    const stockWhitelist = currentWhitelist?.stock || [];
    const emergencyWhitelist = currentWhitelist?.emergency || [];

    const newUserStates = [...userStates, ...emergencyWhitelist];

    // remove disallowed states
    let filteredStates =
      formController.values.orderType === 'ZAB2' &&
      user?.dealerType !== dealerTypes.excavator
        ? stateOptions[country].filter((state: Option) =>
            newUserStates.includes(state.value),
          )
        : stateOptions[country];

    if (stockWhitelist.length > 0) {
      filteredStates = filteredStates.filter((state: Option) =>
        currentWhitelist.stock.includes(state.value),
      );
    }

    if (
      formController.values.orderType === 'ZAB2' &&
      emergencyWhitelist.length
    ) {
      // add states missing from the list that are in the emergency list
      emergencyWhitelist.forEach(state => {
        if (!filteredStates.find(s => s.value === state)) {
          const newValue = stateOptions[country].find(s => s.value === state);
          if (newValue) filteredStates.push(newValue);
        }
      });
      // sort the list by label
      filteredStates = filteredStates.sort((a, b) =>
        a.label.localeCompare(b.label),
      );
    }

    return filteredStates;
  }, [
    formController.values.orderType,
    formController.values.country,
    userStates,
    canChangeAddress,
    user,
  ]);

  const shouldEnableCountry = useMemo(() => {
    if (formController.values.country === 'CA') {
      const USStates = stateOptions.US.map(state => state.value);
      return (
        userStates.filter(state => USStates.includes(state || '')).length > 0
      );
    }
    return false;
  }, [formController.values.country, userStates]);

  const shouldHideCountrySelector = useMemo(() => {
    if (
      allShippingMethods?.every(method => method?.ConsigneeCountry === 'US')
    ) {
      return true;
    }
    return false;
  }, [allShippingMethods]);

  return (
    <div className="customer-details">
      <InputsRow>
        <InputContainer
          mobile={{ order: 0, basis: 49 }}
          desktop={{ order: 0, basis: 22 }}
        >
          <DropDown
            required
            label="Order Type"
            disabled={isDisabled}
            value={formController.values.orderType}
            onChange={option => {
              // Reset shipping method when order type changes
              // tied to frontend since when we load saved order programatically
              // we don't want this to trigger

              formController.setValues(
                {
                  ...formController.values,
                  shippingMethodRaw: null,
                  shippingMethod: null,
                },
                false,
              );

              handleChange('orderType', option);
            }}
            errorMessage={formController.errors.orderType || undefined}
            options={soTypes}
          />
        </InputContainer>
        <InputContainer
          mobile={{ order: 1, basis: 49 }}
          desktop={{ order: 1, basis: 28 }}
        >
          <DropDown
            disabled={isDisabled}
            errorMessage={formController.errors.shippingMethod || undefined}
            label="Shipping Method"
            noOptionsMessage={noOptionsMessage}
            onChange={option => {
              handleChange('shippingMethod', {
                value: option.label,
                label: option.label,
              });
              handleChange('consigneeCode', option);
            }}
            options={shippingMethods}
            required
            value={formController.values.consigneeCode}
          />
        </InputContainer>
        <InputContainer
          mobile={{ order: 2, basis: 49 }}
          desktop={{ order: 2, basis: 18 }}
        >
          <TextField
            required
            type="text"
            name="freightCode"
            // disabled={isDisabled}
            disabled
            label="Freight Code"
            onChange={formController.handleChange}
            value={formController.values.freightCode}
            errorMessage={formController.errors.freightCode || undefined}
          />
        </InputContainer>
        <InputContainer
          mobile={{ order: 2, basis: 49 }}
          desktop={{ order: 4, basis: 28 }}
        >
          <TextField
            required
            disabled={isDisabled}
            errorMessage={formController.errors.poNumber || undefined}
            label="PO Number"
            maxLength={20}
            name="poNumber"
            onChange={formController.handleChange}
            type="text"
            value={formController.values.poNumber}
          />
        </InputContainer>
        <InputContainer
          mobile={{ order: 3, basis: 100 }}
          desktop={{ order: 4, basis: 38 }}
        >
          <TextField
            required
            disabled={isDisabled || !canChangeAddress}
            errorMessage={formController.errors.customer || undefined}
            label="Company Name"
            maxLength={70}
            name="customer"
            onChange={formController.handleChange}
            type="text"
            value={formController.values.customer}
          />
        </InputContainer>
        <InputContainer
          mobile={{ order: 4, basis: 100 }}
          desktop={{ order: 4, basis: 60 }}
        >
          <TextField
            required
            disabled={isDisabled || !canChangeAddress}
            errorMessage={formController.errors.address || undefined}
            label="Address"
            maxLength={40}
            name="address"
            onChange={formController.handleChange}
            type="text"
            value={formController.values.address}
          />
        </InputContainer>
        <InputContainer
          mobile={{ order: 5, basis: 32 }}
          desktop={{ order: 5, basis: 28 }}
        >
          <TextField
            required
            disabled={isDisabled || !canChangeAddress}
            errorMessage={formController.errors.city || undefined}
            label="City"
            maxLength={40}
            name="city"
            onChange={formController.handleChange}
            type="text"
            value={formController.values.city}
          />
        </InputContainer>
        <InputContainer
          mobile={{ order: 6, basis: 32 }}
          desktop={{ order: 6, basis: 28 }}
        >
          <DropDown
            required
            label="State / Province"
            disabled={isDisabled || !canChangeAddress}
            errorMessage={formController.errors.state || undefined}
            onChange={option => handleChange('state', option)}
            options={states}
            value={formController.values.state}
          />
        </InputContainer>
        <InputContainer
          mobile={{ order: 7, basis: 32 }}
          desktop={{ order: 7, basis: 20 }}
        >
          <TextField
            required
            disabled={isDisabled || !canChangeAddress}
            errorMessage={formController.errors.zip || undefined}
            label="Zip / Postal Code"
            maxLength={10}
            name="zip"
            onChange={formController.handleChange}
            type="text"
            value={formController.values.zip}
          />
        </InputContainer>
        <InputContainer
          mobile={{ order: 8, basis: 32 }}
          desktop={{ order: 8, basis: 20 }}
        >
          <TextField
            required
            type="text"
            name="date"
            label="Date"
            value={date}
            disabled
            onChange={formController.handleChange}
            errorMessage={formController.errors.date || undefined}
          />
        </InputContainer>

        {shouldHideCountrySelector ? null : (
          <InputContainer
            mobile={{ order: 8, basis: 66 }}
            desktop={{ order: 8, basis: 20 }}
          >
            <DropDown
              required
              label="Country"
              disabled={isDisabled || !canChangeAddress || !shouldEnableCountry}
              errorMessage={formController.errors.country || undefined}
              onChange={option => handleChange('country', option)}
              options={CountryOptions}
              value={formController.values.country}
            />
          </InputContainer>
        )}
      </InputsRow>
    </div>
  );
};

export default CustomerDetails;
