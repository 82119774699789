/* eslint-disable @typescript-eslint/indent */
import get from 'lodash/get';
import styled from 'styled-components';
import React, { FunctionComponent } from 'react';

import ToRGB from '../../utils/color';
import formatters from '../../utils/formatters';

import StarSwitch from '../Inputs/StarSwitch';

import {
  useCurrentUserQuery,
  useFavoritePartMutation,
  useUnfavoritePartMutation,
} from '../../generated/graphql';
import useWindowSize from '../../hooks/useWindowSize';

import theme from '../../constants/theme';

export const Row = styled.div<{ verPadding: number; hasBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ verPadding }) => `${verPadding}rem 0`};
  border-bottom: ${({ theme: { colors }, hasBorder }) =>
    hasBorder ? `0.1rem solid ${colors.grayLight}` : undefined};
`;

const { r, g, b } = ToRGB(theme.colors.blueNeutral);
const MobileColumn = styled.div<{ hasBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  border-left: 0.4rem solid
    ${({ hasBorder }) =>
      hasBorder ? `rgba(${r}, ${g}, ${b}, 0.5)` : 'transparent'};
`;

const MobileContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 0.4rem solid transparent;
`;

interface MobilePartContentProps {
  flex: number;
  isNumber?: boolean;
  isUnitCost?: boolean;
}

const MobilePartContent = styled.span<MobilePartContentProps>`
  font-size: 1rem;
  line-height: normal;
  flex: ${({ flex }) => flex};
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors }, isNumber, isUnitCost }) => {
    if (isUnitCost) {
      return colors.grayADA;
    }
    return isNumber ? colors.blueDark : colors.grayDark;
  }};
`;

const MobileAlternatePartLabel = styled.span`
  flex: 1;
  font-size: 1rem;
  font-style: italic;
  line-height: normal;
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

interface PartColumnContentProps {
  flex: number;
  center?: boolean;
  isNumber?: boolean;
  isAlternate?: boolean;
}

const PartColumnContent = styled.div<PartColumnContentProps>`
  display: flex;
  font-size: 1rem;
  line-height: normal;
  flex-direction: column;
  flex: ${({ flex }) => flex};
  font-family: ${({ theme: { typography } }) => typography.museo};
  align-items: ${({ center }) => (center ? 'center' : undefined)};
  background-color: ${({ theme: { colors }, isNumber }) =>
    isNumber ? colors.grayLight : 'transparent'};
  color: ${({ theme: { colors }, isNumber }) =>
    isNumber ? colors.blueDark : colors.grayDark};
  border-left: 0.4rem solid
    ${({ isAlternate }) =>
      isAlternate ? `rgba(${r}, ${g}, ${b}, 0.5)` : 'transparent'};
  padding: ${({ isAlternate }) =>
    isAlternate ? '0.4rem 0.8rem 0.4rem 0.4rem' : '1rem 0.8rem 1rem 0.4rem'};
`;

const AlternatePartLabel = styled.span`
  font-size: 1rem;
  margin-top: 0.2rem;
  font-style: italic;
  line-height: normal;
  color: ${({ theme: { colors } }) => colors.blueDark};
`;

function getRequiredPartFields() {
  return {
    ATPstockCR: null,
    ATPstockSC: null,
    ATPstockSH: null,
    dealerPrice: null,
    description: null,
    height: null,
    length: null,
    price: null,
    specialPrice: null,
    weight: null,
    weightUnit: null,
    width: null,
  };
}

interface PartRowProps {
  alternateFor?: string;
  number: number;
  part: any;
  quantity: number;
  totalCost?: number;
  unitCost: number;
}

const PartRow: FunctionComponent<PartRowProps> = ({
  alternateFor,
  number,
  part,
  quantity,
  totalCost,
  unitCost,
}) => {
  const { width } = useWindowSize();
  const { data: currentUser } = useCurrentUserQuery();

  const [favoriteMutation] = useFavoritePartMutation({
    refetchQueries: ['currentUser', 'favoriteParts'],
  });
  const [unfavoriteMutation] = useUnfavoritePartMutation({
    refetchQueries: ['currentUser', 'favoriteParts'],
  });

  const favorites = get(currentUser, 'currentUser.favorites', []);
  const isPartFavorite = () =>
    favorites.findIndex((favorite: any) => favorite.number === number) !== -1;

  const handleClickStar = () => {
    if (!isPartFavorite()) {
      favoriteMutation({
        variables: { partNumber: `${number}` },
        optimisticResponse: {
          __typename: 'Mutation',
          favoritePart: {
            __typename: 'Part',
            number: `${number}`,
            ...getRequiredPartFields(),
            ...part,
          },
        },
      });
    } else {
      unfavoriteMutation({ variables: { partNumber: `${number}` } });
    }
  };

  const mobileBreakpoint = Number(theme.breakpoints.mobile.replace('px', ''));
  if (width && width < mobileBreakpoint) {
    return (
      <MobileColumn hasBorder={!!alternateFor}>
        <MobileContentRow>
          <MobilePartContent flex={2} isNumber>
            {number}
          </MobilePartContent>
          <MobilePartContent flex={0.4}>{quantity}</MobilePartContent>
          <MobilePartContent flex={1.2}>
            {formatters.currency(totalCost || quantity * unitCost)}
          </MobilePartContent>
          <MobilePartContent flex={0.4}>
            <StarSwitch
              isFavorite={isPartFavorite()}
              onClick={() => handleClickStar()}
            />
          </MobilePartContent>
        </MobileContentRow>
        {!!alternateFor && (
          <MobileContentRow style={{ marginTop: '0.4rem' }}>
            <MobileAlternatePartLabel>
              New Part Number for: {alternateFor}
            </MobileAlternatePartLabel>
          </MobileContentRow>
        )}
        <MobileContentRow style={{ marginTop: '0.2rem' }}>
          <MobilePartContent flex={1} isUnitCost>
            {formatters.currency(unitCost)}/unit
          </MobilePartContent>
        </MobileContentRow>
      </MobileColumn>
    );
  }

  return (
    <Row verPadding={0} style={{ marginTop: '0.8rem' }}>
      <PartColumnContent isNumber flex={1.5} isAlternate={!!alternateFor}>
        {number}
        {!!alternateFor && (
          <AlternatePartLabel>
            New Part Number for: {alternateFor}
          </AlternatePartLabel>
        )}
      </PartColumnContent>
      <PartColumnContent flex={0.875}>
        {formatters.currency(unitCost)}
      </PartColumnContent>
      <PartColumnContent flex={0.875}>{quantity}</PartColumnContent>
      <PartColumnContent flex={0.875}>
        {formatters.currency(totalCost || quantity * unitCost)}
      </PartColumnContent>
      <PartColumnContent center flex={0.875}>
        <StarSwitch
          isFavorite={isPartFavorite()}
          onClick={() => handleClickStar()}
        />
      </PartColumnContent>
    </Row>
  );
};

export default PartRow;
