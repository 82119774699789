interface ToastEventManager {
  list: Map<any, any>;
  on: (
    event: number,
    callback: (content: string, type: 'error' | 'info' | 'success') => void,
  ) => void;
  off: (event: number) => void;
  emit: (event: number, ...args: unknown[]) => void;
}

const eventManager: ToastEventManager = {
  list: new Map(),
  on(event, callback) {
    if (!this.list.has(event)) {
      this.list.set(event, []);
    }
    this.list.get(event).push(callback);
  },
  off(event) {
    this.list.delete(event);
  },
  emit(event, ...args) {
    if (this.list.has(event)) {
      this.list.get(event).forEach((callback: any) =>
        setTimeout(() => {
          callback(...args);
        }, 0),
      );
    }
  },
};

export default eventManager;
