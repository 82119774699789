import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import get from 'lodash/get';
import React, { FunctionComponent, useState, useEffect } from 'react';

import withLayout from '../components/withLayout';
import OpenOrderForm from '../containers/OpenOrderForm';
import TabsContainer from '../containers/TabsContainer';
import OrdersSection from '../containers/Sections/OrdersSection';
import OrdersSectionMobile from '../containers/Sections/OrdersSectionMobile';

import { useSearchOrdersQuery, IOrderStatus } from '../generated/graphql';

import useWindowSize from '../hooks/useWindowSize';

import theme from '../constants/theme';
import useIsMobile from '../hooks/useIsMobile';

const AllOrdersContainer = styled.main`
  display: flex;
  height: 100%;
`;

const DesktopOnly = styled.div`
  display: none;
  ${up('mobile')} {
    width: 50%;
    display: block;
  }
`;

const MobileOnly = styled.div`
  display: block;
  ${up('mobile')} {
    display: none;
  }
`;

const AllOrders: FunctionComponent = () => {
  const size = useWindowSize();
  const isMobile = useIsMobile();
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const [days, setDays] = useState<number>(7);

  const { data, loading } = useSearchOrdersQuery({
    fetchPolicy: 'network-only',
    variables: {
      status: IOrderStatus.OpenAndClosed,
      days,
    },
  });

  const allOrders = get(data, 'searchOrders', []) || [];

  useEffect(() => {
    if (!selected) {
      if (allOrders.length && allOrders[0] && allOrders[0].number) {
        const mobileBreakpoint = parseInt(
          get(theme, 'breakpoints.mobile', '').replace('px', ''),
          10,
        );
        if (!size || (size && size.width && size.width >= mobileBreakpoint)) {
          setSelected(allOrders[0].number);
        }
      }
    }
  }, [data, selected, size, allOrders]);

  console.log(isMobile);
  return (
    <AllOrdersContainer>
      <TabsContainer
        loading={loading}
        isSubpage={!!selected}
        redirectTo="/new-order"
        shouldShowCover={allOrders.length === 0 && !isMobile}
        onClickBack={() => setSelected(undefined)}
        title={selected ? 'All Orders' : undefined}
      >
        {selected ? (
          <OpenOrderForm
            key={selected}
            order={
              selected
                ? allOrders.find(order => order && order.number === selected)
                : null
            }
          />
        ) : (
          <MobileOnly>
            <OrdersSectionMobile
              loading={loading}
              orders={allOrders}
              selected={selected}
              setSelected={nr => setSelected(nr)}
              days={days}
              setDays={setDays}
            />
          </MobileOnly>
        )}
      </TabsContainer>
      <DesktopOnly>
        <OrdersSection
          loading={loading}
          orders={allOrders}
          selected={selected}
          setSelected={nr => setSelected(nr)}
          days={days}
          setDays={setDays}
          label="All Orders"
          placeholder="Search All Orders by Sales Order, PO, or Invoice number"
          displayInvoice={false}
        />
      </DesktopOnly>
    </AllOrdersContainer>
  );
};

export default withLayout(AllOrders);
