/* eslint-disable @typescript-eslint/indent */
import styled from 'styled-components';
import React, { FunctionComponent } from 'react';

import { ReactComponent as StarIcon } from '../../assets/images/star.svg';

type StarColorProps = {
  disabled?: boolean;
  switchStar?: boolean;
};

const StarColor = styled(({ disabled, switchStar, ...rest }) => (
  <StarIcon {...rest} />
))<StarColorProps>`
  path {
    fill: ${({ theme: { colors }, switchStar }) =>
      switchStar ? colors.yellowKobelco : colors.grayLight};
    stroke: ${({ theme: { colors }, switchStar }) =>
      switchStar ? colors.yellowKobelco : '#879AAB'};
  }
  pointer-events: ${({ disabled = false }) => (disabled ? 'none' : 'pointer')};
  cursor: ${({ disabled = false }) => (disabled ? 'none' : 'pointer')};
`;

interface StarSwitchProps {
  disabled?: boolean;
  isFavorite: boolean;
  onClick: () => void;
}

const StarSwitch: FunctionComponent<StarSwitchProps> = ({
  disabled,
  onClick,
  isFavorite,
}) => (
  <StarColor switchStar={isFavorite} onClick={onClick} disabled={disabled} />
);

export default StarSwitch;
