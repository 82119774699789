import { FormikValues } from 'formik';
import { useMemo, useEffect, useRef } from 'react';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import { getUser, saveUser } from '../utils/localStorage';
import { useGetShippingMethodsQuery } from '../generated/graphql';
import { humanizeSOType } from '../utils/humanizeSOType';
import { Option, dealerTypes, CompoundOption } from '../constants';
import { parseShippingMethodName } from '../utils/parseShippingMethodName';

// Custom Hook
const useShippingMethods = (formController: FormikValues, prefill = true) => {
  const user = getUser();
  const previousOrderTypeRef = useRef(formController.values.orderType);

  const {
    data: shippingMethodsData,
    loading: shippingMethodsLoading,
  } = useGetShippingMethodsQuery({
    skip: !user,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (shippingMethodsData) {
      let dealerType = null;
      if (shippingMethodsData?.shippingMethods?.[0]?.SalesOffice === 'NCG') {
        dealerType = dealerTypes.crane;
      }
      if (shippingMethodsData?.shippingMethods?.[0]?.SalesOffice === 'NCD') {
        dealerType = dealerTypes.excavator;
      }
      if (dealerType) {
        saveUser({ ...user, ...{ dealerType } });
      }
    }
  }, [shippingMethodsData, user]);

  const rawResults = useMemo(() => shippingMethodsData?.shippingMethods, [
    shippingMethodsData,
  ]);

  // compute service order types
  const SOTypes = useMemo(() => {
    const methods: Array<CompoundOption> = [];
    shippingMethodsData?.shippingMethods?.forEach(value => {
      if (
        user?.dealerType === dealerTypes.excavator &&
        value?.SOType === 'ZAB4'
      ) {
        return;
      }
      if (value && value?.SOType) {
        const optionIndex = methods.findIndex(
          method => method.label === humanizeSOType(value?.SOType as string),
        );
        if (optionIndex < 0) {
          methods.push({
            label: humanizeSOType(value?.SOType),
            value: [value?.SOType],
          });
        } else {
          methods[optionIndex] = {
            ...methods[optionIndex],
            value: [
              ...new Set([
                ...(methods[optionIndex].value as Array<string>),
                value?.SOType,
              ]),
            ],
          };
        }
      }
    });

    return methods;
  }, [shippingMethodsData, user]);

  // compute shipping methods
  const shippingMethods = useMemo(() => {
    const orderTypes: Array<string> =
      formController?.values?.orderType?.split(',') || [];
    const mappedShippingMethods: {
      [key: string]: Array<Option>;
    } = shippingMethodsData?.shippingMethods?.reduce(
      (accumulator: any, value) => {
        if (
          user?.dealerType === dealerTypes.excavator &&
          value?.SOType === 'ZAB4'
        ) {
          return accumulator;
        }
        if (value?.SOType && !accumulator[value?.SOType]) {
          accumulator[value?.SOType] = [];
        }
        if (value && value?.SOType) {
          accumulator[value?.SOType].push({
            label:
              parseShippingMethodName(value?.ShippingMethod as string) +
              ` (${value?.ConsigneeCity})`,
            value: value?.ConsigneeCode,
          });
        }

        return accumulator;
      },
      {},
    );

    return uniqBy(
      flatten(orderTypes.map(tp => mappedShippingMethods?.[tp])).filter(
        Boolean,
      ),
      'value',
    );
  }, [shippingMethodsData, formController.values.orderType, user]) as Option[];

  // Extract selected shipping method
  const selectedMethod = useMemo(() => {
    return formController.values.shippingMethod
      ? shippingMethodsData?.shippingMethods?.find(method => {
          return method?.ConsigneeCode === formController.values.consigneeCode;
        })
      : undefined;
  }, [
    formController.values.shippingMethod,
    formController.values.consigneeCode,
    shippingMethodsData,
  ]);

  // Check if address fields can be changed based on selected shipping method
  const canChangeAddress = useMemo(() => {
    return (
      selectedMethod?.ChangeFlag !== 'X' ||
      ['ZAB3', 'ZAB4'].indexOf(selectedMethod?.SOType || '') < 0
    );
  }, [selectedMethod]);

  const hasAddressInformation = useMemo(() => {
    return (
      formController.values.address &&
      formController.values.customer &&
      formController.values.city &&
      formController.values.state &&
      formController.values.country &&
      formController.values.zip
    );
  }, [
    formController.values.address,
    formController.values.customer,
    formController.values.city,
    formController.values.state,
    formController.values.country,
    formController.values.zip,
  ]);

  // Reset shipping method when order type changes
  useEffect(() => {
    if (previousOrderTypeRef.current === formController.values.orderType) {
      return;
    }
    previousOrderTypeRef.current = formController.values.orderType;

    if (!selectedMethod) {
      formController.setValues(
        {
          ...formController.values,
          shippingMethodRaw: undefined,
          shippingMethod: undefined,
        },
        false,
      );
    }
  }, [formController.values.orderType, selectedMethod]);

  // Pre-fill address when order type and shipping method is selected
  useEffect(() => {
    if (selectedMethod && prefill) {
      let overrides: any = {
        customer: formController.values.customer
          ? formController.values.customer
          : `${selectedMethod?.ConsigneeName1}${selectedMethod?.ConsigneeName2}`,
        freightCode: `${selectedMethod?.ConsigneeCode?.slice(-2)}`,
      };

      if (!canChangeAddress || !hasAddressInformation) {
        overrides = {
          ...overrides,
          customer: `${selectedMethod?.ConsigneeName1}${selectedMethod?.ConsigneeName2}`,
          address: selectedMethod?.ConsigneeStreet,
          city: selectedMethod?.ConsigneeCity,
          state: selectedMethod?.ConsigneeState,
          country: selectedMethod?.ConsigneeCountry,
          zip: selectedMethod?.ConsigneePostalCode,
        };
      }

      setTimeout(() => {
        formController.setValues(
          {
            ...formController.values,
            ...overrides,
            shippingMethodRaw: selectedMethod,
          },
          false,
        );
      });
    }
    // adding formController causes an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMethod]);

  return {
    soTypes: SOTypes.map(type => ({
      ...type,
      value: type.value.join(','),
    })) as Array<Option>,
    loading: shippingMethodsLoading && !shippingMethodsData,
    shippingMethods,
    canChangeAddress,
    selectedMethod,
    results: rawResults,
  };
};

export default useShippingMethods;
