/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import get from 'lodash/get';

import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import { useHistory, RouteComponentProps } from 'react-router-dom';
import React, { useState, FunctionComponent, useEffect } from 'react';

import { ReactComponent as ChevronIcon } from '../assets/images/chevron.svg';

import Title from '../components/Title';
import ActionBar from '../components/ActionBar';
import withLayout from '../components/withLayout';
import useNewOrderForm from '../hooks/useNewOrderForm';
import TabsContainer from '../containers/TabsContainer';
import NewOrderModal from '../components/Modals/NewOrder';
import DeliveryOption from '../components/DeliveryOption';
import NewOrdersForm from '../components/Orders/NewOrders';
import FullscreenSpinner from '../components/FullscreenSpinner';
import SearchSection from '../containers/Sections/SearchSection';
import OrderSuccessModal from '../components/Modals/OrderSuccess';
import InventoryStatus, {
  InventoryStatusMobile,
} from '../components/InventoryStatus';

import useActionBar from '../hooks/useActionBar';
import useWindowSize from '../hooks/useWindowSize';

import theme from '../constants/theme';
import formatters from '../utils/formatters';
import useEventListener from '../hooks/useEventListener';
import { isReadyToSubmitOrderChecker } from '../utils/isReadyToSubmitOrderChecker';
import useAlerts from '../hooks/useAlerts';

const NewOrdersContainer = styled.main`
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  ${up('mobile')} {
    position: static;
    flex-direction: row;
  }
`;

const Column = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface MobileFavoritesSliderProps {
  isOpen?: boolean;
  isHidden?: boolean;
  isSecondPanel?: boolean;
}

const MobileFavoritesSlider = styled.div<MobileFavoritesSliderProps>`
  z-index: 10;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
  transition: all 0.2s;
  top: ${({ isOpen }) => (isOpen ? '60px' : '100%')};
  height: ${({ isOpen }) => (isOpen ? '100%' : 0)};
  overflow: hidden;
  position: absolute;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  ${up('mobile')} {
    width: 50%;
    display: block;
    position: relative;
    flex-direction: row;
    top: auto;
    height: auto;
    overflow: visible;
  }
`;

const FavoritePartsButton = styled.div<MobileFavoritesSliderProps>`
  cursor: pointer;
  background-color: ${({ theme: { colors } }) => colors.white};
  top: ${({ isOpen }) => (isOpen ? '51px' : 'auto')};
  position: absolute;
  bottom: ${({ isOpen, isSecondPanel }) => {
    if (isOpen) {
      return 'auto';
    }
    return isSecondPanel ? '65px' : '0';
  }};
  left: 0;
  right: 0;
  z-index: 11;
  padding: 0 1.5rem;
  min-height: 5rem;
  transition: all 0.2s;
  border-top: 0.1rem solid ${({ theme: { colors } }) => colors.grayLight};
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  ${up('mobile')} {
    display: none;
  }
`;

type ChevronWDirectionProps = {
  showItems: boolean;
};
const ChevronWDirection = styled(({ showItems, ...rest }) => (
  <ChevronIcon {...rest} />
))<ChevronWDirectionProps>`
  margin-left: 0.8rem;
  transition: transform 0.12s ease-in-out;
  transform: ${({ showItems }) => (showItems ? 'rotate(0)' : 'rotate(180deg)')};
`;

const FavoritePartsTitle = styled(Title)`
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-style: normal;
  font-weight: normal;
  color: ${({ theme: { colors } }) => colors.black};
  margin-left: 1.6rem;
`;

const customerDetailsFields = [
  'orderType',
  'shippingMethod',
  'freightCode',
  'customer',
  'address',
  'poNumber',
  'state',
  'zip',
  'date',
];

const NewOrders: FunctionComponent<RouteComponentProps> = ({ location }) => {
  const [showCover, setShowCover] = useState<boolean>(true);
  const [toggleFav, setToggleFav] = useState<boolean>(false);
  const [isSearchingPart, setIsSearchingPart] = useState<boolean>(false);

  useEventListener(
    'AddedToOrder',
    (_: any) => {
      if (toggleFav) {
        setToggleFav(!toggleFav);
      }
    },
    toggleFav,
  );

  const mobileBreakpoint = Number(theme.breakpoints.mobile.replace('px', ''));
  const [formik, current, send, orderNumber] = useNewOrderForm(
    {
      notes: '',
      address: '',
      city: '',
      customer: '',
      date: formatters.date(new Date(), '/'),
      freightCode: '',
      orderType: null,
      parts: [],
      poNumber: '',
      shippingMethod: null,
      shippingMethodRaw: null,
      state: null,
      zip: '',
      country: '',
    },
    {
      showCover,
      savedOrder: undefined,
    },
  );
  const { width } = useWindowSize();

  useAlerts();

  useEffect(() => {
    let isValid = true;
    customerDetailsFields.forEach(field => {
      if (
        !formik.values[field] ||
        !formik.values[field].length ||
        formik.errors[field]
      ) {
        isValid = false;
      }
    });
    const isMobile = Boolean(width && width < mobileBreakpoint);
    if (isValid && current.matches('customerDetails') && !isMobile) {
      send('CONTINUE');
    }
  }, [formik.values, formik.errors, width, mobileBreakpoint, current, send]);

  const history = useHistory();
  const historyShowCover = get(history, 'location.state.showCover');

  useEffect(() => {
    if (current.matches('inactive') && historyShowCover === false) {
      history.replace('/new-order');
      send('START');
    }
  }, [current, history, historyShowCover, send]);

  useEffect(() => {
    if (location && location.state) {
      setShowCover(location.state.showCover);
    }
  }, [location]);

  const loadingText = (() => {
    let text = '';
    if (current.matches({ inventory: 'loading' })) {
      text = 'Checking Status on Inventory';
    } else {
      text = 'Processing your Order';
    }
    return text;
  })();

  const displayNewOrderModal = (mobile?: boolean) => {
    if (width) {
      if (mobile) {
        return width < mobileBreakpoint && current.matches('shipping');
      }
      return width >= mobileBreakpoint && current.matches('shipping');
    }
    return false;
  };

  const displayInventoryStatusModal = (mobile?: boolean) => {
    if (width) {
      if (mobile) {
        return (
          width < mobileBreakpoint && current.matches({ inventory: 'stock' })
        );
      }
      return (
        width >= mobileBreakpoint && current.matches({ inventory: 'stock' })
      );
    }
    return false;
  };

  const isReadyToSubmit = () => {
    return isReadyToSubmitOrderChecker({
      current,
      customerDetailsFields,
      formik,
      mobileBreakpoint,
      send,
      width,
    });
  };

  const EditingActionBar = useActionBar(
    <ActionBar
      secondaryAction={{
        label: 'Save Cart',
        onClick: () => send('SAVE'),
      }}
      primaryAction={{
        onClick: () => isReadyToSubmit() && send('CONTINUE'),
        label:
          current.value === 'customerDetails' &&
          width &&
          width < mobileBreakpoint
            ? 'Add Parts'
            : 'Continue',
      }}
    />,
    current.value === 'customerDetails' ||
      current.value === 'editingAll' ||
      current.value === 'stockHasChanged',
  );

  const stockItems = get(current, 'event.parts', []);

  // TODO useCallback on these two methods
  // onOpenSearch
  // onCloseSearch

  const stateValue: any = current?.value;
  const isLoading =
    stateValue?.inventory === 'loading' ||
    stateValue === 'saving' ||
    stateValue === 'submitting';

  return (
    <>
      <FullscreenSpinner
        description={loadingText}
        isOpen={
          isLoading ||
          current.matches([{ inventory: 'loading' }, 'saving', 'submitting'])
        }
      />
      <InventoryStatus
        items={stockItems}
        onContinue={() => send('CONTINUE')}
        isOpen={displayInventoryStatusModal()}
        onEditParts={() => send('EDIT_PARTS', { parts: stockItems })}
      />
      <InventoryStatusMobile
        items={stockItems}
        onContinue={() => send('CONTINUE')}
        isOpen={displayInventoryStatusModal(true)}
        onEditParts={() => send('EDIT_PARTS', { parts: stockItems })}
      />
      <NewOrderModal
        form={formik}
        onDoneforNow={() => send('SAVE')}
        onSubmit={() => send('CONTINUE')}
        onAddMoreParts={() => send('ADD_MORE_PARTS')}
        isOpen={displayNewOrderModal()}
      />
      <OrderSuccessModal
        orderNumber={orderNumber}
        isOpen={current.matches('finish')}
        onContinue={() => send('CONTINUE')}
      />
      <DeliveryOption
        form={formik}
        onDoneforNow={() => send('SAVE')}
        onSubmit={() => send('CONTINUE')}
        onAddMoreParts={() => send('ADD_MORE_PARTS')}
        isOpen={displayNewOrderModal(true)}
      />
      <NewOrdersContainer id="new-order-container">
        <TabsContainer
          onStartNewOrder={() => send('START')}
          shouldShowCover={current.matches('inactive')}
        >
          <Column>
            <NewOrdersForm
              send={send}
              formik={formik}
              state={current}
              stockItems={stockItems}
              setAddedParts={() => send('CONTINUE')}
              reviewOrder={displayNewOrderModal(true)}
              onOpenSearch={() => setIsSearchingPart(true)}
              onCloseSearch={() => setIsSearchingPart(false)}
              isCustomerDataCollapsed={current.value !== 'customerDetails'}
              setCustomerDataCollapsed={() => send('BACK')}
            />
            {!isSearchingPart && EditingActionBar}
          </Column>
        </TabsContainer>
        <FavoritePartsButton
          tabIndex={0}
          isSecondPanel
          isOpen={toggleFav}
          onClick={() => setToggleFav(!toggleFav)}
          isHidden={
            isSearchingPart ||
            current.value === 'inactive' ||
            current.value === 'customerDetails' ||
            current.value === 'shipping' ||
            current.value === 'saving' ||
            current.value === 'submitting' ||
            current.value === 'finish'
          }
        >
          <FavoritePartsTitle label="Favorite Parts" />
          <ChevronWDirection showItems={toggleFav} />
        </FavoritePartsButton>
        <MobileFavoritesSlider
          isOpen={toggleFav}
          isHidden={!current.matches(['editingAll'])}
        >
          <SearchSection />
        </MobileFavoritesSlider>
      </NewOrdersContainer>
    </>
  );
};

export default withLayout(NewOrders);
