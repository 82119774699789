import Modal from 'react-modal';
import styled from 'styled-components';
import React, { FunctionComponent, useState, useEffect } from 'react';

import ToRGB from '../../utils/color';
import theme from '../../constants/theme';

import './index.css';

Modal.setAppElement('#root');

const { r, g, b } = ToRGB(theme.colors.blueKobelco);
const customStyles: Modal.Styles = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'transparent',
  },
  overlay: {
    backgroundColor: `rgba(${r}, ${g}, ${b}, 0.8)`,
    zIndex: 1000,
  },
};

const SpinnerTitle = styled.h1`
  margin: 0;
  font-size: 2.4rem;
  font-weight: normal;
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { typography } }) => typography.museo};
`;

const Spinner = styled.div`
  width: 12.6rem;
  height: 12.6rem;
  border-radius: 50%;
  margin-bottom: 4rem;
  animation: loader 1.1s infinite linear;
  -webkit-animation: loader 1.1s infinite linear;
  border-top: 1.2rem solid ${({ theme: { colors } }) => colors.white};
  border-right: 1.2rem solid ${({ theme: { colors } }) => colors.white};
  border-bottom: 1.2rem solid ${({ theme: { colors } }) => colors.white};
  border-left: 1.2rem solid transparent;
`;

interface FullscreenSpinnerProps {
  isOpen?: boolean;
  description?: string;
  onClose?: () => void;
  afterOpenModal?: () => void;
}

const FullscreenSpinner: FunctionComponent<FullscreenSpinnerProps> = ({
  description,
  isOpen = false,
  onClose = () => {},
  afterOpenModal = () => {},
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(isOpen);
  useEffect(() => {
    setModalIsOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      onRequestClose={onClose}
      onAfterOpen={afterOpenModal}
    >
      <Spinner className="loader" />
      <SpinnerTitle>
        {description || 'Checking Status on Inventory'}
      </SpinnerTitle>
    </Modal>
  );
};

export default FullscreenSpinner;
