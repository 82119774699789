import React, { FunctionComponent } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import HttpsRedirect from 'react-https-redirect';
import { ErrorBoundary, Provider } from '@rollbar/react';

import Routes from './Routes';
import constants from './constants';
import client from './utils/apolloClient';
import rollbar from './utils/rollbar';

const isDevelopment = process.env.NODE_ENV === 'development';

const App: FunctionComponent = () => {
  return (
    <HttpsRedirect disabled={isDevelopment}>
      <Provider instance={rollbar}>
        <ErrorBoundary>
          <ApolloProvider client={client}>
            <ThemeProvider theme={constants.theme}>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </ThemeProvider>
          </ApolloProvider>
        </ErrorBoundary>
      </Provider>
    </HttpsRedirect>
  );
};

export default App;
