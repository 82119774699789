import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const PrimaryButton = styled.button`
  background: ${({ theme: { colors } }) => colors.blueKobelco};
  border: none;
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;
`;

export const TertiaryButton = styled.button`
  background: ${({ theme: { colors } }) => colors.white};
  border: none;
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.blueNeutral};
  border: ${({ theme: { colors } }) => `0.1rem solid ${colors.blueNeutral}`};
  cursor: pointer;
`;

export const FlatButton = styled.button`
  background: transparent;
  border: ${({ theme: { colors } }) => `0.1rem solid ${colors.white}`};
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.white};
  padding: 0.6rem 2rem;
  font-weight: light;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: background 0.1s, color 0.1s;
  &:hover {
    background: ${({ theme: { colors } }) => colors.white};
    color: ${({ theme: { colors } }) => colors.blueKobelco};
  }
`;

interface ColorProps {
  color: string;
  hoverColor: string;
  borderColor?: string;
  disabled?: boolean;
  center?: boolean;
}
export const FlatButtonColor = styled(FlatButton)<ColorProps>`
  color: ${({ color }) => color};
  border: ${({ color, borderColor }) => `0.1rem solid ${borderColor || color}`};
  &:hover {
    background: ${({ hoverColor }) => hoverColor};
    border: ${({ hoverColor }) => `0.1rem solid ${hoverColor}`};
    color: ${({ theme: { colors } }) => colors.white};
  }
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
  `}
  ${({ center }) =>
    center
      ? `
    display: flex;
    align-items: center;
    justify-content: center;
  `
      : ''}
`;

export const RegularButton = styled.button`
  background-color: ${({ theme: { colors } }) => colors.blueDark};
  color: ${({ theme: { colors } }) => colors.white};
  border: none;
  font-family: ${({ theme: { typography } }) => typography.museo};
  cursor: pointer;
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
  transition: background-color 0.1s, color 0.1s;
`;

export const TextButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;
