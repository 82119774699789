/* eslint-disable @typescript-eslint/indent */
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import React, { useRef, useState, useEffect, FunctionComponent } from 'react';

import SearchResultsDropDown from '../Inputs/SearchResultsDropDown';

import { ReactComponent as SearchIconSVG } from '../../assets/images/search.svg';

import useDebounce from '../../hooks/useDebounce';
import useClickOutside from '../../hooks/useClickOutside';

import { useSearchPartsQuery } from '../../generated/graphql';

import ToRGB from '../../utils/color';
import noop from '../../utils/noop';

interface SearchResultContainerProps {
  isOpen?: boolean;
  hasError?: boolean;
  shadowPlaceholder?: string;
}

const Container = styled.div<SearchResultContainerProps>`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.grayLight};
  position: relative;
  display: flex;
  z-index: ${undefined};
  margin-bottom: 1rem;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '4rem')};
  flex-direction: column;

  ${up('mobile')} {
    flex-direction: row;
    z-index: ${({ isOpen }) => (isOpen ? 15 : undefined)};
    height: ${({ isOpen }) => (isOpen ? '5rem' : '4rem')};
  }
`;

const SearchIcon = styled(SearchIconSVG)`
  position: absolute;
  height: 50%;
  transform: translateY(-50%);
  left: 0.8rem;
  pointer-events: none;
  height: 2rem;
  width: 2rem;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 0.35rem;
  transition: all 0.1s;
  top: 1.8rem;
  ${up('mobile')} {
    top: 50%;
  }
`;

const SearchInput = styled.input<SearchResultContainerProps>`
  flex: 1;
  letter-spacing: 0.06rem;
  background: transparent;
  border: none;
  position: relative;
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.blueDark};
  font-size: 1rem;
  height: 4rem;
  padding-left: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: ${({ theme, hasError }) =>
    hasError ? `2px solid ${theme.colors.red}` : undefined};
  ${up('mobile')} {
    padding-top: 0;
    padding-bottom: 0;
  }
  &::placeholder {
    color: ${({ theme: { colors } }) => colors.blueNeutral};
  }
  &:focus {
    padding-top: 1rem;
    padding-bottom: 1rem;
    ${up('mobile')} {
      padding-top: 0;
      padding-bottom: 0;
    }
    outline: none;
    margin: 0 1rem;
    background-color: ${({ theme: { colors } }) => colors.white};
    margin-top: 1rem;
  }
  &:focus ~ .search-bar-icon {
    left: 1.8rem;
    top: 2.8rem;
    ${up('mobile')} {
      top: calc(50% + 0.5rem);
    }
  }
`;

const SearchResultContainer = styled.div<SearchResultContainerProps>`
  top: 100%;
  left: 0;
  right: 0;
  background-color: ${({ theme: { colors } }) => colors.grayLight};
  visibility: hidden;
  ${up('mobile')} {
    position: absolute;
    border-bottom: ${({ theme: { colors } }) =>
      `0.1rem solid ${colors.grayLight}`};
    border-left: ${({ theme: { colors } }) =>
      `0.1rem solid ${colors.grayLight}`};
    border-right: ${({ theme: { colors } }) =>
      `0.1rem solid ${colors.grayLight}`};
    box-shadow: ${({ theme: { colors } }) => {
      const { r, g, b } = ToRGB(colors.blueDark);
      return `0px 41px 54px rgba(${r}, ${g}, ${b}, 0.08), 0px 41px 54px rgba(${r}, ${g}, ${b}, 0.14)`;
    }};
  }
  position: ${({ isOpen }) => (isOpen ? 'relative' : 'absolute')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;

interface SearchPartProps {
  addToCart: (items: any[]) => void;
  clearDefaultSearch?: () => void;
  defaultSearch?: string;
  editingParts?: any[];
  hasError?: boolean;
  onClose: () => void;
  onOpen: () => void;
  parts: any[];
  placeholder?: string;
  viewOnly?: boolean;
}

const Search: FunctionComponent<SearchPartProps> = ({
  addToCart,
  clearDefaultSearch = noop,
  defaultSearch = '',
  editingParts,
  hasError,
  onClose = noop,
  onOpen = noop,
  parts = [],
  placeholder,
  viewOnly = false,
}) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    if (editingParts) {
      editingParts.forEach(editPart => {
        if (parts.find(part => editPart === part.number) !== undefined) {
          setIsOpen(true);
          onOpen();
        }
      });
    }
    // TODO Set the array dep and test after creating none mutatable callback functions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingParts]);

  useEffect(() => {
    if (defaultSearch && defaultSearch.length) {
      setSearchVal(defaultSearch);
      setIsOpen(true);
      clearDefaultSearch();
    }
  }, [defaultSearch, clearDefaultSearch]);

  useClickOutside([wrapperRef], !isOpen, () => {
    onClose();
    setIsOpen(false);
  });

  const searchTerm = useDebounce(searchVal, 300);
  const { loading, data: partData } = useSearchPartsQuery({
    variables: { parts: [searchTerm], exact: false },
    skip: searchTerm.length < 2,
    fetchPolicy: 'network-only',
  });

  return (
    <Container isOpen={isOpen} ref={wrapperRef}>
      <SearchInput
        value={searchVal}
        hasError={hasError}
        placeholder={placeholder || 'Add a Part'}
        onChange={({ target: { value } }) => setSearchVal(value.toUpperCase())}
        onFocus={() => {
          setIsOpen(true);
          onOpen();
        }}
      />
      <SearchIcon className="search-bar-icon" />
      <SearchResultContainer isOpen={isOpen} className="search-results-panel">
        <SearchResultsDropDown
          isOpen={isOpen}
          loading={loading}
          viewOnly={viewOnly}
          selectedItems={parts}
          items={
            searchTerm.length < 2
              ? []
              : (partData && partData.searchParts) || []
          }
          addToCart={partsAdded => {
            setIsOpen(false);
            onClose();
            setSearchVal('');
            addToCart(partsAdded);
          }}
        />
      </SearchResultContainer>
    </Container>
  );
};

export default Search;
