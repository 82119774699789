import { IPart } from '../generated/graphql';

export function isPartOrderable(part: IPart, dealerType: string): boolean {
  let canReturnPart = true;
  if (
    (part?.notServiceFlag?.toLowerCase() === 'x' &&
      dealerType.toLowerCase() !== 'excavator') ||
    part?.notServiceFlag === '1' ||
    part?.notServiceFlag === '2'
  ) {
    canReturnPart = false;
  }

  return canReturnPart;
}
