import isEqual from 'lodash/isEqual';
import { useEntity } from 'simpler-state';
import { userStore } from '../store/user';

function useUser() {
  const localUser = useEntity(
    userStore,
    v => v,
    (a, b) => isEqual(a, b),
  );
  return localUser;
}

export default useUser;
