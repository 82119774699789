import React, { useState, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { ReactComponent as ChevronIcon } from '../../assets/images/chevron.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface CollapsedProps {
  collapsed?: boolean;
}

const Header = styled.div<CollapsedProps>`
  display: ${({ collapsed }) => (collapsed ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3.8rem;
  border-top: 0.1rem solid ${({ theme: { colors } }) => colors.grayLight};
  border-bottom: 0.1rem solid ${({ theme: { colors } }) => colors.grayLight};
  cursor: pointer;
  margin-bottom: 1rem;
  ${up('mobile')} {
    display: none;
  }
`;

const Title = styled.div`
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-style: normal;
  font-weight: normal;
  color: ${({ theme: { colors } }) => colors.black};
`;

const Arrow = styled(({ collapsed, ...rest }) => <ChevronIcon {...rest} />)<
  CollapsedProps
>`
  transform: ${({ collapsed }) =>
    !collapsed ? 'rotate(180deg)' : 'rotate(0)'};
  transition: all 0.1s;
`;

const MobileBody = styled.span`
  transition: height 0.1s;
  ${up('mobile')} {
    display: none;
  }
  &:first-child {
    height: 100%;
    flex: 1;
    ${up('mobile')} {
      height: auto;
      flex: auto;
    }
  }
`;

const DesktopBody = styled.span`
  transition: height 0.1s;
  display: none;
  ${up('mobile')} {
    display: block;
  }
  &:first-child {
    ${up('mobile')} {
      height: 100%;
      flex: 1;
    }
  }
`;

interface CollapsibleProps {
  title?: string;
  startCollapsed?: boolean;
  setCollapsed?: (a: boolean) => void;
  collapsed?: boolean;
}

const Collapsible: FunctionComponent<CollapsibleProps> = ({
  title,
  children,
  startCollapsed = false,
  collapsed = false,
  setCollapsed = val => null,
}) => {
  const [localCollapsed, setLocalCollapsed] = useState(startCollapsed);
  useEffect(() => {
    setLocalCollapsed(collapsed);
  }, [collapsed, setCollapsed]);
  return (
    <Container>
      <Header
        collapsed={localCollapsed}
        onClick={() => {
          setLocalCollapsed(!localCollapsed);
          setCollapsed(!localCollapsed);
        }}
      >
        <Title>{title}</Title>
        <Arrow collapsed={localCollapsed} />
      </Header>
      <MobileBody>{!localCollapsed && children}</MobileBody>
      <DesktopBody>{children}</DesktopBody>
    </Container>
  );
};

export default Collapsible;
