import styled from 'styled-components';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { FlatButtonColor } from '../styled/buttons';

import { ReactComponent as Plus } from '../../assets/images/plus-white.svg';
import { ReactComponent as PlusDark } from '../../assets/images/plus-dark.svg';

import { Toast } from '../../utils/toast';
import eventManager from '../../utils/eventManager';
import theme from '../../constants/theme';

const Container = styled.div<{ top: number; toastType: string }>`
  height: 4rem;
  background-color: red;
  position: absolute;
  top: ${({ top }) => `${top}rem`};
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 1.75rem 3.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme: { colors }, toastType }) =>
    colors.alerts[toastType]};
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.17);
`;

const Message = styled.div<{ toastType: string }>`
  flex: 1;
  color: ${({ theme: { colors }, toastType }) =>
    toastType === 'announcement' ? colors.black : colors.white};
  font-size: 1.5rem;
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-style: normal;
  font-weight: 500;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div<{ toastType: string }>`
  color: ${({ theme: { colors }, toastType }) =>
    toastType === 'announcement' ? colors.black : colors.white};
  font-size: 1.5rem;
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-style: normal;
  font-weight: 500;
`;

const Subtitle = styled.div<{ toastType: string }>`
  color: ${({ theme: { colors }, toastType }) =>
    toastType === 'announcement' ? colors.black : colors.white};
  font-size: 1rem;
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-style: normal;
  font-weight: 500;
`;

const ButtonLabel = styled.span`
  margin-left: 0.7rem;
`;

const Centered = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ACTION = {
  SHOW: 0,
  CLEAR: 1,
};

const AlertContainer: FunctionComponent = () => {
  const [toasts, setToasts]: [any, any] = useState([]);
  useEffect(() => {
    eventManager.on(
      ACTION.SHOW,
      (
        content: string,
        type: 'error' | 'info' | 'success' | 'announcement',
        subcontent?: string,
      ) => {
        setToasts((t: Array<Toast>) => [...t, { content, type, subcontent }]);
      },
    );
    eventManager.on(ACTION.CLEAR, () => {
      setToasts([]);
    });
  }, []);

  return (
    <>
      {toasts.map((toast: Toast, index: number) => (
        <Container
          top={index * 7.5}
          toastType={toast.type}
          key={`toast - ${index} `}
        >
          {toast.subcontent ? (
            <Column>
              <Title toastType={toast.type}>{toast.content}</Title>
              <Subtitle toastType={toast.type}>{toast.subcontent}</Subtitle>
            </Column>
          ) : (
            <Message toastType={toast.type}>{toast.content}</Message>
          )}
          <FlatButtonColor
            center
            color={theme.colors.black}
            hoverColor={theme.colors.black}
            borderColor={theme.colors.black}
            onClick={() => {
              setToasts([
                ...toasts.slice(
                  0,
                  toasts.findIndex(
                    (tst: Toast) => tst.content === toast.content,
                  ),
                ),
                ...toasts.slice(
                  toasts.findIndex(
                    (tst: Toast) => tst.content === toast.content,
                  ) + 1,
                ),
              ]);
            }}
          >
            <Centered>x</Centered>
            <ButtonLabel>Close</ButtonLabel>
          </FlatButtonColor>
        </Container>
      ))}
    </>
  );
};

export default AlertContainer;
