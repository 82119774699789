import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import TabsMenuDesktop from './TabsMenuDesktop';
import TabsMenuMobile from './TabsMenuMobile';

const TabsMenuDesktopShow = styled.div`
  display: none;
  ${up('mobile')} {
    display: block;
  }
`;

const TabsMenuMobileShow = styled.div`
  display: block;
  ${up('mobile')} {
    display: none;
  }
`;

interface Props {
  shouldShowCover?: boolean;
  isSubpage?: boolean;
  onClickBack?: any;
  title?: string | null;
}

const Tabs: FunctionComponent<Props> = ({
  isSubpage = false,
  onClickBack = () => {},
  title = null,
}) => {
  return (
    <>
      <TabsMenuDesktopShow>
        <TabsMenuDesktop />
      </TabsMenuDesktopShow>
      <TabsMenuMobileShow>
        <TabsMenuMobile
          isSubpage={isSubpage}
          onClickBack={onClickBack}
          title={title}
        />
      </TabsMenuMobileShow>
    </>
  );
};

export default Tabs;
