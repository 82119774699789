// DISCLAIMER: Color should stay in HEX or RGB format.
// other colors are computed from these values
const theme = {
  colors: {
    red: '#FF4848',
    background: '#FFFFFF',
    blueKobelco: '#009FB3',
    blueNeutral: '#879AAB',
    blueDark: '#2B557C',
    blueLight: '#DBE6F0',
    blueUltraLight: '#F9FBFD',
    yellowKobelco: '#F5C142',
    yellow20: '#E8DEC4',
    white: '#FFFFFF',
    whiteSmoke: '#FAFAFA',
    gray: '#C8C8C8',
    grayADA: '#999999',
    grayLight: '#F5F5F5',
    grayDark: '#626262',
    grayActive: '#ECECEC',
    black: '#333333',
    alerts: {
      error: '#FF0000',
      success: '#00cc00',
      info: '#2B557C',
      announcement: '#F5C142',
    },
  },
  typography: {
    museo: 'Museo Sans',
    ptsans: 'PT Sans',
  },
  breakpoints: {
    mobile: '800px',
    tablet: '920px',
    desktop: '1230px',
  },
};

export default theme;
