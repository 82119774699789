import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import ModalLayout from './Layout';
import { ReactComponent as logo } from '../../assets/images/logo-dark.svg';
import GrayLine from '../Line';
import { PrimaryButton } from '../styled/buttons';
import formatters from '../../utils/formatters';

interface LineProps {
  gray?: boolean;
  margintop?: boolean;
  uppercase?: boolean;
  large?: boolean;
}

interface GridProps {
  justify?: string;
  flex?: string;
  margintop?: boolean;
  full?: boolean;
  padded?: boolean;
}

const Column = styled.div<GridProps>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex || '1'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  margin-top: ${({ margintop }) => (margintop ? '1rem' : '0')};
  width: ${({ full }) => (full ? '100%' : 'auto')};
  height: ${({ full }) => (full ? '100%' : 'auto')};
  padding: ${({ padded }) => (padded ? '3rem' : 0)};
`;

const Row = styled.div<GridProps>`
  display: flex;
  flex-direction: row;
  flex: ${({ flex }) => flex || '1'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  margin-top: ${({ margintop }) => (margintop ? '1rem' : '0')};
`;

const RowDark = styled(Row)`
  background-color: ${({ theme: { colors } }) => colors.whiteSmoke};
  padding: 1.5rem;
`;

const Title = styled.div`
  color: ${({ theme: { colors } }) => colors.grayDark};
  font-size: 0.8rem;
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-style: normal;
  font-weight: 500;
  line-height: 1.3rem;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
`;

const Line = styled.span<LineProps>`
  font-family: ${({ theme: { typography } }) => typography.museo};
  font-size: ${({ large }) => (large ? '1.5rem' : 'inherit')};
  color: ${({ theme: { colors }, gray, large }) => {
    if (large) {
      return colors.blueDark;
    }
    return gray ? colors.grayDark : colors.black;
  }};
  line-height: 1.3rem;
  letter-spacing: 0.06rem;
  margin-top: ${({ margintop }) => (margintop ? '0.5rem' : 0)};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;

const Item = styled(Column)<LineProps>`
  margin-bottom: 1rem;
  line-height: 1.3rem;
  font-size: ${({ large }) => (large ? '1.5rem' : 'inherit')};
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors }, gray, large }) => {
    if (large) {
      return colors.blueDark;
    }
    return gray ? colors.grayDark : colors.black;
  }};
`;

const Box = styled.div`
  background-color: ${({ theme: { colors } }) => colors.whiteSmoke};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
`;

const Logo = styled(logo)`
  width: 10rem;
  height: 2.125rem;
`;

const WhiteButton = styled.button`
  background: ${({ theme: { colors } }) => colors.white};
  border: 0.1rem solid ${({ theme: { colors } }) => colors.grayADA};
  font-family: ${({ theme: { typography } }) => typography.museo};
  color: ${({ theme: { colors } }) => colors.blueDark};
  margin-left: 1rem;
  min-width: 7rem;
  cursor: pointer;
  height: 3rem;
  padding: 0.9rem 1.6rem;
`;

interface ModalProps {
  isOpen?: boolean;
  afterOpenModal?: () => void;
  onClose?: () => void;
  title?: string;
  invoice?: any;
  order?: any;
}

const InvoiceModal: FunctionComponent<ModalProps> = ({
  isOpen = false,
  onClose = (): void => {},
  invoice = {},
  order = {},
}) => {
  const { parts } = invoice;
  const partsCost =
    (parts || []).reduce(
      (total: number, part: any) => total + part.quantity * part.price,
      0,
    ) || 0;
  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <Column full justify="space-between">
        <Column padded flex="none">
          <Row justify="space-between">
            <Column flex="1 0 55%">
              <Title>Dealer Code</Title>
              <Item>G023560986</Item>
              <Title>attn:</Title>
              <Item>
                <Line>United Rental C - 02F</Line>
                <Line>P.O. Box 30129</Line>
                <Line>College Station</Line>
                <Line>Tx 77942</Line>
              </Item>
            </Column>
            <Column flex="1 0 45%">
              <Column>
                <Logo />
                <Line margintop uppercase>
                  Kobelco construction machinery usa inc.
                </Line>
                <Line margintop gray>
                  22350 Merchants Way Katy, Tx 77449
                </Line>
                <Line margintop gray>
                  TEL 281-898-8430 FAX 281-898-8430
                </Line>
              </Column>
            </Column>
          </Row>
          <Row justify="space-between">
            <Column flex="0 1 43%">
              <Title>Shipping to</Title>
              <Box>
                <Item>
                  <Line gray>---</Line>
                  <Line gray>---</Line>
                  <Line gray>--</Line>
                </Item>
              </Box>
              <Row margintop>
                <Line>(place for a note)</Line>
              </Row>
            </Column>
            <Column flex="0 1 53%">
              <Row justify="space-around">
                <Column>
                  <Title>Invoice NO.</Title>
                  <Item>{invoice.number}</Item>
                </Column>
                <Column>
                  <Title>Issue Date</Title>
                  <Item>{formatters.date(invoice.issuedAt)}</Item>
                </Column>
              </Row>
              <Row justify="space-around">
                <Column>
                  <Title>Shipping Date</Title>
                  <Item>-</Item>
                </Column>
                <Column>
                  <Title>Dealer PO</Title>
                  <Item>{order.poNumber}</Item>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Title>Reference No.</Title>
                  <Item>-</Item>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Title>Place of Shipment</Title>
                  <Item>-</Item>
                </Column>
                <Column>
                  <Title>Transport Mode</Title>
                  <Item>-</Item>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Title>Delivery Terms</Title>
                  <Item>-</Item>
                </Column>
                <Column>
                  <Title>Payment Terms</Title>
                  <Item>-</Item>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Title>Tracking No.</Title>
                  <Item large>{invoice.trackingNumber}</Item>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row margintop justify="space-between">
            <Column flex="0 1 43%">
              <Title>Mark and Notes</Title>
              <Row justify="space-between">
                <Line margintop>1</Line>
                <Line margintop>Number of Package</Line>
                <Line margintop>Total Qty</Line>
                <Line margintop>7</Line>
              </Row>
            </Column>
            <Column flex="0 1 53%">
              <Row justify="space-between">
                <Line>Parts Total</Line>
                <Line>{formatters.currency(partsCost)}</Line>
              </Row>
              <Row margintop justify="space-between">
                <Line>Freight Charge</Line>
                <Line>{formatters.currency(0)}</Line>
              </Row>
              <Row margintop justify="space-between">
                <Line>Insurance</Line>
                <Line>{formatters.currency(0)}</Line>
              </Row>
              <Row margintop justify="space-between">
                <Line>Other Charges</Line>
                <Line>{formatters.currency(0)}</Line>
              </Row>
              <GrayLine />
              <Row margintop justify="space-between">
                <Line>Total before Tax</Line>
                <Line>{formatters.currency(partsCost)}</Line>
              </Row>
              <Row margintop justify="space-between">
                <Line>Sales Tax</Line>
                <Line>{formatters.currency(0)}</Line>
              </Row>
              <GrayLine />
              <Row margintop justify="space-between">
                <Line large>Order Total</Line>
                <Line large>{formatters.currency(invoice.total)}</Line>
              </Row>
            </Column>
          </Row>
        </Column>
        <RowDark flex="none" justify="space-between">
          <PrimaryButton onClick={onClose}>View Original Order</PrimaryButton>
          <Row justify="flex-end">
            <WhiteButton>Download</WhiteButton>
            <WhiteButton>Print</WhiteButton>
          </Row>
        </RowDark>
      </Column>
    </ModalLayout>
  );
};

export default InvoiceModal;
