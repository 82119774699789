import React, { useState, FunctionComponent, useEffect } from 'react';
import Modal from 'react-modal';
import theme from '../../constants/theme';
import ToRGB from '../../utils/color';

Modal.setAppElement('#root');

const { r, g, b } = ToRGB(theme.colors.blueKobelco);
const { r: rd, g: gd, b: bd } = ToRGB(theme.colors.blueDark);

const customStyles: Modal.Styles = {
  content: {
    position: 'static',
    top: undefined,
    left: undefined,
    right: undefined,
    bottom: undefined,
    padding: 0,
    border: 'none',
    borderRadius: 0,
    margin: '1.2rem',
    minWidth: '550px',
    minHeight: '570px',
    maxWidth: '90vw',
    maxHeight: '90vh',
    boxShadow: `0px 4px 24px rgba(${rd}, ${gd}, ${bd}, 0.5)`,
  },
  overlay: {
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `rgba(${r}, ${g}, ${b}, 0.8)`,
    width: '100%',
    height: '100%',
  },
};

interface ModalProps {
  title?: string;
  isOpen?: boolean;
  spaceBetween?: boolean;
  afterOpenModal?: () => void;
  onClose?: () => void;
}

const ModalLayout: FunctionComponent<ModalProps> = ({
  isOpen = false,
  spaceBetween = false,
  title = 'Example Modal',
  onClose = (): void => {},
  afterOpenModal = (): void => {},
  children,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(isOpen);
  useEffect(() => {
    setModalIsOpen(isOpen);
  }, [isOpen]);

  if (spaceBetween && customStyles.content) {
    customStyles.content.display = 'flex';
    customStyles.content.flexDirection = 'column';
    customStyles.content.justifyContent = 'space-between';
  }

  return (
    <Modal
      contentLabel={title}
      isOpen={modalIsOpen}
      style={customStyles}
      onRequestClose={onClose}
      onAfterOpen={afterOpenModal}
    >
      {children}
    </Modal>
  );
};

export default ModalLayout;
