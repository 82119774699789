import styled from 'styled-components';
import React, { FunctionComponent } from 'react';

const PartHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type HeaderItemProps = {
  flex?: number;
  alignCenter?: boolean;
};

const HeaderItem = styled.span<HeaderItemProps>`
  font-size: 0.8rem;
  padding: 0 0.4rem;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  flex: ${({ flex }) => flex};
  border-left: 0.4rem solid transparent;
  color: ${({ theme: { colors } }) => colors.black};
  font-family: ${({ theme: { typography } }) => typography.museo};
  text-align: ${({ alignCenter }) => (alignCenter ? 'center' : undefined)};
`;

const PartsHeader: FunctionComponent = () => (
  <PartHeaderContainer>
    <HeaderItem flex={2}>Part</HeaderItem>
    <HeaderItem flex={1}>Unit Cost</HeaderItem>
    <HeaderItem flex={1}>QTY</HeaderItem>
    <HeaderItem flex={1}>Total</HeaderItem>
    <HeaderItem flex={0.5} alignCenter>
      Favorite
    </HeaderItem>
    <HeaderItem flex={0.5} />
  </PartHeaderContainer>
);

export default PartsHeader;
