import { State, AnyEventObject } from 'xstate';
import { FormikValues } from 'formik';

interface ArgumentsInterface {
  current: State<any, AnyEventObject, any, any>;
  customerDetailsFields: string[];
  formik: FormikValues;
  mobileBreakpoint: number;
  send: (event: any, payload?: any) => any;
  width?: number;
}

export function isReadyToSubmitOrderChecker({
  current,
  customerDetailsFields,
  formik,
  mobileBreakpoint,
  send,
  width,
}: ArgumentsInterface) {
  formik.setErrors({});

  let readyToSubmit = true;
  const invalidFields: Array<string> = [];

  Object.keys(formik.values).forEach(key => {
    if (key === 'total' || key === 'notes') {
      return;
    }

    if (!formik.values[key]) {
      readyToSubmit = false;
      invalidFields.push(key);
      formik.setFieldError(key, 'Required field');
    } else if (Array.isArray(formik.values[key])) {
      if (formik.values[key].length <= 0) {
        readyToSubmit = false;
        invalidFields.push(key);
        formik.setFieldError(key, 'Required field');
      }
    }
  });

  // if error is on customer details and on mobile, expand customer details
  if (!readyToSubmit) {
    let errorOnCustDetails = false;
    customerDetailsFields.forEach(field => {
      if (invalidFields.includes(field)) {
        errorOnCustDetails = true;
      }
    });
    const isMobile = Boolean(width && width < mobileBreakpoint);
    if (!errorOnCustDetails && current.matches('customerDetails') && isMobile) {
      send('CONTINUE');
      formik.setErrors({});
    } else if (
      errorOnCustDetails &&
      current.matches('editingAll') &&
      isMobile
    ) {
      send('BACK');
    }
  }

  return readyToSubmit;
}
